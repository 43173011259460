import React, { useState, ReactElement } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  ImageV9 as Image,
  StartOrEndTimeDisplayOnly,
  getDocument,
  mergeClasses,
  ButtonV9 as Button,
  TextV9 as Text,
  DialogV9 as Dialog,
  DialogSurfaceV9 as DialogSurface,
  DialogBodyV9 as DialogBody,
  CalendarCancel24Regular,
  ChevronDown20Filled,
  MenuV9 as Menu,
  MenuTriggerV9 as MenuTrigger,
  MenuPopoverV9 as MenuPopover,
  MenuListV9 as MenuList,
  MenuItemV9 as MenuItem,
  CalendarCancel20Regular,
} from "../../shared";
import {
  eventSelector,
  registeredSelector,
  registrationDetailSelector,
  userIsRegistered,
  isUserRegistrationStatusKnown,
  eventHasEndedSelector,
  registrationHasEndedSelector,
  registrationHasNotStartedSelector,
  isUserAwaitingApprovalSelector,
  isOrganizerSelector,
} from "../../core/slices/eventSlice";
import {
  registrationMobileStyles,
  registrationPageStyles,
  registrationCardStyles,
  registrationCancelMobileStyles,
  noOfAttendeesStyles,
} from "../../styles/EventRegistration";
import {
  AccessType,
  UserRegistrationStatus,
  EventType,
} from "../../core/slices/eventTypes.interface";
import {
  getRegistrationButtonState,
  RegistrationState,
} from "./RegistrationStateInternal";
import { authenticatedSelector } from "../../core/slices/userSlice";
import { useBreakpoint } from "../../utilities/hooks/useBreakpoints";
import { routes } from "../../common/constants";
import { Breakpoint } from "../../styles/Grid";
import { useLogger } from "../../common/logger/LoggerContext";
import { Scenario, UserBIScenario } from "../../common/logger/Logger";
import { StepsToTrack } from "../../common/logger/StepsToTrack";
import { buttonStyles } from "../../styles/ButtonStyles";
import { flexAlignStyles, flexStyles } from "../../styles/FlexStyles";
import { portalTextStyles } from "../../styles/PortalText";
import { portalResponsiveDialogStyles } from "../../styles/PortalResponsiveDialog";
import { TopInfo } from "../eventInfo/TopInfo";
import { IEventInfo } from "../eventInfo/IEventInfo";
import { timeDisplayStyles } from "../../styles/TimeDisplay";
import { currentSessionSelector } from "../../core/slices/sessionSlice";
import { generateSearchParams } from "../../utilities/common/generateSearchParams";
import { eventAttendingAndCancelStyles } from "../../styles/registration/RegistrationCard";
import { History, Location } from "history";
import { PortalLocationState } from "../../core/history/history.interface";
export interface IRegisterCardOptions {
  webinarInfoText?: string | ReactElement;
  showImage?: boolean;
  isFull: boolean;
  eventFullText?: string;
  defaultButtonText?: string;
  primaryButtonText: string;
  buttonDisabled: boolean;
  waitlistOpen?: boolean;
  imgSrc?: string;
  meetingJoinUrl?: string;
  ariaLabel: string;
}

export const RegistrationCard: React.FunctionComponent<{
  props: IRegisterCardOptions;
}> = ({ props }) => {
  const {
    webinarInfoText,
    showImage,
    isFull,
    defaultButtonText,
    primaryButtonText,
    buttonDisabled,
    waitlistOpen,
    imgSrc,
    meetingJoinUrl,
    ariaLabel,
  } = props;
  const flexClasses = flexStyles();
  const flexAlignClasses = flexAlignStyles();
  const buttonClasses = buttonStyles();
  const registrationCardClasses = registrationCardStyles();
  const portalTextClasses = portalTextStyles();
  const timeDisplayClasses = timeDisplayStyles();
  const registrationMobileClasses = registrationMobileStyles();
  const registrationPageClasses = registrationPageStyles();

  const isAuthenticated = useSelector(authenticatedSelector);
  const currentEvent = useSelector(eventSelector);
  const currentSession = useSelector(currentSessionSelector);
  const isRegistered = useSelector(userIsRegistered);
  const { id } = useParams<{ id: string }>();
  const { t: i18n } = useTranslation();
  const logger = useLogger()?.logger;
  const history: History = useHistory<PortalLocationState>();
  const location: Location = useLocation<PortalLocationState>();
  const registrationDetails = useSelector(registrationDetailSelector);
  const hasEventEnded = useSelector(eventHasEndedSelector);
  const hasRegistrationNotStarted = useSelector(
    registrationHasNotStartedSelector
  );
  const hasRegistrationEnded = useSelector(registrationHasEndedSelector);
  const isOrganizer = useSelector(isOrganizerSelector);

  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);

  const isEventFull: boolean =
    !!registrationDetails?.registrationProperties?.isFull;
  const isEventWaitlistOpen: boolean = !!(
    registrationDetails?.registrationProperties?.isWaitlistEnabled &&
    !registrationDetails?.registrationProperties?.isWaitlistFull
  );

  const eventInfoProps: IEventInfo = {
    isFull: isEventFull,
    waitlistOpen: isEventWaitlistOpen,
  };

  const isRegistrationCardTopInfoNeeded =
    !!webinarInfoText ||
    (hasRegistrationNotStarted && !isRegistered) ||
    hasEventEnded ||
    showImage;

  const routeToComponent: (isAuthenticated: boolean) => void = () => {
    const document = getDocument();
    const referrer = document?.referrer;
    const scenario = logger?.findScenario(Scenario.RegistrationDetails);
    if (scenario) {
      scenario.mark(StepsToTrack.RegisterButtonClicked);
    }
    //User indicating to access the Registration Page
    logger?.createScenario(Scenario.EventRegistration);

    //If user is registered then the Join Webinar button should
    //take them to the meeting url
    if (isAuthenticated && isRegistered && meetingJoinUrl) {
      logger?.logUiTelemetry(
        "event",
        UserBIScenario.JoinEvent,
        "click",
        "none",
        "RegistrationCard",
        referrer
      );
      window.location.href = meetingJoinUrl;
    } else {
      if (
        isAuthenticated ||
        registrationDetails?.eventDetails.access.accessType ===
          AccessType.PUBLIC
      ) {
        if (currentEvent?.recurrencePattern) {
          logger?.logUiTelemetry(
            "eventRegistration",
            UserBIScenario.RegisterRecurringWebinarOccurance,
            "click",
            "none",
            "RegistrationCard",
            referrer
          );
          history.push({
            pathname: `${routes.event}/${id}/${routes.webinarSeries}`,
            search: generateSearchParams(location),
          });
        } else {
          logger?.logUiTelemetry(
            "eventRegistration",
            UserBIScenario.RegisterEvent,
            "click",
            "none",
            "RegistrationCard",
            referrer
          );
          //react-router-dom's useHistory hook helps to pass in the location to route to via pathname
          //and any state that the new location should use that gets passed down as props
          history.push({
            pathname: `${routes.event}/${id}/${routes.registration}`,
            search: generateSearchParams(location),
          });
        }
      } else {
        history.push({
          pathname: routes.login,
          search: generateSearchParams(location),
          state: {
            redirectPath: {
              pathname: `${routes.event}/${id}/${routes.registration}`,
              search: generateSearchParams(location),
            },
          },
        });
      }
    }
  };

  let footNote: React.ReactNode = null;
  if (!hasEventEnded && isFull) {
    footNote = waitlistOpen ? (
      <Text id="waitlist-state-info">{i18n("event_waitlisted_state")}</Text>
    ) : (
      <Text id="sold-out-info">{i18n("registration_at_capacity")}</Text>
    );
  }

  const registrationCardTopInfo = () => {
    return (
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.row,
          flexAlignClasses.alignItemCenter,
          buttonClasses.fluid,
          showImage ? registrationCardClasses.TopInfoShowImage : ""
        )}
      >
        {webinarInfoText && (
          <Text
            className={registrationCardClasses.container}
            id="webinar-info-text"
          >
            {webinarInfoText}
          </Text>
        )}
        {hasEventEnded && !isRegistered && (
          <Text className={portalTextClasses.medium2} id="event-ended">
            {i18n("this_event_ended")}
          </Text>
        )}
        {hasRegistrationNotStarted && (
          <Text id="registration-not-started">
            {i18n("registration_not_started")}&nbsp;
            <StartOrEndTimeDisplayOnly
              time={
                registrationDetails &&
                registrationDetails.registrationProperties.registrationLimit &&
                registrationDetails?.registrationProperties.registrationOpenTime
                  ?.startTime
                  ? registrationDetails?.registrationProperties
                      .registrationOpenTime?.startTime
                  : /* istanbul ignore next */ ""
              }
              className={mergeClasses(
                timeDisplayClasses.root,
                portalTextClasses.medium,
                portalTextClasses.mediumLineHeight
              )}
            />
          </Text>
        )}
        {showImage && (
          <Image
            id="registration-card-image"
            src={imgSrc}
            fit="contain"
            alt=""
            className={mergeClasses(
              registrationCardClasses.image,
              registrationCardClasses.imageContainer
            )}
          />
        )}
      </div>
    );
  };

  const registrationCardButtonSection = () => {
    return (
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGap12px,
          buttonClasses.fluid,
          showImage ? registrationCardClasses.registrationButtonShowImage : ""
        )}
      >
        <div className={buttonClasses.fluid}>
          {!defaultButtonText && primaryButtonText.length > 0 ? (
            <Button
              id="registration_button"
              className={buttonClasses.fluid}
              appearance="primary"
              onClick={() => {
                routeToComponent(isAuthenticated);
              }}
              disabled={buttonDisabled}
              aria-label={ariaLabel}
            >
              {primaryButtonText}
            </Button>
          ) : (
            <Button className={buttonClasses.fluid}>{defaultButtonText}</Button>
          )}
        </div>

        {footNote}

        {hasRegistrationEnded && !isOrganizer && (
          <Text id="registration-has-ended">
            {i18n("registration_is_closed")}
          </Text>
        )}
      </div>
    );
  };

  const TopInfoStyles = mergeClasses(
    showImage ? registrationCardClasses.detailsSectionShowImage : "",
    isRegistrationCardTopInfoNeeded && !showImage
      ? registrationCardClasses.detailsSection
      : "",
    flexClasses.fluid
  );

  const showRegistrationSection =
    !isRegistered ||
    !isAuthenticated ||
    currentEvent?.type === EventType.WEBINAR;

  const registerSectionWeb = showRegistrationSection ? (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexAlignClasses.alignItemFlexStart,
        showImage
          ? registrationCardClasses.topSectionWithImage
          : mergeClasses(
              registrationCardClasses.root,
              flexClasses.columnGap20px
            )
      )}
    >
      {isRegistrationCardTopInfoNeeded && registrationCardTopInfo()}
      {currentSession ? (
        <div className={TopInfoStyles}>
          <TopInfo session={currentSession} />
        </div>
      ) : (
        <div className={TopInfoStyles}>
          <TopInfo eventInfo={eventInfoProps} event={currentEvent} />
        </div>
      )}
      {registrationCardButtonSection()}
    </div>
  ) : /* istanbul ignore next */ null;

  const registerSectionMobile = showRegistrationSection ? (
    <div
      className={mergeClasses(
        "registration-button-mobile-container",
        registrationMobileClasses.root
      )}
    >
      <div
        id="mobile-registration"
        className={mergeClasses(
          flexClasses.root,
          flexClasses.row,
          flexClasses.rowGapSmaller,
          flexAlignClasses.alignItemCenter,
          flexAlignClasses.justifyContentSpaceBetween,
          registrationMobileClasses.wrapper
        )}
      >
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.row,
            flexAlignClasses.alignItemCenter
          )}
        >
          {webinarInfoText && (
            <Text id="webinar-info-text">{webinarInfoText}</Text>
          )}
        </div>
        <Button
          id="registration_button"
          className={registrationPageClasses.registerSectionMobileButton}
          appearance="primary"
          aria-label={ariaLabel}
          onClick={() => {
            routeToComponent(isAuthenticated);
          }}
          disabled={buttonDisabled}
        >
          {primaryButtonText}
        </Button>
      </div>
    </div>
  ) : /* istanbul ignore next */ null;

  const registrationCard = !registrationDetails ? (
    /* istanbul ignore next */ <></>
  ) : isMobileView ? (
    registerSectionMobile
  ) : (
    registerSectionWeb
  );

  return registrationCard;
};

export const EventAttendingAndCancel: React.FunctionComponent = () => {
  const flexClasses = flexStyles();
  const portalResponsiveDialogClasses = portalResponsiveDialogStyles();
  const eventAttendingAndCancelClasses = eventAttendingAndCancelStyles();
  const buttonClasses = buttonStyles();
  const registrationPageClasses = registrationPageStyles();
  const noOfAttendeesClasses = noOfAttendeesStyles();
  const registrationCancelMobileClasses = registrationCancelMobileStyles();

  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();

  const registrationDetails = useSelector(registrationDetailSelector);
  const isAuthenticated = useSelector(authenticatedSelector);
  const registration = useSelector(registeredSelector);
  const currentEvent = useSelector(eventSelector);
  const isRegistrationStatusKnown = useSelector(isUserRegistrationStatusKnown);
  const { id, regId } = useParams<{ id: string; regId: string }>();
  const { t: i18n } = useTranslation();
  const registrationId =
    regId && regId.trim()
      ? regId
      : /* istanbul ignore next */ registration?.registrationId;
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);
  const [isOpenCancelMobile, setIsOpenCancelMobile] = useState(false);
  const userInAwaitingApprovalStatus = useSelector(
    isUserAwaitingApprovalSelector
  );

  const isPrivateEventWithRegId = !!(
    registrationDetails?.eventDetails.access.accessType ===
      AccessType.PRIVATE && registrationId
  );

  let attendingStatusText = "";
  if (registration?.status === UserRegistrationStatus.REGISTERED) {
    attendingStatusText = i18n("attending");
  } else if (registration?.status === UserRegistrationStatus.WAITLIST) {
    attendingStatusText = i18n("waitlisted");
  } else if (userInAwaitingApprovalStatus) {
    attendingStatusText = i18n("awaiting_approval");
  }

  const onClickCancelRegistration = React.useCallback(() => {
    history.push({
      pathname: `${routes.event}/${id}/attendee/${registrationId}/cancel`,
      search: generateSearchParams(location),
    });
  }, [history, id, location, registrationId]);

  const onClickCancelRegistrationMobile = React.useCallback(() => {
    setIsOpenCancelMobile(false);
    onClickCancelRegistration();
  }, [onClickCancelRegistration]);

  const cancelOptionMobile = (
    <Dialog
      open={isOpenCancelMobile}
      onOpenChange={
        /* istanbul ignore next */ () => setIsOpenCancelMobile(false)
      }
    >
      <DialogSurface
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          portalResponsiveDialogClasses.dialogSurface
        )}
      >
        <DialogBody
          id={"cancel-registration-dialog"}
          className={mergeClasses(
            flexClasses.root,
            portalResponsiveDialogClasses.dialogBody
          )}
        >
          <Button
            data-testid={"cancel-registration"}
            onClick={onClickCancelRegistrationMobile}
            aria-label={i18n("cancel_registration")}
            icon={{
              className:
                eventAttendingAndCancelClasses.cancelRegitsrationDialogCancelButtonIconMobile,
              children: <CalendarCancel24Regular />,
            }}
            appearance="transparent"
          >
            {i18n("cancel_registration")}
          </Button>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );

  const registrationState = getRegistrationButtonState(
    currentEvent,
    registrationDetails
  );

  const eventAttendAndCancelTrigger = (
    <Button
      appearance="transparent"
      className={mergeClasses(
        buttonClasses.primaryWithText,
        registrationPageClasses.eventAttendAndCancelTriggerButton
      )}
      id={"registration-status-text"}
      icon={<ChevronDown20Filled />}
      iconPosition="after"
    >
      {attendingStatusText}
    </Button>
  );

  const eventAttendingAndCancelGeneric =
    (isPrivateEventWithRegId || isAuthenticated) &&
    isRegistrationStatusKnown ? (
      !isMobileView ? (
        registrationState === RegistrationState.EVENT_ENDED ? (
          <Text
            weight="bold"
            className={noOfAttendeesClasses.attendingEventText}
          >
            {attendingStatusText}
          </Text>
        ) : (
          <Menu positioning={{ align: "end" }}>
            <MenuTrigger>{eventAttendAndCancelTrigger}</MenuTrigger>
            <MenuPopover>
              <MenuList>
                <MenuItem
                  key="cancelRegistration"
                  data-testid="registrationCancelMenuItem"
                  icon={<CalendarCancel20Regular />}
                  onClick={onClickCancelRegistration}
                >
                  {i18n("cancel_registration")}
                </MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        )
      ) : (
        <Button
          className={mergeClasses(
            registrationCancelMobileClasses.eventAttendingAndCancelButton,
            buttonClasses.fluid,
            buttonClasses.largeButton
          )}
          id={"registration-status-text"}
          icon={<ChevronDown20Filled />}
          iconPosition="after"
          onClick={() => setIsOpenCancelMobile(true)}
        >
          <Text weight="bold">{attendingStatusText}</Text>
        </Button>
      )
    ) : null;

  return (
    <>
      {eventAttendingAndCancelGeneric}
      {cancelOptionMobile}
    </>
  );
};
