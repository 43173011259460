import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  authenticatedSelector,
  userSelector,
} from "../../core/slices/userSlice";
import {
  registeredSelector,
  isUserAwaitingApprovalSelector,
  eventSelector,
} from "../../core/slices/eventSlice";
import {
  AccessType,
  UserRegistrationStatus,
  IEventAccessPermissions,
} from "../../core/slices/eventTypes.interface";
import {
  DialogContentV9 as DialogContent,
  DialogTitleV9 as DialogTitle,
  DialogV9 as Dialog,
  DialogSurfaceV9 as DialogSurface,
  DialogBodyV9 as DialogBody,
  DialogTriggerV9 as DialogTrigger,
  mergeClasses,
  TextV9 as Text,
  ImageV9 as Image,
  ButtonV9 as Button,
  Dismiss32Regular,
} from "../../shared";
import { useTranslation } from "react-i18next";
import thanksAllSet from "../../assets/thanks_AllSet.svg";
import approvalPending from "../../assets/approval_pending.svg";
import waitlisted from "../../assets/waitlist_success.svg";
import { registrationPageStyles } from "../../styles/EventRegistration";
import { EventSharing } from "../EventSharing";
import { flexAlignStyles, flexStyles } from "../../styles/FlexStyles";
import { portalResponsiveDialogStyles } from "../../styles/PortalResponsiveDialog";
import { regAndCancelSuccessStyles } from "../../styles/RegistrationAndCancellationSuccess";
import { generateSearchParams } from "../../utilities/common/generateSearchParams";
import { PortalLocationState } from "../../core/history/history.interface";

export interface IRegistrationSuccess {
  titleId: string;
  eventUrl: string;
  eventName: string;
  eventDescription: string;
  emailAddress: string;
  promoImageID: string;
  eventID: string;
}

export const RegistrationSuccess: React.FunctionComponent<{
  props: IRegistrationSuccess;
}> = ({ props }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const portalResponsiveDialogClasses = portalResponsiveDialogStyles();
  const registrationPageClasses = registrationPageStyles();
  const regAndCancelSuccessClasses = regAndCancelSuccessStyles();
  const [isOpen, setIsOpen] = useState(true);
  const { t: i18n } = useTranslation();
  const {
    titleId,
    eventUrl,
    eventName,
    eventDescription,
    emailAddress,
    promoImageID,
    eventID,
  } = props;
  const isAuthenticated = useSelector(authenticatedSelector);
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  const currentEvent = useSelector(eventSelector);
  const registration = useSelector(registeredSelector);
  const userInAwaitingApprovalStatus = useSelector(
    isUserAwaitingApprovalSelector
  );

  let successTitle = "";
  let successBody = "";
  let imgSrc = thanksAllSet;
  let altText = i18n("attending");

  if (isAuthenticated) {
    if (userInAwaitingApprovalStatus) {
      successTitle = i18n("registration_awaiting_approval_title");
      successBody = i18n("registration_awaiting_approval_text");
      imgSrc = approvalPending;
      altText = i18n("awaiting_approval");
    } else if (registration?.status === UserRegistrationStatus.WAITLIST) {
      successTitle = i18n("waitlisted_registration");
      successBody = i18n("registration_waitlisted_text", {
        emailAddress: emailAddress,
      });
      imgSrc = waitlisted;
      altText = i18n("waitlisted");
    } else {
      successTitle = i18n("see_you_at_event", { eventTitle: eventName });
      successBody = i18n("confirmation_email_message", { email: emailAddress });
    }
  } else {
    successTitle = i18n("registration_being_processed");
    successBody = i18n("confirmation_email_message", { email: emailAddress });
  }

  const onDismiss: () => void = () => {
    setIsOpen(false);
    history.replace({
      state: undefined,
      search: generateSearchParams(location),
    });
  };

  let isEventPublic = false;
  // indicates whether organizer has enabled social sharing for public event
  let isSocialShareEnabled = true;
  if (currentEvent) {
    const eventAccess: IEventAccessPermissions = currentEvent.access;
    isEventPublic = eventAccess.accessType === AccessType.PUBLIC;
    isSocialShareEnabled =
      !!currentEvent.communicationProperties?.isSocialShareEnabled;
  }

  const modal = (
    <Dialog open={isOpen} onOpenChange={onDismiss}>
      <DialogSurface
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          portalResponsiveDialogClasses.dialogSurface
        )}
      >
        <DialogBody
          aria-modal="true"
          title-aria-id={titleId}
          className={mergeClasses(
            "registration-success-dialog",
            flexClasses.root,
            flexClasses.column,
            flexAlignClasses.justifyContentCenter,
            portalResponsiveDialogClasses.dialogBody
          )}
          aria-labelledby="modalSuccessTitle"
          aria-describedby="modalSuccessBody"
        >
          <DialogTitle
            className={mergeClasses(
              flexClasses.root,
              flexAlignClasses.justifyContentEnd
            )}
          >
            <DialogTrigger action="close">
              <Button
                id="closeSuccessDialog"
                aria-label={i18n("close_popup_modal")}
                onClick={onDismiss}
                icon={<Dismiss32Regular />}
                appearance="transparent"
                title="Close"
              />
            </DialogTrigger>
          </DialogTitle>
          <DialogContent
            id="registrationSuccessModal"
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              flexClasses.columnGapMedium,
              flexAlignClasses.alignItemCenter,
              regAndCancelSuccessClasses.successContent
            )}
          >
            <Image
              fit="contain"
              alt={altText}
              src={imgSrc}
              className={regAndCancelSuccessClasses.successImageOnModal}
            />
            <Text
              role="heading"
              id="modalSuccessTitle"
              tabIndex={1}
              as="h1"
              align="center"
              className={mergeClasses(
                registrationPageClasses.titleDisplay,
                regAndCancelSuccessClasses.successBodyText
              )}
              aria-label={successTitle + " " + successBody}
            >
              {successTitle}
            </Text>
            <div
              className={mergeClasses(
                flexClasses.root,
                flexClasses.column,
                flexClasses.columnGapLarge,
                flexAlignClasses.justifyContentCenter,
                regAndCancelSuccessClasses.successBody
              )}
            >
              <Text
                id="modalSuccessBody"
                align="center"
                className={regAndCancelSuccessClasses.successBodyText}
              >
                {successBody}
              </Text>
              {isEventPublic && isSocialShareEnabled && (
                <EventSharing
                  eventTitle={eventName}
                  eventUrl={eventUrl}
                  eventDescription={eventDescription}
                  promoImageID={promoImageID}
                  eventID={eventID}
                />
              )}
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );

  return modal;
};

export const CancellationSuccess: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const portalResponsiveDialogClasses = portalResponsiveDialogStyles();
  const registrationPageClasses = registrationPageStyles();
  const regAndCancelSuccessClasses = regAndCancelSuccessStyles();
  const [isOpen, setIsOpen] = useState(true);
  const { t: i18n } = useTranslation();
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  const isAuthenticated = useSelector(authenticatedSelector);
  const user = useSelector(userSelector);

  const onDismiss: () => void = () => {
    setIsOpen(false);
    history.replace({
      state: undefined,
      search: generateSearchParams(location),
    });
  };

  const ariaLabelMesage = `${i18n("cancellation_complete")} ${
    isAuthenticated
      ? i18n("cancellation_message_auth", {
          emailAddress: user?.email,
        })
      : i18n("cancellation_message_unauth")
  }`;

  const modal = (
    <Dialog open={isOpen} onOpenChange={onDismiss}>
      <DialogSurface
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          portalResponsiveDialogClasses.dialogSurface
        )}
      >
        <DialogBody
          className={mergeClasses(
            "cancel-registration-dialog",
            flexClasses.root,
            flexClasses.column,
            portalResponsiveDialogClasses.dialogBody
          )}
        >
          <DialogTitle
            className={mergeClasses(
              flexClasses.root,
              flexAlignClasses.justifyContentEnd
            )}
          >
            <DialogTrigger action="close">
              <Button
                id="closeDialog"
                aria-label={i18n("close_popup_modal")}
                onClick={onDismiss}
                icon={<Dismiss32Regular />}
                appearance="transparent"
              />
            </DialogTrigger>
          </DialogTitle>
          <DialogContent
            id="cancelSuccessModal"
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              flexClasses.columnGapMedium,
              flexAlignClasses.alignItemCenter,
              regAndCancelSuccessClasses.successContent
            )}
          >
            <Image
              fit="contain"
              alt={i18n("cancellation_complete")}
              className={regAndCancelSuccessClasses.successImageOnModal}
              src={thanksAllSet}
            />
            <Text
              role="heading"
              id="cancelSuccessModalTitle"
              as="h1"
              align="center"
              className={mergeClasses(
                registrationPageClasses.titleDisplay,
                regAndCancelSuccessClasses.successBodyText
              )}
              aria-label={ariaLabelMesage}
              tabIndex={1}
            >
              {i18n("cancellation_complete")}
            </Text>
            <div className={regAndCancelSuccessClasses.successBody}>
              <Text
                id="cancelSuccessModalBody"
                align="center"
                className={regAndCancelSuccessClasses.successBodyText}
              >
                {isAuthenticated
                  ? i18n("cancellation_message_auth", {
                      emailAddress: user?.email,
                    })
                  : i18n("cancellation_message_unauth")}
              </Text>
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );

  return modal;
};
