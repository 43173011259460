import React from "react";
import {
  SkeletonV9 as Skeleton,
  mergeClasses,
  ButtonV9 as Button,
  AvatarV9 as Avatar,
  Person16Regular,
  Popover,
  PopoverTrigger,
  PopoverSurface,
  TextV9 as Text,
  ChevronRight16Regular,
  CalendarArrowRight20Filled,
  Person16Filled,
  SkeletonItem,
} from "../shared";

import { useSelector } from "react-redux";
import {
  authenticatedSelector,
  isUserLoadingSelector,
  userSelector,
} from "../core/slices/userSlice";
import { useTranslation } from "react-i18next";
import { topNavBarWebStyles } from "../styles/TopNavBarWeb";
import { imageSelector } from "../core/slices/imageSlice";
import { UserBIScenario } from "../common/logger/Logger";
import { useLogger } from "../common/logger/LoggerContext";
import { HelpMenuButton } from "./HelpMenuButton";
import { buttonStyles } from "../styles/ButtonStyles";
import {
  flexAlignStyles,
  flexItemStyles,
  flexStyles,
} from "../styles/FlexStyles";
import { avatarStyles } from "../styles/Avatar";
import { portalTextStyles } from "../styles/PortalText";
import { skeletonItemStyles } from "../styles/SkeletonStyles";

export const TopNavBarWeb: React.FunctionComponent<{
  enableManageAccount: boolean;
  onClickSignIn: () => void;
  onClickSignOut: () => void;
  onClickManageAccount: () => void;
}> = ({
  enableManageAccount,
  onClickSignIn,
  onClickSignOut,
  onClickManageAccount,
}) => {
  const topNavBarWebClasses = topNavBarWebStyles();
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();

  const isAuthenticated = useSelector(authenticatedSelector);
  const { t: i18n } = useTranslation();

  const signInButton = (
    <Button id="signIn" onClick={onClickSignIn}>
      {i18n("sign_in")}
    </Button>
  );

  return (
    <>
      <div
        className={mergeClasses(
          "top-nav-bar-web",
          flexClasses.root,
          flexClasses.row,
          flexClasses.rowGap20px,
          flexAlignClasses.justifyContentEnd,
          flexAlignClasses.alignItemCenter,
          topNavBarWebClasses.root
        )}
      >
        <HelpMenuButton />
        {!isAuthenticated ? (
          signInButton
        ) : (
          <div>
            <AccountWeb
              enableManageAccount={enableManageAccount}
              onClickSignOut={onClickSignOut}
              onClickManageAccount={onClickManageAccount}
            />
          </div>
        )}
      </div>
      <div
        className={mergeClasses(
          "top-nav-bar-web-spacer",
          topNavBarWebClasses.spacer
        )}
      />
    </>
  );
};

const AccountWeb: React.FunctionComponent<{
  enableManageAccount: boolean;
  onClickSignOut: () => void;
  onClickManageAccount: () => void;
}> = ({ enableManageAccount, onClickSignOut, onClickManageAccount }) => {
  const topNavBarWebClasses = topNavBarWebStyles();
  const buttonClasses = buttonStyles();
  const flexAlignClasses = flexAlignStyles();
  const flexItemClasses = flexItemStyles();
  const flexClasses = flexStyles();
  const avatarClasses = avatarStyles();
  const portalTextClasses = portalTextStyles();
  const skeletonItemClasses = skeletonItemStyles();

  const user = useSelector(userSelector);
  const isUserLoading = useSelector(isUserLoadingSelector);
  const profileImage = useSelector(imageSelector(user && user.profileImage));

  const { t: i18n } = useTranslation();
  const logger = useLogger()?.logger;

  const userProfile = user ? (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.row,
        flexClasses.rowGapMedium
      )}
      data-testid="userProfileContainer"
    >
      <Avatar
        size={40}
        className={mergeClasses(
          avatarClasses.root,
          topNavBarWebClasses.userProfileAvatar
        )}
        image={
          profileImage
            ? { src: profileImage }
            : /* istanbul ignore next */ undefined
        }
        name={user?.displayName || undefined}
        icon={<Person16Regular data-testid="userProfileIcon" />}
      />
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexItemClasses.grow,
          flexAlignClasses.justifyContentCenter,
          topNavBarWebClasses.userProfileTextContainer
        )}
      >
        <Text weight="bold"> {user.displayName} </Text>
        <Text
          className={mergeClasses(
            portalTextClasses.small,
            topNavBarWebClasses.userProfileEmail
          )}
          truncate
          wrap={false}
        >
          {user.email}
        </Text>
      </div>
    </div>
  ) : null;

  const loadingUserProfile = (
    <div className={mergeClasses(flexClasses.root, flexClasses.rowGapMedium)}>
      <Avatar
        size={40}
        data-testid="loadingUserProfileAvatar"
        className={mergeClasses(
          avatarClasses.root,
          topNavBarWebClasses.userProfileAvatar
        )}
        icon={<Person16Filled />}
      />
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexAlignClasses.justifyContentCenter,
          flexItemClasses.grow,
          topNavBarWebClasses.userProfileTextContainer
        )}
      >
        <Skeleton animation="pulse">
          <SkeletonItem
            data-testid="loadingUserProfileText"
            className={mergeClasses(
              skeletonItemClasses.root,
              skeletonItemClasses.textSize18,
              skeletonItemClasses.width100
            )}
          />
          <SkeletonItem
            className={mergeClasses(
              skeletonItemClasses.root,
              skeletonItemClasses.textSize18,
              skeletonItemClasses.width150
            )}
          />
        </Skeleton>
      </div>
    </div>
  );

  const onClickAvatar = () => {
    logger?.logUiTelemetry(
      "profileAvatar",
      UserBIScenario.ProfileAvatar,
      "click",
      "none",
      "TopNavBar"
    );
  };
  return (
    <Popover
      positioning={{
        position: "below",
        offset: { crossAxis: 0, mainAxis: 6 },
      }}
      trapFocus
    >
      <PopoverTrigger>
        <Button
          className={topNavBarWebClasses.userProfileTriggerButton}
          shape="circular"
        >
          <Avatar
            id="userProfileMenuButton"
            data-testid="userProfileMenuButton"
            image={profileImage ? { src: profileImage } : undefined}
            name={user?.displayName || undefined}
            icon={<Person16Filled />}
            onClick={onClickAvatar}
            className={avatarClasses.root}
          />
        </Button>
      </PopoverTrigger>
      <PopoverSurface
        className={
          isUserLoading
            ? topNavBarWebClasses.loadingUserProfileContainer
            : topNavBarWebClasses.userProfileContainer
        }
      >
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            flexClasses.columnGapSmaller
          )}
        >
          {isUserLoading ? loadingUserProfile : userProfile}
          <div
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              topNavBarWebClasses.userProfileButtonWrapper
            )}
          >
            {enableManageAccount && (
              <Button
                id="userProfile"
                appearance="transparent"
                className={mergeClasses(
                  buttonClasses.fluid,
                  flexClasses.root,
                  flexAlignClasses.justifyContentSpaceBetween,
                  topNavBarWebClasses.accountWebContent
                )}
                iconPosition="after"
                icon={<ChevronRight16Regular />}
                onClick={onClickManageAccount}
                aria-label={`${i18n("manage_account")} ${i18n("link_new_tab")}`}
              >
                {i18n("manage_account")}
              </Button>
            )}

            <Button
              id="signOut"
              appearance="transparent"
              className={mergeClasses(
                buttonClasses.fluid,
                flexClasses.root,
                flexAlignClasses.justifyContentSpaceBetween,
                topNavBarWebClasses.accountWebContent
              )}
              iconPosition="after"
              icon={<CalendarArrowRight20Filled />}
              onClick={onClickSignOut}
              aria-label={i18n("sign_out")}
            >
              {i18n("sign_out")}
            </Button>
          </div>
        </div>
      </PopoverSurface>
    </Popover>
  );
};
