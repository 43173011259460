import { shorthands, mergeClasses, makeStyles } from '@griffel/react';
import { tokens, typographyStyles } from '@fluentui/react-theme';
import { createSlideStyles } from '@fluentui/react-positioning';
export const menuPopoverClassNames = {
    root: 'fui-MenuPopover'
};
const useStyles = makeStyles({
    root: {
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        backgroundColor: tokens.colorNeutralBackground1,
        color: tokens.colorNeutralForeground1,
        boxSizing: 'border-box',
        minWidth: '138px',
        maxWidth: '300px',
        overflowX: 'hidden',
        width: 'max-content',
        boxShadow: `${tokens.shadow16}`,
        ...shorthands.padding('4px'),
        ...shorthands.border('1px', 'solid', tokens.colorTransparentStroke),
        ...typographyStyles.body1,
        ...createSlideStyles(10)
    }
});
/**
 * Apply styling to the Menu slots based on the state
 */ export const useMenuPopoverStyles_unstable = (state)=>{
    const styles = useStyles();
    state.root.className = mergeClasses(menuPopoverClassNames.root, styles.root, state.root.className);
    return state;
};
