import { AXHelper } from "../api/axHelper";
import { EventId } from "./CMDTypes";
import {
  IEventSession,
  IEventSessionResponse,
  IRecordingPlaybackInfo,
} from "./session.interface";
import { eventsRoute, recordingsRoute, sessionsRoute } from "./CMDRoutes";
import {
  getEventTenantId,
  isValidEventIdFormat,
} from "../../utilities/common/utils";
import { InvalidEventIdFormatError } from "./InvalidEventIdFormatError";
import { IScenarioLogger } from "../../common/logger/interface";

export async function fetchSessions(
  eventId: EventId
): Promise<IEventSessionResponse> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  const route: string = `${eventsRoute}/${eventId}/${sessionsRoute}`;
  return AXHelper.getDataFromServer(route, eventTenantId);
}

export async function fetchSession(
  eventId: EventId,
  sessionId: string
): Promise<IEventSession> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  const route: string = `${eventsRoute}/${eventId}/${sessionsRoute}/${sessionId}`;
  return AXHelper.getDataFromServer(route, eventTenantId);
}

export async function fetchRecordingPlaybackInfo(
  eventId: EventId,
  sessionId: string,
  recordingId: string,
  registrationId: string | undefined,
  presenterKey: string | undefined,
  badgerToken: string | undefined,
  scenario: IScenarioLogger | null
): Promise<IRecordingPlaybackInfo> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  let route: string = `${eventsRoute}/${eventId}/${sessionsRoute}/${sessionId}/${recordingsRoute}/${recordingId}/playback`;
  const params = new URLSearchParams();
  if (registrationId && !presenterKey) {
    params.append("registrationId", registrationId);
  }
  if (presenterKey) {
    params.append("presenterKey", presenterKey);
  }
  if (badgerToken) {
    params.append("badgerToken", badgerToken);
  }
  if (Array.from(params).length > 0) {
    route += "?" + params.toString();
  }
  return AXHelper.getDataFromServer(
    route,
    eventTenantId,
    scenario ?? undefined
  );
}
