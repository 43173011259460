export interface ITZMap {
  windowsTZ: string;
  territory: string;
  ianaTZ: string;
  intlStr: string;
}

export const tzMap: ITZMap[] = [
  {
    windowsTZ: "DatelineSt",
    territory: "001",
    ianaTZ: "Etc/GMT+12",
    intlStr: "DatelineSt",
  },
  {
    windowsTZ: "DatelineSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT+12",
    intlStr: "DatelineSt",
  },
  {
    windowsTZ: "UTC-11",
    territory: "001",
    ianaTZ: "Etc/GMT+11",
    intlStr: "UtcMinus11",
  },
  {
    windowsTZ: "UTC-11",
    territory: "AS",
    ianaTZ: "Pacific/Pago_Pago",
    intlStr: "UtcMinus11",
  },
  {
    windowsTZ: "UTC-11",
    territory: "NU",
    ianaTZ: "Pacific/Niue",
    intlStr: "UtcMinus11",
  },
  {
    windowsTZ: "UTC-11",
    territory: "UM",
    ianaTZ: "Pacific/Midway",
    intlStr: "UtcMinus11",
  },
  {
    windowsTZ: "UTC-11",
    territory: "ZZ",
    ianaTZ: "Etc/GMT+11",
    intlStr: "UtcMinus11",
  },
  {
    windowsTZ: "AleutianSt",
    territory: "001",
    ianaTZ: "America/Adak",
    intlStr: "AleutianSt",
  },
  {
    windowsTZ: "AleutianSt",
    territory: "US",
    ianaTZ: "America/Adak",
    intlStr: "AleutianSt",
  },
  {
    windowsTZ: "HawaiianSt",
    territory: "001",
    ianaTZ: "Pacific/Honolulu",
    intlStr: "HawaiianSt",
  },
  {
    windowsTZ: "HawaiianSt",
    territory: "CK",
    ianaTZ: "Pacific/Rarotonga",
    intlStr: "HawaiianSt",
  },
  {
    windowsTZ: "HawaiianSt",
    territory: "PF",
    ianaTZ: "Pacific/Tahiti",
    intlStr: "HawaiianSt",
  },
  {
    windowsTZ: "HawaiianSt",
    territory: "UM",
    ianaTZ: "Pacific/Johnston",
    intlStr: "HawaiianSt",
  },
  {
    windowsTZ: "HawaiianSt",
    territory: "US",
    ianaTZ: "Pacific/Honolulu",
    intlStr: "HawaiianSt",
  },
  {
    windowsTZ: "HawaiianSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT+10",
    intlStr: "HawaiianSt",
  },
  {
    windowsTZ: "MarquesasSt",
    territory: "001",
    ianaTZ: "Pacific/Marquesas",
    intlStr: "MarquesasSt",
  },
  {
    windowsTZ: "MarquesasSt",
    territory: "PF",
    ianaTZ: "Pacific/Marquesas",
    intlStr: "MarquesasSt",
  },
  {
    windowsTZ: "AlaskanSt",
    territory: "001",
    ianaTZ: "America/Anchorage",
    intlStr: "AlaskanSt",
  },
  {
    windowsTZ: "AlaskanSt",
    territory: "US",
    ianaTZ:
      "America/Anchorage America/Juneau America/Metlakatla America/Nome America/Sitka America/Yakutat",
    intlStr: "AlaskanSt",
  },
  {
    windowsTZ: "UTC-09",
    territory: "001",
    ianaTZ: "Etc/GMT+9",
    intlStr: "UtcMinus09",
  },
  {
    windowsTZ: "UTC-09",
    territory: "PF",
    ianaTZ: "Pacific/Gambier",
    intlStr: "UtcMinus09",
  },
  {
    windowsTZ: "UTC-09",
    territory: "ZZ",
    ianaTZ: "Etc/GMT+9",
    intlStr: "UtcMinus09",
  },
  {
    windowsTZ: "PacificStMexico",
    territory: "001",
    ianaTZ: "America/Tijuana",
    intlStr: "PacificStMexico",
  },
  {
    windowsTZ: "PacificStMexico",
    territory: "MX",
    ianaTZ: "America/Tijuana America/Santa_Isabel",
    intlStr: "PacificStMexico",
  },
  {
    windowsTZ: "UTC-08",
    territory: "001",
    ianaTZ: "Etc/GMT+8",
    intlStr: "UtcMinus08",
  },
  {
    windowsTZ: "UTC-08",
    territory: "PN",
    ianaTZ: "Pacific/Pitcairn",
    intlStr: "UtcMinus08",
  },
  {
    windowsTZ: "UTC-08",
    territory: "ZZ",
    ianaTZ: "Etc/GMT+8",
    intlStr: "UtcMinus08",
  },
  {
    windowsTZ: "PacificSt",
    territory: "001",
    ianaTZ: "America/Los_Angeles",
    intlStr: "PacificSt",
  },
  {
    windowsTZ: "PacificSt",
    territory: "CA",
    ianaTZ: "America/Vancouver",
    intlStr: "PacificSt",
  },
  {
    windowsTZ: "PacificSt",
    territory: "US",
    ianaTZ: "America/Los_Angeles",
    intlStr: "PacificSt",
  },
  {
    windowsTZ: "PacificSt",
    territory: "ZZ",
    ianaTZ: "PST8PDT",
    intlStr: "PacificSt",
  },
  {
    windowsTZ: "US MountainSt",
    territory: "001",
    ianaTZ: "America/Phoenix",
    intlStr: "UsMountainSt",
  },
  {
    windowsTZ: "US MountainSt",
    territory: "CA",
    ianaTZ: "America/Creston America/Dawson_Creek America/Fort_Nelson",
    intlStr: "UsMountainSt",
  },
  {
    windowsTZ: "US MountainSt",
    territory: "MX",
    ianaTZ: "America/Hermosillo America/Ciudad_Juarez",
    intlStr: "UsMountainSt",
  },
  {
    windowsTZ: "US MountainSt",
    territory: "US",
    ianaTZ: "America/Phoenix",
    intlStr: "UsMountainSt",
  },
  {
    windowsTZ: "US MountainSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT+7",
    intlStr: "UsMountainSt",
  },
  {
    windowsTZ: "MountainSt (Mexico)",
    territory: "001",
    ianaTZ: "America/Chihuahua",
    intlStr: "MountainStMexico",
  },
  {
    windowsTZ: "MountainSt (Mexico)",
    territory: "MX",
    ianaTZ: "America/Chihuahua America/Mazatlan",
    intlStr: "MountainStMexico",
  },
  {
    windowsTZ: "MountainSt",
    territory: "001",
    ianaTZ: "America/Denver",
    intlStr: "MountainSt",
  },
  {
    windowsTZ: "MountainSt",
    territory: "CA",
    ianaTZ:
      "America/Edmonton America/Cambridge_Bay America/Inuvik America/Yellowknife",
    intlStr: "MountainSt",
  },
  {
    windowsTZ: "MountainSt",
    territory: "MX",
    ianaTZ: "America/Ojinaga",
    intlStr: "MountainStMexico",
  },
  {
    windowsTZ: "MountainSt",
    territory: "US",
    ianaTZ: "America/Denver America/Boise",
    intlStr: "MountainSt",
  },
  {
    windowsTZ: "MountainSt",
    territory: "ZZ",
    ianaTZ: "MST7MDT",
    intlStr: "MountainSt",
  },
  {
    windowsTZ: "YukonSt",
    territory: "001",
    ianaTZ: "America/Whitehorse",
    intlStr: "YukonUSCA",
  },
  {
    windowsTZ: "YukonSt",
    territory: "CA",
    ianaTZ: "America/Whitehorse America/Dawson",
    intlStr: "YukonUSCA",
  },
  {
    windowsTZ: "Central AmericaSt",
    territory: "001",
    ianaTZ: "America/Guatemala",
    intlStr: "CentralAmericaSt",
  },
  {
    windowsTZ: "Central AmericaSt",
    territory: "BZ",
    ianaTZ: "America/Belize",
    intlStr: "CentralAmericaSt",
  },
  {
    windowsTZ: "Central AmericaSt",
    territory: "CR",
    ianaTZ: "America/Costa_Rica",
    intlStr: "CentralAmericaSt",
  },
  {
    windowsTZ: "Central AmericaSt",
    territory: "EC",
    ianaTZ: "Pacific/Galapagos",
    intlStr: "CentralAmericaSt",
  },
  {
    windowsTZ: "Central AmericaSt",
    territory: "GT",
    ianaTZ: "America/Guatemala",
    intlStr: "CentralAmericaSt",
  },
  {
    windowsTZ: "Central AmericaSt",
    territory: "HN",
    ianaTZ: "America/Tegucigalpa",
    intlStr: "CentralAmericaSt",
  },
  {
    windowsTZ: "Central AmericaSt",
    territory: "NI",
    ianaTZ: "America/Managua",
    intlStr: "CentralAmericaSt",
  },
  {
    windowsTZ: "Central AmericaSt",
    territory: "SV",
    ianaTZ: "America/El_Salvador",
    intlStr: "CentralAmericaSt",
  },
  {
    windowsTZ: "Central AmericaSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT+6",
    intlStr: "CentralAmericaSt",
  },
  {
    windowsTZ: "CentralSt",
    territory: "001",
    ianaTZ: "America/Chicago",
    intlStr: "CentralSt",
  },
  {
    windowsTZ: "CentralSt",
    territory: "CA",
    ianaTZ:
      "America/Winnipeg America/Rainy_River America/Rankin_Inlet America/Resolute",
    intlStr: "CentralSt",
  },
  {
    windowsTZ: "CentralSt",
    territory: "MX",
    ianaTZ: "America/Matamoros",
    intlStr: "CentralSt",
  },
  {
    windowsTZ: "CentralSt",
    territory: "US",
    ianaTZ:
      "America/Chicago America/Indiana/Knox America/Indiana/Tell_City America/Menominee America/North_Dakota/Beulah America/North_Dakota/Center America/North_Dakota/New_Salem",
    intlStr: "CentralSt",
  },
  {
    windowsTZ: "CentralSt",
    territory: "ZZ",
    ianaTZ: "CST6CDT",
    intlStr: "CentralSt",
  },
  {
    windowsTZ: "Easter IslandSt",
    territory: "001",
    ianaTZ: "Pacific/Easter",
    intlStr: "EasterIslandSt",
  },
  {
    windowsTZ: "Easter IslandSt",
    territory: "CL",
    ianaTZ: "Pacific/Easter",
    intlStr: "EasterIslandSt",
  },
  {
    windowsTZ: "CentralSt (Mexico)",
    territory: "001",
    ianaTZ: "America/Mexico_City",
    intlStr: "CentralStMexico",
  },
  {
    windowsTZ: "CentralSt (Mexico)",
    territory: "MX",
    ianaTZ:
      "America/Mexico_City America/Bahia_Banderas America/Merida America/Monterrey",
    intlStr: "CentralStMexico",
  },
  {
    windowsTZ: "Canada CentralSt",
    territory: "001",
    ianaTZ: "America/Regina",
    intlStr: "CanadaCentralSt",
  },
  {
    windowsTZ: "Canada CentralSt",
    territory: "CA",
    ianaTZ: "America/Regina America/Swift_Current",
    intlStr: "CanadaCentralSt",
  },
  {
    windowsTZ: "SA PacificSt",
    territory: "001",
    ianaTZ: "America/Bogota",
    intlStr: "SaPacificSt",
  },
  {
    windowsTZ: "SA PacificSt",
    territory: "BR",
    ianaTZ: "America/Rio_Branco America/Eirunepe",
    intlStr: "SaPacificSt",
  },
  {
    windowsTZ: "SA PacificSt",
    territory: "CA",
    ianaTZ: "America/Coral_Harbour",
    intlStr: "SaPacificSt",
  },
  {
    windowsTZ: "SA PacificSt",
    territory: "CO",
    ianaTZ: "America/Bogota",
    intlStr: "SaPacificSt",
  },
  {
    windowsTZ: "SA PacificSt",
    territory: "EC",
    ianaTZ: "America/Guayaquil",
    intlStr: "SaPacificSt",
  },
  {
    windowsTZ: "SA PacificSt",
    territory: "JM",
    ianaTZ: "America/Jamaica",
    intlStr: "SaPacificSt",
  },
  {
    windowsTZ: "SA PacificSt",
    territory: "KY",
    ianaTZ: "America/Cayman",
    intlStr: "SaPacificSt",
  },
  {
    windowsTZ: "SA PacificSt",
    territory: "PA",
    ianaTZ: "America/Panama",
    intlStr: "SaPacificSt",
  },
  {
    windowsTZ: "SA PacificSt",
    territory: "PE",
    ianaTZ: "America/Lima",
    intlStr: "SaPacificSt",
  },
  {
    windowsTZ: "SA PacificSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT+5",
    intlStr: "SaPacificSt",
  },
  {
    windowsTZ: "EasternSt (Mexico)",
    territory: "001",
    ianaTZ: "America/Cancun",
    intlStr: "EasternStMexico",
  },
  {
    windowsTZ: "EasternSt (Mexico)",
    territory: "MX",
    ianaTZ: "America/Cancun",
    intlStr: "EasternStMexico",
  },
  {
    windowsTZ: "EasternSt",
    territory: "001",
    ianaTZ: "America/New_York",
    intlStr: "EasternSt",
  },
  {
    windowsTZ: "EasternSt",
    territory: "BS",
    ianaTZ: "America/Nassau",
    intlStr: "EasternSt",
  },
  {
    windowsTZ: "EasternSt",
    territory: "CA",
    ianaTZ:
      "America/Toronto America/Iqaluit America/Montreal America/Nipigon America/Pangnirtung America/Thunder_Bay",
    intlStr: "EasternSt",
  },
  {
    windowsTZ: "EasternSt",
    territory: "US",
    ianaTZ:
      "America/New_York America/Detroit America/Indiana/Petersburg America/Indiana/Vincennes America/Indiana/Winamac America/Kentucky/Monticello America/Louisville",
    intlStr: "EasternSt",
  },
  {
    windowsTZ: "EasternSt",
    territory: "ZZ",
    ianaTZ: "EST5EDT",
    intlStr: "EasternSt",
  },
  {
    windowsTZ: "HaitiSt",
    territory: "001",
    ianaTZ: "America/Port-au-Prince",
    intlStr: "HaitiSt",
  },
  {
    windowsTZ: "HaitiSt",
    territory: "HT",
    ianaTZ: "America/Port-au-Prince",
    intlStr: "HaitiSt",
  },
  {
    windowsTZ: "CubaSt",
    territory: "001",
    ianaTZ: "America/Havana",
    intlStr: "CubaSt",
  },
  {
    windowsTZ: "CubaSt",
    territory: "CU",
    ianaTZ: "America/Havana",
    intlStr: "CubaSt",
  },
  {
    windowsTZ: "US EasternSt",
    territory: "001",
    ianaTZ: "America/Indianapolis America/Indiana/Indianapolis",
    intlStr: "EasternSt",
  },
  {
    windowsTZ: "US EasternSt",
    territory: "US",
    ianaTZ:
      "America/Indianapolis America/Indiana/Marengo America/Indiana/Vevay",
    intlStr: "EasternSt",
  },
  {
    windowsTZ: "Turks And CaicosSt",
    territory: "001",
    ianaTZ: "America/Grand_Turk",
    intlStr: "TurksAndCaicosSt",
  },
  {
    windowsTZ: "Turks And CaicosSt",
    territory: "TC",
    ianaTZ: "America/Grand_Turk",
    intlStr: "TurksAndCaicosSt",
  },
  {
    windowsTZ: "ParaguaySt",
    territory: "001",
    ianaTZ: "America/Asuncion",
    intlStr: "ParaguaySt",
  },
  {
    windowsTZ: "ParaguaySt",
    territory: "PY",
    ianaTZ: "America/Asuncion",
    intlStr: "ParaguaySt",
  },
  {
    windowsTZ: "AtlanticSt",
    territory: "001",
    ianaTZ: "America/Halifax",
    intlStr: "AtlanticSt",
  },
  {
    windowsTZ: "AtlanticSt",
    territory: "BM",
    ianaTZ: "Atlantic/Bermuda",
    intlStr: "AtlanticSt",
  },
  {
    windowsTZ: "AtlanticSt",
    territory: "CA",
    ianaTZ:
      "America/Halifax America/Glace_Bay America/Goose_Bay America/Moncton",
    intlStr: "AtlanticSt",
  },
  {
    windowsTZ: "AtlanticSt",
    territory: "GL",
    ianaTZ: "America/Thule",
    intlStr: "AtlanticSt",
  },
  {
    windowsTZ: "VenezuelaSt",
    territory: "001",
    ianaTZ: "America/Caracas",
    intlStr: "VenezulaSt",
  },
  {
    windowsTZ: "VenezuelaSt",
    territory: "VE",
    ianaTZ: "America/Caracas",
    intlStr: "VenezulaSt",
  },
  {
    windowsTZ: "Central BrazilianSt",
    territory: "001",
    ianaTZ: "America/Cuiaba",
    intlStr: "CentralBrazilianSt",
  },
  {
    windowsTZ: "Central BrazilianSt",
    territory: "BR",
    ianaTZ: "America/Cuiaba America/Campo_Grande",
    intlStr: "CentralBrazilianSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "001",
    ianaTZ: "America/La_Paz",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "AG",
    ianaTZ: "America/Antigua",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "AI",
    ianaTZ: "America/Anguilla",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "AW",
    ianaTZ: "America/Aruba",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "BB",
    ianaTZ: "America/Barbados",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "BL",
    ianaTZ: "America/St_Barthelemy",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "BO",
    ianaTZ: "America/La_Paz",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "BQ",
    ianaTZ: "America/Kralendijk",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "BR",
    ianaTZ: "America/Manaus America/Boa_Vista America/Porto_Velho",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "CA",
    ianaTZ: "America/Blanc-Sablon",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "CW",
    ianaTZ: "America/Curacao",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "DM",
    ianaTZ: "America/Dominica",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "DO",
    ianaTZ: "America/Santo_Domingo",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "GD",
    ianaTZ: "America/Grenada",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "GP",
    ianaTZ: "America/Guadeloupe",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "GY",
    ianaTZ: "America/Guyana",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "KN",
    ianaTZ: "America/St_Kitts",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "LC",
    ianaTZ: "America/St_Lucia",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "MF",
    ianaTZ: "America/Marigot",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "MQ",
    ianaTZ: "America/Martinique",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "MS",
    ianaTZ: "America/Montserrat",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "PR",
    ianaTZ: "America/Puerto_Rico",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "SX",
    ianaTZ: "America/Lower_Princes",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "TT",
    ianaTZ: "America/Port_of_Spain",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "VC",
    ianaTZ: "America/St_Vincent",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "VG",
    ianaTZ: "America/Tortola",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "VI",
    ianaTZ: "America/St_Thomas",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "SA WesternSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT+4",
    intlStr: "SaWesternSt",
  },
  {
    windowsTZ: "Pacific SASt",
    territory: "001",
    ianaTZ: "America/Santiago",
    intlStr: "PacificSaSt",
  },
  {
    windowsTZ: "Pacific SASt",
    territory: "CL",
    ianaTZ: "America/Santiago",
    intlStr: "PacificSaSt",
  },
  {
    windowsTZ: "NewfoundlandSt",
    territory: "001",
    ianaTZ: "America/St_Johns",
    intlStr: "NewfoundlandSt",
  },
  {
    windowsTZ: "NewfoundlandSt",
    territory: "CA",
    ianaTZ: "America/St_Johns",
    intlStr: "NewfoundlandSt",
  },
  {
    windowsTZ: "TocantinsSt",
    territory: "001",
    ianaTZ: "America/Araguaina",
    intlStr: "TocantinsSt",
  },
  {
    windowsTZ: "TocantinsSt",
    territory: "BR",
    ianaTZ: "America/Araguaina",
    intlStr: "TocantinsSt",
  },
  {
    windowsTZ: "E. South AmericaSt",
    territory: "001",
    ianaTZ: "America/Sao_Paulo",
    intlStr: "ESouthAmericaSt",
  },
  {
    windowsTZ: "E. South AmericaSt",
    territory: "BR",
    ianaTZ: "America/Sao_Paulo",
    intlStr: "ESouthAmericaSt",
  },
  {
    windowsTZ: "SA EasternSt",
    territory: "001",
    ianaTZ: "America/Cayenne",
    intlStr: "SaEasternSt",
  },
  {
    windowsTZ: "SA EasternSt",
    territory: "AQ",
    ianaTZ: "Antarctica/Rothera Antarctica/Palmer",
    intlStr: "SaEasternSt",
  },
  {
    windowsTZ: "SA EasternSt",
    territory: "BR",
    ianaTZ:
      "America/Fortaleza America/Belem America/Maceio America/Recife America/Santarem",
    intlStr: "SaEasternSt",
  },
  {
    windowsTZ: "SA EasternSt",
    territory: "FK",
    ianaTZ: "Atlantic/Stanley",
    intlStr: "SaEasternSt",
  },
  {
    windowsTZ: "SA EasternSt",
    territory: "GF",
    ianaTZ: "America/Cayenne",
    intlStr: "SaEasternSt",
  },
  {
    windowsTZ: "SA EasternSt",
    territory: "SR",
    ianaTZ: "America/Paramaribo",
    intlStr: "SaEasternSt",
  },
  {
    windowsTZ: "SA EasternSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT+3",
    intlStr: "SaEasternSt",
  },
  {
    windowsTZ: "ArgentinaSt",
    territory: "001",
    ianaTZ: "America/Buenos_Aires",
    intlStr: "ArgentinaSt",
  },
  {
    windowsTZ: "ArgentinaSt",
    territory: "AR",
    ianaTZ:
      "America/Buenos_Aires America/Argentina/La_Rioja America/Argentina/Rio_Gallegos America/Argentina/Salta America/Argentina/San_Juan America/Argentina/San_Luis America/Argentina/Tucuman America/Argentina/Ushuaia America/Catamarca America/Cordoba America/Jujuy America/Mendoza",
    intlStr: "ArgentinaSt",
  },
  {
    windowsTZ: "GreenlandSt",
    territory: "001",
    ianaTZ: "America/Godthab",
    intlStr: "GreenlandSt",
  },
  {
    windowsTZ: "GreenlandSt",
    territory: "GL",
    ianaTZ: "America/Godthab",
    intlStr: "GreenlandSt",
  },
  {
    windowsTZ: "MontevideoSt",
    territory: "001",
    ianaTZ: "America/Montevideo",
    intlStr: "MontevideoSt",
  },
  {
    windowsTZ: "MontevideoSt",
    territory: "UY",
    ianaTZ: "America/Montevideo",
    intlStr: "MontevideoSt",
  },
  {
    windowsTZ: "MagallanesSt",
    territory: "001",
    ianaTZ: "America/Punta_Arenas",
    intlStr: "MagallanesSt",
  },
  {
    windowsTZ: "MagallanesSt",
    territory: "CL",
    ianaTZ: "America/Punta_Arenas",
    intlStr: "MagallanesSt",
  },
  {
    windowsTZ: "Saint PierreSt",
    territory: "001",
    ianaTZ: "America/Miquelon",
    intlStr: "SaintPierreSt",
  },
  {
    windowsTZ: "Saint PierreSt",
    territory: "PM",
    ianaTZ: "America/Miquelon",
    intlStr: "SaintPierreSt",
  },
  {
    windowsTZ: "BahiaSt",
    territory: "001",
    ianaTZ: "America/Bahia",
    intlStr: "BahiaSt",
  },
  {
    windowsTZ: "BahiaSt",
    territory: "BR",
    ianaTZ: "America/Bahia",
    intlStr: "BahiaSt",
  },
  {
    windowsTZ: "UTC-02",
    territory: "001",
    ianaTZ: "Etc/GMT+2",
    intlStr: "UtcMinus02",
  },
  {
    windowsTZ: "UTC-02",
    territory: "BR",
    ianaTZ: "America/Noronha",
    intlStr: "UtcMinus02",
  },
  {
    windowsTZ: "UTC-02",
    territory: "GS",
    ianaTZ: "Atlantic/South_Georgia",
    intlStr: "UtcMinus02",
  },
  {
    windowsTZ: "UTC-02",
    territory: "ZZ",
    ianaTZ: "Etc/GMT+2",
    intlStr: "UtcMinus02",
  },
  {
    windowsTZ: "AzoresSt",
    territory: "001",
    ianaTZ: "Atlantic/Azores",
    intlStr: "AzoresSt",
  },
  {
    windowsTZ: "AzoresSt",
    territory: "GL",
    ianaTZ: "America/Scoresbysund",
    intlStr: "AzoresSt",
  },
  {
    windowsTZ: "AzoresSt",
    territory: "PT",
    ianaTZ: "Atlantic/Azores",
    intlStr: "AzoresSt",
  },
  {
    windowsTZ: "Cape VerdeSt",
    territory: "001",
    ianaTZ: "Atlantic/Cape_Verde",
    intlStr: "CapeVerdeSt",
  },
  {
    windowsTZ: "Cape VerdeSt",
    territory: "CV",
    ianaTZ: "Atlantic/Cape_Verde",
    intlStr: "CapeVerdeSt",
  },
  {
    windowsTZ: "Cape VerdeSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT+1",
    intlStr: "CapeVerdeSt",
  },
  {
    windowsTZ: "UTC",
    territory: "001",
    ianaTZ: "Etc/UTC",
    intlStr: "Utc",
  },
  {
    windowsTZ: "UTC",
    territory: "001",
    ianaTZ: "UTC",
    intlStr: "Utc",
  },
  {
    windowsTZ: "UTC",
    territory: "ZZ",
    ianaTZ: "Etc/UTC Etc/GMT",
    intlStr: "Utc",
  },
  {
    windowsTZ: "GMTSt",
    territory: "001",
    ianaTZ: "Europe/London",
    intlStr: "GmtSt",
  },
  {
    windowsTZ: "GMTSt",
    territory: "ES",
    ianaTZ: "Atlantic/Canary",
    intlStr: "GmtSt",
  },
  {
    windowsTZ: "GMTSt",
    territory: "FO",
    ianaTZ: "Atlantic/Faeroe",
    intlStr: "GmtSt",
  },
  {
    windowsTZ: "GMTSt",
    territory: "GB",
    ianaTZ: "Europe/London",
    intlStr: "GmtSt",
  },
  {
    windowsTZ: "GMTSt",
    territory: "GG",
    ianaTZ: "Europe/Guernsey",
    intlStr: "GmtSt",
  },
  {
    windowsTZ: "GMTSt",
    territory: "IE",
    ianaTZ: "Europe/Dublin",
    intlStr: "GmtSt",
  },
  {
    windowsTZ: "GMTSt",
    territory: "IM",
    ianaTZ: "Europe/Isle_of_Man",
    intlStr: "GmtSt",
  },
  {
    windowsTZ: "GMTSt",
    territory: "JE",
    ianaTZ: "Europe/Jersey",
    intlStr: "GmtSt",
  },
  {
    windowsTZ: "GMTSt",
    territory: "PT",
    ianaTZ: "Europe/Lisbon Atlantic/Madeira",
    intlStr: "GmtSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "001",
    ianaTZ: "Atlantic/Reykjavik",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "BF",
    ianaTZ: "Africa/Ouagadougou",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "CI",
    ianaTZ: "Africa/Abidjan",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "GH",
    ianaTZ: "Africa/Accra",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "GL",
    ianaTZ: "America/Danmarkshavn",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "GM",
    ianaTZ: "Africa/Banjul",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "GN",
    ianaTZ: "Africa/Conakry",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "GW",
    ianaTZ: "Africa/Bissau",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "IS",
    ianaTZ: "Atlantic/Reykjavik",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "LR",
    ianaTZ: "Africa/Monrovia",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "ML",
    ianaTZ: "Africa/Bamako",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "MR",
    ianaTZ: "Africa/Nouakchott",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "SH",
    ianaTZ: "Atlantic/St_Helena",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "SL",
    ianaTZ: "Africa/Freetown",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "SN",
    ianaTZ: "Africa/Dakar",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "GreenwichSt",
    territory: "TG",
    ianaTZ: "Africa/Lome",
    intlStr: "GreenwichSt",
  },
  {
    windowsTZ: "Sao TomeSt",
    territory: "001",
    ianaTZ: "Africa/Sao_Tome",
    intlStr: "SaoTomeSt",
  },
  {
    windowsTZ: "Sao TomeSt",
    territory: "ST",
    ianaTZ: "Africa/Sao_Tome",
    intlStr: "SaoTomeSt",
  },
  {
    windowsTZ: "MoroccoSt",
    territory: "001",
    ianaTZ: "Africa/Casablanca",
    intlStr: "MoroccoSt",
  },
  {
    windowsTZ: "MoroccoSt",
    territory: "EH",
    ianaTZ: "Africa/El_Aaiun",
    intlStr: "MoroccoSt",
  },
  {
    windowsTZ: "MoroccoSt",
    territory: "MA",
    ianaTZ: "Africa/Casablanca",
    intlStr: "MoroccoSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "001",
    ianaTZ: "Europe/Berlin",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "AD",
    ianaTZ: "Europe/Andorra",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "AT",
    ianaTZ: "Europe/Vienna",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "CH",
    ianaTZ: "Europe/Zurich",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "DE",
    ianaTZ: "Europe/Berlin Europe/Busingen",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "GI",
    ianaTZ: "Europe/Gibraltar",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "IT",
    ianaTZ: "Europe/Rome",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "LI",
    ianaTZ: "Europe/Vaduz",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "LU",
    ianaTZ: "Europe/Luxembourg",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "MC",
    ianaTZ: "Europe/Monaco",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "MT",
    ianaTZ: "Europe/Malta",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "NL",
    ianaTZ: "Europe/Amsterdam",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "NO",
    ianaTZ: "Europe/Oslo",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "SE",
    ianaTZ: "Europe/Stockholm",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "SJ",
    ianaTZ: "Arctic/Longyearbyen",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "SM",
    ianaTZ: "Europe/San_Marino",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "W. EuropeSt",
    territory: "VA",
    ianaTZ: "Europe/Vatican",
    intlStr: "WEuropeSt",
  },
  {
    windowsTZ: "Central EuropeSt",
    territory: "001",
    ianaTZ: "Europe/Budapest",
    intlStr: "CentralEuropeSt",
  },
  {
    windowsTZ: "Central EuropeSt",
    territory: "AL",
    ianaTZ: "Europe/Tirane",
    intlStr: "CentralEuropeSt",
  },
  {
    windowsTZ: "Central EuropeSt",
    territory: "CZ",
    ianaTZ: "Europe/Prague",
    intlStr: "CentralEuropeSt",
  },
  {
    windowsTZ: "Central EuropeSt",
    territory: "HU",
    ianaTZ: "Europe/Budapest",
    intlStr: "CentralEuropeSt",
  },
  {
    windowsTZ: "Central EuropeSt",
    territory: "ME",
    ianaTZ: "Europe/Podgorica",
    intlStr: "CentralEuropeSt",
  },
  {
    windowsTZ: "Central EuropeSt",
    territory: "RS",
    ianaTZ: "Europe/Belgrade",
    intlStr: "CentralEuropeSt",
  },
  {
    windowsTZ: "Central EuropeSt",
    territory: "SI",
    ianaTZ: "Europe/Ljubljana",
    intlStr: "CentralEuropeSt",
  },
  {
    windowsTZ: "Central EuropeSt",
    territory: "SK",
    ianaTZ: "Europe/Bratislava",
    intlStr: "CentralEuropeSt",
  },
  {
    windowsTZ: "RomanceSt",
    territory: "001",
    ianaTZ: "Europe/Paris",
    intlStr: "RomanceSt",
  },
  {
    windowsTZ: "RomanceSt",
    territory: "BE",
    ianaTZ: "Europe/Brussels",
    intlStr: "RomanceSt",
  },
  {
    windowsTZ: "RomanceSt",
    territory: "DK",
    ianaTZ: "Europe/Copenhagen",
    intlStr: "RomanceSt",
  },
  {
    windowsTZ: "RomanceSt",
    territory: "ES",
    ianaTZ: "Europe/Madrid Africa/Ceuta",
    intlStr: "RomanceSt",
  },
  {
    windowsTZ: "RomanceSt",
    territory: "FR",
    ianaTZ: "Europe/Paris",
    intlStr: "RomanceSt",
  },
  {
    windowsTZ: "Central EuropeanSt",
    territory: "001",
    ianaTZ: "Europe/Warsaw",
    intlStr: "CentralEuropeanSt",
  },
  {
    windowsTZ: "Central EuropeanSt",
    territory: "BA",
    ianaTZ: "Europe/Sarajevo",
    intlStr: "CentralEuropeanSt",
  },
  {
    windowsTZ: "Central EuropeanSt",
    territory: "HR",
    ianaTZ: "Europe/Zagreb",
    intlStr: "CentralEuropeanSt",
  },
  {
    windowsTZ: "Central EuropeanSt",
    territory: "MK",
    ianaTZ: "Europe/Skopje",
    intlStr: "CentralEuropeanSt",
  },
  {
    windowsTZ: "Central EuropeanSt",
    territory: "PL",
    ianaTZ: "Europe/Warsaw",
    intlStr: "CentralEuropeanSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "001",
    ianaTZ: "Africa/Lagos",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "AO",
    ianaTZ: "Africa/Luanda",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "BJ",
    ianaTZ: "Africa/Porto-Novo",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "CD",
    ianaTZ: "Africa/Kinshasa",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "CF",
    ianaTZ: "Africa/Bangui",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "CG",
    ianaTZ: "Africa/Brazzaville",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "CM",
    ianaTZ: "Africa/Douala",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "DZ",
    ianaTZ: "Africa/Algiers",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "GA",
    ianaTZ: "Africa/Libreville",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "GQ",
    ianaTZ: "Africa/Malabo",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "NE",
    ianaTZ: "Africa/Niamey",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "NG",
    ianaTZ: "Africa/Lagos",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "TD",
    ianaTZ: "Africa/Ndjamena",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "TN",
    ianaTZ: "Africa/Tunis",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "W. Central AfricaSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT-1",
    intlStr: "WCentralAfricaSt",
  },
  {
    windowsTZ: "JordanSt",
    territory: "001",
    ianaTZ: "Asia/Amman",
    intlStr: "JordanSt",
  },
  {
    windowsTZ: "JordanSt",
    territory: "JO",
    ianaTZ: "Asia/Amman",
    intlStr: "JordanSt",
  },
  {
    windowsTZ: "GTBSt",
    territory: "001",
    ianaTZ: "Europe/Bucharest",
    intlStr: "GTBSt",
  },
  {
    windowsTZ: "GTBSt",
    territory: "CY",
    ianaTZ: "Asia/Nicosia Asia/Famagusta",
    intlStr: "GTBSt",
  },
  {
    windowsTZ: "GTBSt",
    territory: "GR",
    ianaTZ: "Europe/Athens",
    intlStr: "GTBSt",
  },
  {
    windowsTZ: "GTBSt",
    territory: "RO",
    ianaTZ: "Europe/Bucharest",
    intlStr: "GTBSt",
  },
  {
    windowsTZ: "Middle EastSt",
    territory: "001",
    ianaTZ: "Asia/Beirut",
    intlStr: "MiddleEastSt",
  },
  {
    windowsTZ: "Middle EastSt",
    territory: "LB",
    ianaTZ: "Asia/Beirut",
    intlStr: "MiddleEastSt",
  },
  {
    windowsTZ: "EgyptSt",
    territory: "001",
    ianaTZ: "Africa/Cairo",
    intlStr: "EgyptSt",
  },
  {
    windowsTZ: "EgyptSt",
    territory: "EG",
    ianaTZ: "Africa/Cairo",
    intlStr: "EgyptSt",
  },
  {
    windowsTZ: "E. EuropeSt",
    territory: "001",
    ianaTZ: "Europe/Chisinau",
    intlStr: "EEuropeSt",
  },
  {
    windowsTZ: "E. EuropeSt",
    territory: "MD",
    ianaTZ: "Europe/Chisinau",
    intlStr: "EEuropeSt",
  },
  {
    windowsTZ: "SyriaSt",
    territory: "001",
    ianaTZ: "Asia/Damascus",
    intlStr: "SyriaSt",
  },
  {
    windowsTZ: "SyriaSt",
    territory: "SY",
    ianaTZ: "Asia/Damascus",
    intlStr: "SyriaSt",
  },
  {
    windowsTZ: "West BankSt",
    territory: "001",
    ianaTZ: "Asia/Hebron",
    intlStr: "WestBankSt",
  },
  {
    windowsTZ: "West BankSt",
    territory: "PS",
    ianaTZ: "Asia/Hebron Asia/Gaza",
    intlStr: "WestBankSt",
  },
  {
    windowsTZ: "South AfricaSt",
    territory: "001",
    ianaTZ: "Africa/Johannesburg",
    intlStr: "SouthAfricaSt",
  },
  {
    windowsTZ: "South AfricaSt",
    territory: "BI",
    ianaTZ: "Africa/Bujumbura",
    intlStr: "SouthAfricaSt",
  },
  {
    windowsTZ: "South AfricaSt",
    territory: "BW",
    ianaTZ: "Africa/Gaborone",
    intlStr: "SouthAfricaSt",
  },
  {
    windowsTZ: "South AfricaSt",
    territory: "CD",
    ianaTZ: "Africa/Lubumbashi",
    intlStr: "SouthAfricaSt",
  },
  {
    windowsTZ: "South AfricaSt",
    territory: "LS",
    ianaTZ: "Africa/Maseru",
    intlStr: "SouthAfricaSt",
  },
  {
    windowsTZ: "South AfricaSt",
    territory: "MW",
    ianaTZ: "Africa/Blantyre",
    intlStr: "SouthAfricaSt",
  },
  {
    windowsTZ: "South AfricaSt",
    territory: "MZ",
    ianaTZ: "Africa/Maputo",
    intlStr: "SouthAfricaSt",
  },
  {
    windowsTZ: "South AfricaSt",
    territory: "RW",
    ianaTZ: "Africa/Kigali",
    intlStr: "SouthAfricaSt",
  },
  {
    windowsTZ: "South AfricaSt",
    territory: "SZ",
    ianaTZ: "Africa/Mbabane",
    intlStr: "SouthAfricaSt",
  },
  {
    windowsTZ: "South AfricaSt",
    territory: "ZA",
    ianaTZ: "Africa/Johannesburg",
    intlStr: "SouthAfricaSt",
  },
  {
    windowsTZ: "South AfricaSt",
    territory: "ZM",
    ianaTZ: "Africa/Lusaka",
    intlStr: "SouthAfricaSt",
  },
  {
    windowsTZ: "South AfricaSt",
    territory: "ZW",
    ianaTZ: "Africa/Harare",
    intlStr: "SouthAfricaSt",
  },
  {
    windowsTZ: "South AfricaSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT-2",
    intlStr: "SouthAfricaSt",
  },
  {
    windowsTZ: "FLESt",
    territory: "001",
    ianaTZ: "Europe/Kiev",
    intlStr: "FLESt",
  },
  {
    windowsTZ: "FLESt",
    territory: "AX",
    ianaTZ: "Europe/Mariehamn",
    intlStr: "FLESt",
  },
  {
    windowsTZ: "FLESt",
    territory: "BG",
    ianaTZ: "Europe/Sofia",
    intlStr: "FLESt",
  },
  {
    windowsTZ: "FLESt",
    territory: "EE",
    ianaTZ: "Europe/Tallinn",
    intlStr: "FLESt",
  },
  {
    windowsTZ: "FLESt",
    territory: "FI",
    ianaTZ: "Europe/Helsinki",
    intlStr: "FLESt",
  },
  {
    windowsTZ: "FLESt",
    territory: "LT",
    ianaTZ: "Europe/Vilnius",
    intlStr: "FLESt",
  },
  {
    windowsTZ: "FLESt",
    territory: "LV",
    ianaTZ: "Europe/Riga",
    intlStr: "FLESt",
  },
  {
    windowsTZ: "FLESt",
    territory: "UA",
    ianaTZ: "Europe/Kiev Europe/Uzhgorod Europe/Zaporozhye",
    intlStr: "FLESt",
  },
  {
    windowsTZ: "IsraelSt",
    territory: "001",
    ianaTZ: "Asia/Jerusalem",
    intlStr: "IsraelSt",
  },
  {
    windowsTZ: "IsraelSt",
    territory: "IL",
    ianaTZ: "Asia/Jerusalem",
    intlStr: "IsraelSt",
  },
  {
    windowsTZ: "South SudanSt",
    territory: "001",
    ianaTZ: "Africa/Juba",
    intlStr: "SouthSudanSt",
  },
  {
    windowsTZ: "South SudanSt",
    territory: "SS",
    ianaTZ: "Africa/Juba",
    intlStr: "SouthSudanSt",
  },
  {
    windowsTZ: "KaliningradSt",
    territory: "001",
    ianaTZ: "Europe/Kaliningrad",
    intlStr: "KaliningradSt",
  },
  {
    windowsTZ: "KaliningradSt",
    territory: "RU",
    ianaTZ: "Europe/Kaliningrad",
    intlStr: "KaliningradSt",
  },
  {
    windowsTZ: "SudanSt",
    territory: "001",
    ianaTZ: "Africa/Khartoum",
    intlStr: "SudanSt",
  },
  {
    windowsTZ: "SudanSt",
    territory: "SD",
    ianaTZ: "Africa/Khartoum",
    intlStr: "SudanSt",
  },
  {
    windowsTZ: "LibyaSt",
    territory: "001",
    ianaTZ: "Africa/Tripoli",
    intlStr: "LibyaSt",
  },
  {
    windowsTZ: "LibyaSt",
    territory: "LY",
    ianaTZ: "Africa/Tripoli",
    intlStr: "LibyaSt",
  },
  {
    windowsTZ: "NamibiaSt",
    territory: "001",
    ianaTZ: "Africa/Windhoek",
    intlStr: "NamibiaSt",
  },
  {
    windowsTZ: "NamibiaSt",
    territory: "NA",
    ianaTZ: "Africa/Windhoek",
    intlStr: "NamibiaSt",
  },
  {
    windowsTZ: "ArabicSt",
    territory: "001",
    ianaTZ: "Asia/Baghdad",
    intlStr: "ArabicSt",
  },
  {
    windowsTZ: "ArabicSt",
    territory: "IQ",
    ianaTZ: "Asia/Baghdad",
    intlStr: "ArabicSt",
  },
  {
    windowsTZ: "TurkeySt",
    territory: "001",
    ianaTZ: "Europe/Istanbul",
    intlStr: "TurkeySt",
  },
  {
    windowsTZ: "TurkeySt",
    territory: "TR",
    ianaTZ: "Europe/Istanbul",
    intlStr: "TurkeySt",
  },
  {
    windowsTZ: "ArabSt",
    territory: "001",
    ianaTZ: "Asia/Riyadh",
    intlStr: "ArabSt",
  },
  {
    windowsTZ: "ArabSt",
    territory: "BH",
    ianaTZ: "Asia/Bahrain",
    intlStr: "ArabSt",
  },
  {
    windowsTZ: "ArabSt",
    territory: "KW",
    ianaTZ: "Asia/Kuwait",
    intlStr: "ArabSt",
  },
  {
    windowsTZ: "ArabSt",
    territory: "QA",
    ianaTZ: "Asia/Qatar",
    intlStr: "ArabSt",
  },
  {
    windowsTZ: "ArabSt",
    territory: "SA",
    ianaTZ: "Asia/Riyadh",
    intlStr: "ArabSt",
  },
  {
    windowsTZ: "ArabSt",
    territory: "YE",
    ianaTZ: "Asia/Aden",
    intlStr: "ArabSt",
  },
  {
    windowsTZ: "BelarusSt",
    territory: "001",
    ianaTZ: "Europe/Minsk",
    intlStr: "BelarusSt",
  },
  {
    windowsTZ: "BelarusSt",
    territory: "BY",
    ianaTZ: "Europe/Minsk",
    intlStr: "BelarusSt",
  },
  {
    windowsTZ: "RussianSt",
    territory: "001",
    ianaTZ: "Europe/Moscow",
    intlStr: "RussianSt",
  },
  {
    windowsTZ: "RussianSt",
    territory: "RU",
    ianaTZ: "Europe/Moscow Europe/Kirov",
    intlStr: "RussianSt",
  },
  {
    windowsTZ: "RussianSt",
    territory: "UA",
    ianaTZ: "Europe/Simferopol",
    intlStr: "RussianSt",
  },
  {
    windowsTZ: "E. AfricaSt",
    territory: "001",
    ianaTZ: "Africa/Nairobi",
    intlStr: "EAfricaSt",
  },
  {
    windowsTZ: "E. AfricaSt",
    territory: "AQ",
    ianaTZ: "Antarctica/Syowa",
    intlStr: "EAfricaSt",
  },
  {
    windowsTZ: "E. AfricaSt",
    territory: "DJ",
    ianaTZ: "Africa/Djibouti",
    intlStr: "EAfricaSt",
  },
  {
    windowsTZ: "E. AfricaSt",
    territory: "ER",
    ianaTZ: "Africa/Asmera",
    intlStr: "EAfricaSt",
  },
  {
    windowsTZ: "E. AfricaSt",
    territory: "ET",
    ianaTZ: "Africa/Addis_Ababa",
    intlStr: "EAfricaSt",
  },
  {
    windowsTZ: "E. AfricaSt",
    territory: "KE",
    ianaTZ: "Africa/Nairobi",
    intlStr: "EAfricaSt",
  },
  {
    windowsTZ: "E. AfricaSt",
    territory: "KM",
    ianaTZ: "Indian/Comoro",
    intlStr: "EAfricaSt",
  },
  {
    windowsTZ: "E. AfricaSt",
    territory: "MG",
    ianaTZ: "Indian/Antananarivo",
    intlStr: "EAfricaSt",
  },
  {
    windowsTZ: "E. AfricaSt",
    territory: "SO",
    ianaTZ: "Africa/Mogadishu",
    intlStr: "EAfricaSt",
  },
  {
    windowsTZ: "E. AfricaSt",
    territory: "TZ",
    ianaTZ: "Africa/Dar_es_Salaam",
    intlStr: "EAfricaSt",
  },
  {
    windowsTZ: "E. AfricaSt",
    territory: "UG",
    ianaTZ: "Africa/Kampala",
    intlStr: "EAfricaSt",
  },
  {
    windowsTZ: "E. AfricaSt",
    territory: "YT",
    ianaTZ: "Indian/Mayotte",
    intlStr: "EAfricaSt",
  },
  {
    windowsTZ: "E. AfricaSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT-3",
    intlStr: "EAfricaSt",
  },
  {
    windowsTZ: "IranSt",
    territory: "001",
    ianaTZ: "Asia/Tehran",
    intlStr: "IranSt",
  },
  {
    windowsTZ: "IranSt",
    territory: "IR",
    ianaTZ: "Asia/Tehran",
    intlStr: "IranSt",
  },
  {
    windowsTZ: "ArabianSt",
    territory: "001",
    ianaTZ: "Asia/Dubai",
    intlStr: "ArabianSt",
  },
  {
    windowsTZ: "ArabianSt",
    territory: "AE",
    ianaTZ: "Asia/Dubai",
    intlStr: "ArabianSt",
  },
  {
    windowsTZ: "ArabianSt",
    territory: "OM",
    ianaTZ: "Asia/Muscat",
    intlStr: "ArabianSt",
  },
  {
    windowsTZ: "ArabianSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT-4",
    intlStr: "ArabianSt",
  },
  {
    windowsTZ: "AstrakhanSt",
    territory: "001",
    ianaTZ: "Europe/Astrakhan",
    intlStr: "AstrakhanSt",
  },
  {
    windowsTZ: "AstrakhanSt",
    territory: "RU",
    ianaTZ: "Europe/Astrakhan Europe/Ulyanovsk",
    intlStr: "AstrakhanSt",
  },
  {
    windowsTZ: "AzerbaijanSt",
    territory: "001",
    ianaTZ: "Asia/Baku",
    intlStr: "AzerbaijanSt",
  },
  {
    windowsTZ: "AzerbaijanSt",
    territory: "AZ",
    ianaTZ: "Asia/Baku",
    intlStr: "AzerbaijanSt",
  },
  {
    windowsTZ: "Russia Time Zone 3",
    territory: "001",
    ianaTZ: "Europe/Samara",
    intlStr: "RussiaTimeZone3",
  },
  {
    windowsTZ: "Russia Time Zone 3",
    territory: "RU",
    ianaTZ: "Europe/Samara",
    intlStr: "RussiaTimeZone3",
  },
  {
    windowsTZ: "MauritiusSt",
    territory: "001",
    ianaTZ: "Indian/Mauritius",
    intlStr: "MauritiusSt",
  },
  {
    windowsTZ: "MauritiusSt",
    territory: "MU",
    ianaTZ: "Indian/Mauritius",
    intlStr: "MauritiusSt",
  },
  {
    windowsTZ: "MauritiusSt",
    territory: "RE",
    ianaTZ: "Indian/Reunion",
    intlStr: "MauritiusSt",
  },
  {
    windowsTZ: "MauritiusSt",
    territory: "SC",
    ianaTZ: "Indian/Mahe",
    intlStr: "MauritiusSt",
  },
  {
    windowsTZ: "SaratovSt",
    territory: "001",
    ianaTZ: "Europe/Saratov",
    intlStr: "SaratovSt",
  },
  {
    windowsTZ: "SaratovSt",
    territory: "RU",
    ianaTZ: "Europe/Saratov",
    intlStr: "SaratovSt",
  },
  {
    windowsTZ: "GeorgianSt",
    territory: "001",
    ianaTZ: "Asia/Tbilisi",
    intlStr: "GeorgianSt",
  },
  {
    windowsTZ: "GeorgianSt",
    territory: "GE",
    ianaTZ: "Asia/Tbilisi",
    intlStr: "GeorgianSt",
  },
  {
    windowsTZ: "VolgogradSt",
    territory: "001",
    ianaTZ: "Europe/Volgograd",
    intlStr: "VolgogradSt",
  },
  {
    windowsTZ: "VolgogradSt",
    territory: "RU",
    ianaTZ: "Europe/Volgograd",
    intlStr: "VolgogradSt",
  },
  {
    windowsTZ: "CaucasusSt",
    territory: "001",
    ianaTZ: "Asia/Yerevan",
    intlStr: "CaucasusSt",
  },
  {
    windowsTZ: "CaucasusSt",
    territory: "AM",
    ianaTZ: "Asia/Yerevan",
    intlStr: "CaucasusSt",
  },
  {
    windowsTZ: "AfghanistanSt",
    territory: "001",
    ianaTZ: "Asia/Kabul",
    intlStr: "AfghanistanSt",
  },
  {
    windowsTZ: "AfghanistanSt",
    territory: "AF",
    ianaTZ: "Asia/Kabul",
    intlStr: "AfghanistanSt",
  },
  {
    windowsTZ: "West AsiaSt",
    territory: "001",
    ianaTZ: "Asia/Tashkent",
    intlStr: "WestAsiaSt",
  },
  {
    windowsTZ: "West AsiaSt",
    territory: "AQ",
    ianaTZ: "Antarctica/Mawson",
    intlStr: "WestAsiaSt",
  },
  {
    windowsTZ: "West AsiaSt",
    territory: "KZ",
    ianaTZ: "Asia/Oral Asia/Aqtau Asia/Aqtobe Asia/Atyrau",
    intlStr: "WestAsiaSt",
  },
  {
    windowsTZ: "West AsiaSt",
    territory: "MV",
    ianaTZ: "Indian/Maldives",
    intlStr: "WestAsiaSt",
  },
  {
    windowsTZ: "West AsiaSt",
    territory: "TF",
    ianaTZ: "Indian/Kerguelen",
    intlStr: "WestAsiaSt",
  },
  {
    windowsTZ: "West AsiaSt",
    territory: "TJ",
    ianaTZ: "Asia/Dushanbe",
    intlStr: "WestAsiaSt",
  },
  {
    windowsTZ: "West AsiaSt",
    territory: "TM",
    ianaTZ: "Asia/Ashgabat",
    intlStr: "WestAsiaSt",
  },
  {
    windowsTZ: "West AsiaSt",
    territory: "UZ",
    ianaTZ: "Asia/Tashkent Asia/Samarkand",
    intlStr: "WestAsiaSt",
  },
  {
    windowsTZ: "West AsiaSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT-5",
    intlStr: "WestAsiaSt",
  },
  {
    windowsTZ: "EkaterinburgSt",
    territory: "001",
    ianaTZ: "Asia/Yekaterinburg",
    intlStr: "EkaterinburgSt",
  },
  {
    windowsTZ: "EkaterinburgSt",
    territory: "RU",
    ianaTZ: "Asia/Yekaterinburg",
    intlStr: "EkaterinburgSt",
  },
  {
    windowsTZ: "PakistanSt",
    territory: "001",
    ianaTZ: "Asia/Karachi",
    intlStr: "PakistanSt",
  },
  {
    windowsTZ: "PakistanSt",
    territory: "PK",
    ianaTZ: "Asia/Karachi",
    intlStr: "PakistanSt",
  },
  {
    windowsTZ: "QyzylordaSt",
    territory: "001",
    ianaTZ: "Asia/Qyzylorda",
    intlStr: "QyzylordaSt",
  },
  {
    windowsTZ: "QyzylordaSt",
    territory: "KZ",
    ianaTZ: "Asia/Qyzylorda",
    intlStr: "QyzylordaSt",
  },
  {
    windowsTZ: "IndiaSt",
    territory: "001",
    ianaTZ: "Asia/Calcutta Asia/Kolkata",
    intlStr: "IndianSt",
  },
  {
    windowsTZ: "IndiaSt",
    territory: "IN",
    ianaTZ: "Asia/Calcutta",
    intlStr: "IndianSt",
  },
  {
    windowsTZ: "Sri LankaSt",
    territory: "001",
    ianaTZ: "Asia/Colombo",
    intlStr: "SriLankaSt",
  },
  {
    windowsTZ: "Sri LankaSt",
    territory: "LK",
    ianaTZ: "Asia/Colombo",
    intlStr: "SriLankaSt",
  },
  {
    windowsTZ: "NepalSt",
    territory: "001",
    ianaTZ: "Asia/Katmandu",
    intlStr: "NepalSt",
  },
  {
    windowsTZ: "NepalSt",
    territory: "NP",
    ianaTZ: "Asia/Katmandu",
    intlStr: "NepalSt",
  },
  {
    windowsTZ: "Central AsiaSt",
    territory: "001",
    ianaTZ: "Asia/Almaty",
    intlStr: "CentralAsiaSt",
  },
  {
    windowsTZ: "Central AsiaSt",
    territory: "AQ",
    ianaTZ: "Antarctica/Vostok",
    intlStr: "CentralAsiaSt",
  },
  {
    windowsTZ: "Central AsiaSt",
    territory: "CN",
    ianaTZ: "Asia/Urumqi",
    intlStr: "CentralAsiaSt",
  },
  {
    windowsTZ: "Central AsiaSt",
    territory: "IO",
    ianaTZ: "Indian/Chagos",
    intlStr: "CentralAsiaSt",
  },
  {
    windowsTZ: "Central AsiaSt",
    territory: "KG",
    ianaTZ: "Asia/Bishkek",
    intlStr: "CentralAsiaSt",
  },
  {
    windowsTZ: "Central AsiaSt",
    territory: "KZ",
    ianaTZ: "Asia/Almaty Asia/Qostanay",
    intlStr: "CentralAsiaSt",
  },
  {
    windowsTZ: "Central AsiaSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT-6",
    intlStr: "CentralAsiaSt",
  },
  {
    windowsTZ: "BangladeshSt",
    territory: "001",
    ianaTZ: "Asia/Dhaka",
    intlStr: "BangladeshSt",
  },
  {
    windowsTZ: "BangladeshSt",
    territory: "BD",
    ianaTZ: "Asia/Dhaka",
    intlStr: "BangladeshSt",
  },
  {
    windowsTZ: "BangladeshSt",
    territory: "BT",
    ianaTZ: "Asia/Thimphu",
    intlStr: "BangladeshSt",
  },
  {
    windowsTZ: "OmskSt",
    territory: "001",
    ianaTZ: "Asia/Omsk",
    intlStr: "OmskSt",
  },
  {
    windowsTZ: "OmskSt",
    territory: "RU",
    ianaTZ: "Asia/Omsk",
    intlStr: "OmskSt",
  },
  {
    windowsTZ: "MyanmarSt",
    territory: "001",
    ianaTZ: "Asia/Rangoon",
    intlStr: "MyanmarSt",
  },
  {
    windowsTZ: "MyanmarSt",
    territory: "CC",
    ianaTZ: "Indian/Cocos",
    intlStr: "MyanmarSt",
  },
  {
    windowsTZ: "MyanmarSt",
    territory: "MM",
    ianaTZ: "Asia/Rangoon",
    intlStr: "MyanmarSt",
  },
  {
    windowsTZ: "SE AsiaSt",
    territory: "001",
    ianaTZ: "Asia/Bangkok",
    intlStr: "SeAsiaSt",
  },
  {
    windowsTZ: "SE AsiaSt",
    territory: "AQ",
    ianaTZ: "Antarctica/Davis",
    intlStr: "SeAsiaSt",
  },
  {
    windowsTZ: "SE AsiaSt",
    territory: "CX",
    ianaTZ: "Indian/Christmas",
    intlStr: "SeAsiaSt",
  },
  {
    windowsTZ: "SE AsiaSt",
    territory: "ID",
    ianaTZ: "Asia/Jakarta Asia/Pontianak",
    intlStr: "SeAsiaSt",
  },
  {
    windowsTZ: "SE AsiaSt",
    territory: "KH",
    ianaTZ: "Asia/Phnom_Penh",
    intlStr: "SeAsiaSt",
  },
  {
    windowsTZ: "SE AsiaSt",
    territory: "LA",
    ianaTZ: "Asia/Vientiane",
    intlStr: "SeAsiaSt",
  },
  {
    windowsTZ: "SE AsiaSt",
    territory: "TH",
    ianaTZ: "Asia/Bangkok",
    intlStr: "SeAsiaSt",
  },
  {
    windowsTZ: "SE AsiaSt",
    territory: "VN",
    ianaTZ: "Asia/Saigon",
    intlStr: "SeAsiaSt",
  },
  {
    windowsTZ: "SE AsiaSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT-7",
    intlStr: "SeAsiaSt",
  },
  {
    windowsTZ: "AltaiSt",
    territory: "001",
    ianaTZ: "Asia/Barnaul",
    intlStr: "AltaiSt",
  },
  {
    windowsTZ: "AltaiSt",
    territory: "RU",
    ianaTZ: "Asia/Barnaul",
    intlStr: "AltaiSt",
  },
  {
    windowsTZ: "W. MongoliaSt",
    territory: "001",
    ianaTZ: "Asia/Hovd",
    intlStr: "WestMongoliaSt",
  },
  {
    windowsTZ: "W. MongoliaSt",
    territory: "MN",
    ianaTZ: "Asia/Hovd",
    intlStr: "WestMongoliaSt",
  },
  {
    windowsTZ: "North AsiaSt",
    territory: "001",
    ianaTZ: "Asia/Krasnoyarsk",
    intlStr: "NAsiaSt",
  },
  {
    windowsTZ: "North AsiaSt",
    territory: "RU",
    ianaTZ: "Asia/Krasnoyarsk Asia/Novokuznetsk",
    intlStr: "NAsiaSt",
  },
  {
    windowsTZ: "N. Central AsiaSt",
    territory: "001",
    ianaTZ: "Asia/Novosibirsk",
    intlStr: "NCentralAsiaSt",
  },
  {
    windowsTZ: "N. Central AsiaSt",
    territory: "RU",
    ianaTZ: "Asia/Novosibirsk",
    intlStr: "NCentralAsiaSt",
  },
  {
    windowsTZ: "TomskSt",
    territory: "001",
    ianaTZ: "Asia/Tomsk",
    intlStr: "TomskSt",
  },
  {
    windowsTZ: "TomskSt",
    territory: "RU",
    ianaTZ: "Asia/Tomsk",
    intlStr: "TomskSt",
  },
  {
    windowsTZ: "ChinaSt",
    territory: "001",
    ianaTZ: "Asia/Shanghai",
    intlStr: "ChinaSt",
  },
  {
    windowsTZ: "ChinaSt",
    territory: "CN",
    ianaTZ: "Asia/Shanghai",
    intlStr: "ChinaSt",
  },
  {
    windowsTZ: "ChinaSt",
    territory: "HK",
    ianaTZ: "Asia/Hong_Kong",
    intlStr: "ChinaSt",
  },
  {
    windowsTZ: "ChinaSt",
    territory: "MO",
    ianaTZ: "Asia/Macau",
    intlStr: "ChinaSt",
  },
  {
    windowsTZ: "North Asia EastSt",
    territory: "001",
    ianaTZ: "Asia/Irkutsk",
    intlStr: "NorthAsiaEastSt",
  },
  {
    windowsTZ: "North Asia EastSt",
    territory: "RU",
    ianaTZ: "Asia/Irkutsk",
    intlStr: "NorthAsiaEastSt",
  },
  {
    windowsTZ: "SingaporeSt",
    territory: "001",
    ianaTZ: "Asia/Singapore",
    intlStr: "SingaporeSt",
  },
  {
    windowsTZ: "SingaporeSt",
    territory: "BN",
    ianaTZ: "Asia/Brunei",
    intlStr: "SingaporeSt",
  },
  {
    windowsTZ: "SingaporeSt",
    territory: "ID",
    ianaTZ: "Asia/Makassar",
    intlStr: "SingaporeSt",
  },
  {
    windowsTZ: "SingaporeSt",
    territory: "MY",
    ianaTZ: "Asia/Kuala_Lumpur Asia/Kuching",
    intlStr: "SingaporeSt",
  },
  {
    windowsTZ: "SingaporeSt",
    territory: "PH",
    ianaTZ: "Asia/Manila",
    intlStr: "SingaporeSt",
  },
  {
    windowsTZ: "SingaporeSt",
    territory: "SG",
    ianaTZ: "Asia/Singapore",
    intlStr: "SingaporeSt",
  },
  {
    windowsTZ: "SingaporeSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT-8",
    intlStr: "SingaporeSt",
  },
  {
    windowsTZ: "W. AustraliaSt",
    territory: "001",
    ianaTZ: "Australia/Perth",
    intlStr: "WAustraliaSt",
  },
  {
    windowsTZ: "W. AustraliaSt",
    territory: "AU",
    ianaTZ: "Australia/Perth",
    intlStr: "WAustraliaSt",
  },
  {
    windowsTZ: "TaipeiSt",
    territory: "001",
    ianaTZ: "Asia/Taipei",
    intlStr: "TaipeiSt",
  },
  {
    windowsTZ: "TaipeiSt",
    territory: "TW",
    ianaTZ: "Asia/Taipei",
    intlStr: "TaipeiSt",
  },
  {
    windowsTZ: "UlaanbaatarSt",
    territory: "001",
    ianaTZ: "Asia/Ulaanbaatar",
    intlStr: "UlaanbaatarSt",
  },
  {
    windowsTZ: "UlaanbaatarSt",
    territory: "MN",
    ianaTZ: "Asia/Ulaanbaatar Asia/Choibalsan",
    intlStr: "UlaanbaatarSt",
  },
  {
    windowsTZ: "Aus Central W.St",
    territory: "001",
    ianaTZ: "Australia/Eucla",
    intlStr: "AusCentralWestSt",
  },
  {
    windowsTZ: "Aus Central W.St",
    territory: "AU",
    ianaTZ: "Australia/Eucla",
    intlStr: "AusCentralWestSt",
  },
  {
    windowsTZ: "TransbaikalSt",
    territory: "001",
    ianaTZ: "Asia/Chita",
    intlStr: "TransbaikalSt",
  },
  {
    windowsTZ: "TransbaikalSt",
    territory: "RU",
    ianaTZ: "Asia/Chita",
    intlStr: "TransbaikalSt",
  },
  {
    windowsTZ: "TokyoSt",
    territory: "001",
    ianaTZ: "Asia/Tokyo",
    intlStr: "TokyoSt",
  },
  {
    windowsTZ: "TokyoSt",
    territory: "ID",
    ianaTZ: "Asia/Jayapura",
    intlStr: "TokyoSt",
  },
  {
    windowsTZ: "TokyoSt",
    territory: "JP",
    ianaTZ: "Asia/Tokyo",
    intlStr: "TokyoSt",
  },
  {
    windowsTZ: "TokyoSt",
    territory: "PW",
    ianaTZ: "Pacific/Palau",
    intlStr: "TokyoSt",
  },
  {
    windowsTZ: "TokyoSt",
    territory: "TL",
    ianaTZ: "Asia/Dili",
    intlStr: "TokyoSt",
  },
  {
    windowsTZ: "TokyoSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT-9",
    intlStr: "TokyoSt",
  },
  {
    windowsTZ: "North KoreaSt",
    territory: "001",
    ianaTZ: "Asia/Pyongyang",
    intlStr: "NorthKoreaSt",
  },
  {
    windowsTZ: "North KoreaSt",
    territory: "KP",
    ianaTZ: "Asia/Pyongyang",
    intlStr: "NorthKoreaSt",
  },
  {
    windowsTZ: "KoreaSt",
    territory: "001",
    ianaTZ: "Asia/Seoul",
    intlStr: "KoreaSt",
  },
  {
    windowsTZ: "KoreaSt",
    territory: "KR",
    ianaTZ: "Asia/Seoul",
    intlStr: "KoreaSt",
  },
  {
    windowsTZ: "YakutskSt",
    territory: "001",
    ianaTZ: "Asia/Yakutsk",
    intlStr: "YakutskSt",
  },
  {
    windowsTZ: "YakutskSt",
    territory: "RU",
    ianaTZ: "Asia/Yakutsk Asia/Khandyga",
    intlStr: "YakutskSt",
  },
  {
    windowsTZ: "Cen. AustraliaSt",
    territory: "001",
    ianaTZ: "Australia/Adelaide",
    intlStr: "CenAustraliaSt",
  },
  {
    windowsTZ: "Cen. AustraliaSt",
    territory: "AU",
    ianaTZ: "Australia/Adelaide Australia/Broken_Hill",
    intlStr: "CenAustraliaSt",
  },
  {
    windowsTZ: "AUS CentralSt",
    territory: "001",
    ianaTZ: "Australia/Darwin",
    intlStr: "AusCentralSt",
  },
  {
    windowsTZ: "AUS CentralSt",
    territory: "AU",
    ianaTZ: "Australia/Darwin",
    intlStr: "AusCentralSt",
  },
  {
    windowsTZ: "E. AustraliaSt",
    territory: "001",
    ianaTZ: "Australia/Brisbane",
    intlStr: "EAustraliaSt",
  },
  {
    windowsTZ: "E. AustraliaSt",
    territory: "AU",
    ianaTZ: "Australia/Brisbane Australia/Lindeman",
    intlStr: "EAustraliaSt",
  },
  {
    windowsTZ: "AUS EasternSt",
    territory: "001",
    ianaTZ: "Australia/Sydney",
    intlStr: "AusEasternSt",
  },
  {
    windowsTZ: "AUS EasternSt",
    territory: "AU",
    ianaTZ: "Australia/Sydney Australia/Melbourne",
    intlStr: "AusEasternSt",
  },
  {
    windowsTZ: "West PacificSt",
    territory: "001",
    ianaTZ: "Pacific/Port_Moresby",
    intlStr: "WestPacificSt",
  },
  {
    windowsTZ: "West PacificSt",
    territory: "AQ",
    ianaTZ: "Antarctica/DumontDUrville",
    intlStr: "WestPacificSt",
  },
  {
    windowsTZ: "West PacificSt",
    territory: "FM",
    ianaTZ: "Pacific/Truk",
    intlStr: "WestPacificSt",
  },
  {
    windowsTZ: "West PacificSt",
    territory: "GU",
    ianaTZ: "Pacific/Guam",
    intlStr: "WestPacificSt",
  },
  {
    windowsTZ: "West PacificSt",
    territory: "MP",
    ianaTZ: "Pacific/Saipan",
    intlStr: "WestPacificSt",
  },
  {
    windowsTZ: "West PacificSt",
    territory: "PG",
    ianaTZ: "Pacific/Port_Moresby",
    intlStr: "WestPacificSt",
  },
  {
    windowsTZ: "West PacificSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT-10",
    intlStr: "WestPacificSt",
  },
  {
    windowsTZ: "TasmaniaSt",
    territory: "001",
    ianaTZ: "Australia/Hobart",
    intlStr: "TasmaniaSt",
  },
  {
    windowsTZ: "TasmaniaSt",
    territory: "AU",
    ianaTZ: "Australia/Hobart Australia/Currie Antarctica/Macquarie",
    intlStr: "TasmaniaSt",
  },
  {
    windowsTZ: "VladivostokSt",
    territory: "001",
    ianaTZ: "Asia/Vladivostok",
    intlStr: "VladivostokSt",
  },
  {
    windowsTZ: "VladivostokSt",
    territory: "RU",
    ianaTZ: "Asia/Vladivostok Asia/Ust-Nera",
    intlStr: "VladivostokSt",
  },
  {
    windowsTZ: "Lord HoweSt",
    territory: "001",
    ianaTZ: "Australia/Lord_Howe",
    intlStr: "LordHoweSt",
  },
  {
    windowsTZ: "Lord HoweSt",
    territory: "AU",
    ianaTZ: "Australia/Lord_Howe",
    intlStr: "LordHoweSt",
  },
  {
    windowsTZ: "BougainvilleSt",
    territory: "001",
    ianaTZ: "Pacific/Bougainville",
    intlStr: "BougainvilleSt",
  },
  {
    windowsTZ: "BougainvilleSt",
    territory: "PG",
    ianaTZ: "Pacific/Bougainville",
    intlStr: "BougainvilleSt",
  },
  {
    windowsTZ: "Russia Time Zone 10",
    territory: "001",
    ianaTZ: "Asia/Srednekolymsk",
    intlStr: "RussiaTimeZone10",
  },
  {
    windowsTZ: "Russia Time Zone 10",
    territory: "RU",
    ianaTZ: "Asia/Srednekolymsk",
    intlStr: "RussiaTimeZone10",
  },
  {
    windowsTZ: "MagadanSt",
    territory: "001",
    ianaTZ: "Asia/Magadan",
    intlStr: "MagadanSt",
  },
  {
    windowsTZ: "MagadanSt",
    territory: "RU",
    ianaTZ: "Asia/Magadan",
    intlStr: "MagadanSt",
  },
  {
    windowsTZ: "NorfolkSt",
    territory: "001",
    ianaTZ: "Pacific/Norfolk",
    intlStr: "NorfolkSt",
  },
  {
    windowsTZ: "NorfolkSt",
    territory: "NF",
    ianaTZ: "Pacific/Norfolk",
    intlStr: "NorfolkSt",
  },
  {
    windowsTZ: "SakhalinSt",
    territory: "001",
    ianaTZ: "Asia/Sakhalin",
    intlStr: "SakhalinSt",
  },
  {
    windowsTZ: "SakhalinSt",
    territory: "RU",
    ianaTZ: "Asia/Sakhalin",
    intlStr: "SakhalinSt",
  },
  {
    windowsTZ: "Central PacificSt",
    territory: "001",
    ianaTZ: "Pacific/Guadalcanal",
    intlStr: "CentralPacificSt",
  },
  {
    windowsTZ: "Central PacificSt",
    territory: "AQ",
    ianaTZ: "Antarctica/Casey",
    intlStr: "CentralPacificSt",
  },
  {
    windowsTZ: "Central PacificSt",
    territory: "FM",
    ianaTZ: "Pacific/Ponape Pacific/Kosrae",
    intlStr: "CentralPacificSt",
  },
  {
    windowsTZ: "Central PacificSt",
    territory: "NC",
    ianaTZ: "Pacific/Noumea",
    intlStr: "CentralPacificSt",
  },
  {
    windowsTZ: "Central PacificSt",
    territory: "SB",
    ianaTZ: "Pacific/Guadalcanal",
    intlStr: "CentralPacificSt",
  },
  {
    windowsTZ: "Central PacificSt",
    territory: "VU",
    ianaTZ: "Pacific/Efate",
    intlStr: "CentralPacificSt",
  },
  {
    windowsTZ: "Central PacificSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT-11",
    intlStr: "CentralPacificSt",
  },
  {
    windowsTZ: "Russia Time Zone 11",
    territory: "001",
    ianaTZ: "Asia/Kamchatka",
    intlStr: "RussiaTimeZone11",
  },
  {
    windowsTZ: "Russia Time Zone 11",
    territory: "RU",
    ianaTZ: "Asia/Kamchatka Asia/Anadyr",
    intlStr: "RussiaTimeZone11",
  },
  {
    windowsTZ: "New ZealandSt",
    territory: "001",
    ianaTZ: "Pacific/Auckland",
    intlStr: "NewZealandSt",
  },
  {
    windowsTZ: "New ZealandSt",
    territory: "AQ",
    ianaTZ: "Antarctica/McMurdo",
    intlStr: "NewZealandSt",
  },
  {
    windowsTZ: "New ZealandSt",
    territory: "NZ",
    ianaTZ: "Pacific/Auckland",
    intlStr: "NewZealandSt",
  },
  {
    windowsTZ: "UTC+12",
    territory: "001",
    ianaTZ: "Etc/GMT-12",
    intlStr: "UTCPlus12",
  },
  {
    windowsTZ: "UTC+12",
    territory: "KI",
    ianaTZ: "Pacific/Tarawa",
    intlStr: "UTCPlus12",
  },
  {
    windowsTZ: "UTC+12",
    territory: "MH",
    ianaTZ: "Pacific/Majuro Pacific/Kwajalein",
    intlStr: "UTCPlus12",
  },
  {
    windowsTZ: "UTC+12",
    territory: "NR",
    ianaTZ: "Pacific/Nauru",
    intlStr: "UTCPlus12",
  },
  {
    windowsTZ: "UTC+12",
    territory: "TV",
    ianaTZ: "Pacific/Funafuti",
    intlStr: "UTCPlus12",
  },
  {
    windowsTZ: "UTC+12",
    territory: "UM",
    ianaTZ: "Pacific/Wake",
    intlStr: "UTCPlus12",
  },
  {
    windowsTZ: "UTC+12",
    territory: "WF",
    ianaTZ: "Pacific/Wallis",
    intlStr: "UTCPlus12",
  },
  {
    windowsTZ: "UTC+12",
    territory: "ZZ",
    ianaTZ: "Etc/GMT-12",
    intlStr: "UTCPlus12",
  },
  {
    windowsTZ: "FijiSt",
    territory: "001",
    ianaTZ: "Pacific/Fiji",
    intlStr: "FijiSt",
  },
  {
    windowsTZ: "FijiSt",
    territory: "FJ",
    ianaTZ: "Pacific/Fiji",
    intlStr: "FijiSt",
  },
  {
    windowsTZ: "Chatham IslandsSt",
    territory: "001",
    ianaTZ: "Pacific/Chatham",
    intlStr: "ChathamIslandsSt",
  },
  {
    windowsTZ: "Chatham IslandsSt",
    territory: "NZ",
    ianaTZ: "Pacific/Chatham",
    intlStr: "ChathamIslandsSt",
  },
  {
    windowsTZ: "UTC+13",
    territory: "001",
    ianaTZ: "Etc/GMT-13",
    intlStr: "UtcPlus13",
  },
  {
    windowsTZ: "UTC+13",
    territory: "KI",
    ianaTZ: "Pacific/Enderbury",
    intlStr: "UtcPlus13",
  },
  {
    windowsTZ: "UTC+13",
    territory: "TK",
    ianaTZ: "Pacific/Fakaofo",
    intlStr: "UtcPlus13",
  },
  {
    windowsTZ: "UTC+13",
    territory: "ZZ",
    ianaTZ: "Etc/GMT-13",
    intlStr: "UtcPlus13",
  },
  {
    windowsTZ: "TongaSt",
    territory: "001",
    ianaTZ: "Pacific/Tongatapu",
    intlStr: "TongaSt",
  },
  {
    windowsTZ: "TongaSt",
    territory: "TO",
    ianaTZ: "Pacific/Tongatapu",
    intlStr: "TongaSt",
  },
  {
    windowsTZ: "SamoaSt",
    territory: "001",
    ianaTZ: "Pacific/Apia",
    intlStr: "SamoaSt",
  },
  {
    windowsTZ: "SamoaSt",
    territory: "WS",
    ianaTZ: "Pacific/Apia",
    intlStr: "SamoaSt",
  },
  {
    windowsTZ: "Line IslandsSt",
    territory: "001",
    ianaTZ: "Pacific/Kiritimati",
    intlStr: "LineIslandsSt",
  },
  {
    windowsTZ: "Line IslandsSt",
    territory: "KI",
    ianaTZ: "Pacific/Kiritimati",
    intlStr: "LineIslandsSt",
  },
  {
    windowsTZ: "Line IslandsSt",
    territory: "ZZ",
    ianaTZ: "Etc/GMT-14",
    intlStr: "LineIslandsSt",
  },
];

export const ianaToWindowsTZ = (ianaTZ: string): string => {
  const res = tzMap.find((tz) => {
    return tz.ianaTZ.includes(ianaTZ);
  });
  if (!res) {
    throw Error(`Failed to map IANA TZ ${ianaTZ}`);
  }
  return res.windowsTZ;
};

export const browserTZToWindowsTZ = (): string => {
  const tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return ianaToWindowsTZ(tz);
};

export const localizedWindowsTZ = (ianaTZ: string): string => {
  const res = tzMap.find((tz) => {
    return tz.ianaTZ.includes(ianaTZ);
  });
  if (!res) {
    throw Error(
      `Failed to localize windowzTZ. The corressponding ianaTZ is ${ianaTZ}`
    );
  }
  return res.intlStr;
};

export const browserTZToLocalizedWindowsTZ = (): string => {
  const tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return localizedWindowsTZ(tz);
};
