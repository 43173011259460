import React, { useEffect, useState } from "react";
import {
  IEmbedLoaderOptions,
  IEmbedPlaybackSessionOptions,
  IPlaybackSession,
  ITokenRetriever,
  OnePlayerError,
  TokenType,
  createWebinarGenericFileEmbedLoader,
} from "@msstream/components-oneplayer-iframe-api";
import { useLogger } from "../../common/logger/LoggerContext";
import { isDevelopment } from "../../utilities/common/utils";
import { getLocale } from "../../core/localization/i18n";
import { useSelector } from "react-redux";
import { eventSelector } from "../../core/slices/eventSlice";
import { onePlayerStyles } from "./OnePlayer.styles";
import { PlayerLoading } from "../PlayerLoading";
import { Scenario } from "../../common/logger/Logger";
import { IRecordingPlaybackInfo } from "../../core/slices/session.interface";
import { mergeClasses } from "../../shared";
import { useAuthenticationService } from "../../core/auth/auth-context";
import { AccountInfo } from "@azure/msal-browser";

const PLAYBACK_SESSION_OPTIONS_HOST_APP = "TeamsVirtualEventsPortal";
const PLAYBACK_SESSION_OPTIONS_HOST_PLATFORM = "Web";
const PLAYBACK_SESSION_OPTIONS_HOST_MODE = "view";

export interface IOnePlayerProps {
  publishTimeStamp: string;
  playbackInfo?: IRecordingPlaybackInfo;
  badgerToken?: string;
  account: AccountInfo | null;
  hostView: string;
}

export interface IInternalOnePlayerProps {
  publishTimeStamp: string;
  playbackInfo?: IRecordingPlaybackInfo;
  badgerToken?: string;
  account: AccountInfo | null;
  hostView: string;
}

export const OnePlayer: React.FunctionComponent<IOnePlayerProps> = ({
  publishTimeStamp,
  playbackInfo,
  badgerToken,
  account,
  hostView,
}) => (
  <InternalOnePlayer
    key={playbackInfo?.id}
    publishTimeStamp={publishTimeStamp}
    playbackInfo={playbackInfo}
    badgerToken={badgerToken}
    account={account}
    hostView={hostView}
  />
);

const InternalOnePlayer: React.FunctionComponent<IInternalOnePlayerProps> = ({
  publishTimeStamp,
  playbackInfo,
  badgerToken,
  account,
  hostView,
}) => {
  const onePlayerclasses = onePlayerStyles();

  const authenticationService =
    useAuthenticationService().authenticationService;
  const logger = useLogger().logger;

  const [playerContainer, setPlayerContainer] =
    React.useState<HTMLDivElement | null>(null);
  const [playbackSession, setPlaybackSession] = useState<
    IPlaybackSession | undefined
  >(undefined);
  const [didMount, setDidMount] = React.useState<boolean>(false);

  const unsubscribePlaybackSessionId = React.useRef<() => void>();
  const unsubscribeIsPlayerReady = React.useRef<() => void>();
  const unsubscribeTtlMs = React.useRef<() => void>();
  const unsubscribeTtirMs = React.useRef<() => void>();
  const unsubscribePlayState = React.useRef<() => void>();

  const currentEvent = useSelector(eventSelector);
  const eventTheme = currentEvent?.theme;

  useEffect(() => {
    if (
      playerContainer != null &&
      playbackInfo &&
      (account || badgerToken) &&
      !didMount
    ) {
      const setupScenario = logger.createScenario(Scenario.OnePlayerSetup, {
        data: { publishTimeStamp },
      });

      const timestamp = performance.now();
      const timeOrigin = performance.timeOrigin;

      const checkTokenType = async (tokenType: TokenType) => {
        // OnePlayer only supports ODSP videos for now
        if (tokenType !== "SPO") {
          return Promise.reject(`Unsupported token type ${tokenType}`);
        }
      };

      const getSpoToken = async (
        resourceOrigin: string,
        account: AccountInfo
      ) => {
        const url = new URL(resourceOrigin);
        const res = await authenticationService.acquireToken(
          account,
          `${url.origin}/Container.Selected`
        );
        return res.accessToken;
      };

      const tokenRetriever: ITokenRetriever = {
        beginSilentSignOn:
          /* istanbul ignore next */
          () => {
            // Do nothing.
          },
        // getTokenAsync is required for the ITokenRetriever interface but does not support badger token. This should be deprecate in favor of getTokenWithAuthTypeAsync at some point.
        getTokenAsync: async (tokenType: TokenType, resourceOrigin: string) => {
          await checkTokenType(tokenType);
          if (account) {
            return getSpoToken(resourceOrigin, account);
          } else {
            return Promise.reject("Unsupported scenario");
          }
        },
        getTokenWithAuthTypeAsync: async (
          tokenType: TokenType,
          resourceOrigin: string
        ) => {
          await checkTokenType(tokenType);
          if (account) {
            return { token: await getSpoToken(resourceOrigin, account) };
          } else if (badgerToken) {
            return { token: badgerToken, authType: "Badger" };
          } else {
            return Promise.reject("Unsupported scenario");
          }
        },
      };

      const loaderOptions: IEmbedLoaderOptions = {
        tokenRetriever,
      };

      const onError = (
        disposeSession: () => void,
        onePlayerError: OnePlayerError
      ) => {
        const scenario =
          setupScenario && !setupScenario?.isScenarioStopped()
            ? setupScenario
            : logger.findScenario(Scenario.OnePlayerPlayback);

        if (onePlayerError.level === "fatal") {
          scenario?.fail({
            message: `OnePlayer fatal Error. level: ${onePlayerError.level}, isExpected: ${onePlayerError.isExpected}, scenerio: ${onePlayerError.scenario}, knownError: ${onePlayerError.knownError}, playbackSessionId: ${onePlayerError.playbackSessionId}, hostSessionId: ${onePlayerError.hostSessionId}`,
            data: {
              playbackSessionId: onePlayerError.playbackSessionId ?? "",
              publishTimeStamp,
            },
          });
        } else {
          scenario?.mark(onePlayerError.scenario, undefined, {
            message: `OnePlayer error. level: ${onePlayerError.level}, isExpected: ${onePlayerError.isExpected}, scenerio: ${onePlayerError.scenario}, knownError: ${onePlayerError.knownError}, playbackSessionId: ${onePlayerError.playbackSessionId}, hostSessionId: ${onePlayerError.hostSessionId}`,
            data: {
              playbackSessionId: onePlayerError.playbackSessionId ?? "",
              publishTimeStamp,
            },
          });
        }
      };

      const videoUrl = new URL(playbackInfo.streamingUrl);
      videoUrl.searchParams.set("playbackToken", playbackInfo.token);

      const playbackSessionOptions: IEmbedPlaybackSessionOptions = {
        hostApp: PLAYBACK_SESSION_OPTIONS_HOST_APP,
        hostView: hostView,
        hostPlatform: PLAYBACK_SESSION_OPTIONS_HOST_PLATFORM,
        hostMode: PLAYBACK_SESSION_OPTIONS_HOST_MODE,
        hostEnvironment: isDevelopment() ? "Dev" : "Prod",
        hostSessionId: logger.sessionId,
        hostTimestamps: {
          t0: {
            timestamp,
            timeOrigin,
          },
        },
        videoId: {
          videoUrl: videoUrl.toString(),
        },
        containerDiv: playerContainer,
        allowFullscreen: true,
        onError,
        overrideLanguage: getLocale(),
        theme: {
          primaryColor: eventTheme?.primaryColor,
        },
        hideVideoMetadata: true,
      };

      const initPlaybackSession = async (
        loaderOptions: IEmbedLoaderOptions,
        playbackSessionOptions: IEmbedPlaybackSessionOptions
      ) => {
        try {
          const loader = createWebinarGenericFileEmbedLoader(loaderOptions);
          const _playbackSession = await loader.createPlaybackSession(
            false,
            playbackSessionOptions
          );
          setPlaybackSession(_playbackSession);

          setupScenario?.stop();
        } catch (err) {
          let message = "OnePlayer create playback session promise rejected.";
          if (err instanceof OnePlayerError) {
            message += ` level: ${err.level}, isExpected: ${err.isExpected}, scenerio: ${err.scenario}, knownError: ${err.knownError}, playbackSessionId: ${err.playbackSessionId}, hostSessionId: ${err.hostSessionId}`;
          }
          setupScenario?.fail({
            message,
            data: { publishTimeStamp },
          });
        }
      };
      initPlaybackSession(loaderOptions, playbackSessionOptions);
      setDidMount(true);
    }
  }, [
    account,
    authenticationService,
    badgerToken,
    didMount,
    eventTheme?.primaryColor,
    hostView,
    logger,
    playbackInfo,
    playerContainer,
    publishTimeStamp,
  ]);

  useEffect(() => {
    if (playbackSession) {
      const playbackScenario = logger.createScenario(
        Scenario.OnePlayerPlayback,
        {
          data: { publishTimeStamp },
        }
      );

      unsubscribeIsPlayerReady.current =
        playbackSession.playbackApi.isPlayerReady.subscribe((value) => {
          if (value) {
            playbackScenario?.mark("PlayerReady");
          }
        });

      unsubscribePlayState.current =
        playbackSession.playbackApi.playState.subscribe((value) => {
          if (value) {
            playbackScenario?.mark(value);
          }
        });

      unsubscribePlaybackSessionId.current =
        playbackSession.playerStats.playbackSessionId.subscribe((value) => {
          if (value) {
            playbackScenario?.mark("PlayerStats.playbackSessionId", undefined, {
              data: {
                playbackSessionId: value,
              },
            });
          }
        });

      unsubscribeTtlMs.current = playbackSession.playerStats.ttlMs.subscribe(
        (value) => {
          if (value) {
            playbackScenario?.mark("PlayerStats.ttlMs", undefined, {
              data: {
                ttlMs: value,
              },
            });
          }
        }
      );

      unsubscribeTtirMs.current = playbackSession.playerStats.ttirMs.subscribe(
        (value) => {
          if (value) {
            playbackScenario?.mark("PlayerStats.ttirMs", undefined, {
              data: {
                ttirMs: value,
              },
            });
          }
        }
      );

      return () => {
        playbackSession?.dispose();

        unsubscribePlaybackSessionId.current &&
          unsubscribePlaybackSessionId.current();
        unsubscribePlaybackSessionId.current = undefined;

        unsubscribeIsPlayerReady.current && unsubscribeIsPlayerReady.current();
        unsubscribeIsPlayerReady.current = undefined;

        unsubscribeTtlMs.current && unsubscribeTtlMs.current();
        unsubscribeTtlMs.current = undefined;

        unsubscribeTtirMs.current && unsubscribeTtirMs.current();
        unsubscribeTtirMs.current = undefined;

        unsubscribePlayState.current && unsubscribePlayState.current();
        unsubscribePlayState.current = undefined;

        if (playbackScenario && !playbackScenario.isScenarioStopped()) {
          playbackScenario?.stop();
        }
      };
    }
  }, [logger, playbackSession, publishTimeStamp]);

  return (
    <div>
      {!playbackSession && <PlayerLoading />}
      <div
        className={mergeClasses(!playbackSession && onePlayerclasses.hidden)}
      >
        <div
          data-testid={playbackSession && "one-player"}
          ref={setPlayerContainer}
          className={onePlayerclasses.container}
        />
      </div>
    </div>
  );
};
