import { CmdServicesResponseErrorCode, ErrorType, IError } from "./error";
import { RecordingPublishStatus } from "./session.interface";

export const isAttendeeNotFoundError = (response: IError): boolean => {
  return (
    !!response &&
    response.type === ErrorType.CMD_SERVICES &&
    response.responseErrorCode === CmdServicesResponseErrorCode.FORBIDDEN &&
    response.responseErrorMessage === "Attendee not found by id." &&
    response.status === 403
  );
};

export const isExpiredRecordingError = (response: IError): boolean => {
  return (
    !!response &&
    response.type === ErrorType.CMD_SERVICES &&
    response.responseErrorCode === CmdServicesResponseErrorCode.BAD_REQUEST &&
    !!response.responseErrorMessage &&
    response.responseErrorMessage.startsWith(
      "Recording is not in valid status."
    ) &&
    response.responseErrorMessage.includes(
      ` Status: ${RecordingPublishStatus.Expired}`
    ) &&
    response.status === 400
  );
};
