import React from "react";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventUser } from "../../core/slices/userTypes.interface";
import { profileEditAccountFieldsStyles } from "../../styles/ProfileEditAccount";
import { useLogger } from "../../common/logger/LoggerContext";
import { LoggerLevels } from "../../common/logger/interface";
import { useSelector } from "react-redux";
import { userSelector } from "../../core/slices/userSlice";
import {
  emailValidator,
  PortalFormField,
  PortalFormFields,
  PortalFormFieldType,
  PortalFormValidatorProvider,
  githubUserUrlValidator,
  lengthValidator,
  linkedinProfileUrlValidator,
  requiredStringValidator,
  twitterUserUrlValidator,
  usePortalFormValidatorContext,
  ButtonV9 as Button,
  AvatarV9 as Avatar,
  getDocument,
  mergeClasses,
  TextV9 as Text,
  Person16Filled,
} from "../../shared";
import {
  enableEditAccountSelector,
  showEditAccountNonMvpFieldsSelector,
} from "../../core/slices/ecsSlice";
import { ProfilePageHeaderMobile } from "./ProfilePageHeaderMobile";
import { profilePageStyles } from "../../styles/ProfilePage";
import { useBreakpoint } from "../../utilities/hooks/useBreakpoints";
import { imageSelector } from "../../core/slices/imageSlice";
import { PortalFormFieldValue } from "../../shared/form/PortalFormFields";
import { Breakpoint, gridStyles } from "../../styles/Grid";
import { flexStyles } from "../../styles/FlexStyles";
import { portalTextStyles } from "../../styles/PortalText";
import { profileAccountSettingsStyles } from "../../styles/ProfileAccountSettings";

export const ProfileAccountInfo: React.FunctionComponent = () => {
  const user = useSelector(userSelector);
  if (!user) {
    // TODO: have loading spinner until user is fetched?
    return null;
    // show failed to load user
  }

  return (
    <PortalFormValidatorProvider>
      <ProfileEditAccountInternal user={user} />
    </PortalFormValidatorProvider>
  );
};

const ProfileEditAccountInternal: React.FunctionComponent<{
  user: EventUser;
}> = ({ user }) => {
  const flexClasses = flexStyles();
  const gridClasses = gridStyles();
  const portalTextClasses = portalTextStyles();
  const profileAccountSettingsClasses = profileAccountSettingsStyles();
  const profileEditAccountFieldsClasses = profileEditAccountFieldsStyles();
  const profilePageClasses = profilePageStyles();
  const { t: i18n } = useTranslation();
  const logger = useLogger()?.logger;
  const document = getDocument();
  const referrer = document?.referrer;
  const enableEditAccount = useSelector(enableEditAccountSelector);
  const showEditAccountNonMvpFields = useSelector(
    showEditAccountNonMvpFieldsSelector
  );
  const { isBreakpointAndDown } = useBreakpoint();
  const mobileView = isBreakpointAndDown(Breakpoint.Medium);
  const profileImage = useSelector(imageSelector(user && user.profileImage));

  const initialInterests: string[] = ["Advertise", "Marketing", "Technology"];

  // TODO: Fetch data.
  const industryOptions = [
    { key: "engineering", content: "Engineering" },
    { key: "math", content: "Math" },
    { key: "science", content: "Science" },
    { key: "technology", content: "Technology" },
  ];

  // TODO: Fetch data (countries?).
  const locationOptions = [
    { key: "CA", content: "Canada" },
    { key: "US", content: "USA" },
  ];

  const [hasFormChanged, setHasFormChanged] = useState(false);
  const [hasSaveBeenClicked, setHasSaveBeenClicked] = useState(false);

  const initialContactInfoFormFieldsState = [
    {
      type: PortalFormFieldType.Text,
      name: "firstName",
      labelTranslationKey: "first_name_label",
      placeholderTranslationKey: "first_name_field_placeholder",
      value: user.firstName || /* istanbul ignore next */ "",
      validatorFns: [lengthValidator, requiredStringValidator],
      disabled: !enableEditAccount,
      borderless: !mobileView,
      className: mergeClasses(
        gridClasses.col,
        gridClasses.xs12,
        gridClasses.md6
      ),
    },
    {
      type: PortalFormFieldType.Text,
      name: "lastName",
      labelTranslationKey: "last_name_label",
      placeholderTranslationKey: "last_name_field_placeholder",
      value: user.lastName || /* istanbul ignore next */ "",
      validatorFns: [lengthValidator, requiredStringValidator],
      disabled: !enableEditAccount,
      borderless: !mobileView,
      className: mergeClasses(
        gridClasses.col,
        gridClasses.xs12,
        gridClasses.md6
      ),
    },
    {
      type: PortalFormFieldType.Text,
      name: "email",
      labelTranslationKey: "email_label",
      placeholderTranslationKey: "email_field_placeholder",
      value: user.email,
      validatorFns: [lengthValidator, requiredStringValidator, emailValidator],
      disabled: !enableEditAccount,
      borderless: !mobileView,
      className: mergeClasses(
        gridClasses.col,
        gridClasses.xs12,
        gridClasses.md6
      ),
    },
    {
      type: PortalFormFieldType.Text,
      name: "displayName",
      labelTranslationKey: "display_name_label",
      placeholderTranslationKey: "display_name_field_placeholder",
      value: user.displayName || /* istanbul ignore next */ "",
      validatorFns: [lengthValidator, requiredStringValidator],
      disabled: !enableEditAccount,
      borderless: !mobileView,
      className: mergeClasses(
        gridClasses.col,
        gridClasses.xs12,
        gridClasses.md6
      ),
    },
  ];
  if (!!showEditAccountNonMvpFields) {
    initialContactInfoFormFieldsState.push(
      ...[
        {
          type: PortalFormFieldType.Text,
          name: "phone",
          labelTranslationKey: "phone_label",
          placeholderTranslationKey: "phone_field_placeholder",
          value: "", // TODO: EventUser does not have phone, fetch this info?
          validatorFns: [lengthValidator],
          disabled: !enableEditAccount,
          borderless: !mobileView,
          className: mergeClasses(
            gridClasses.col,
            gridClasses.xs12,
            gridClasses.md6
          ),
        },
        {
          type: PortalFormFieldType.Text,
          name: "pronoun",
          labelTranslationKey: "pronoun_label",
          placeholderTranslationKey: "pronoun_field_placeholder",
          value: "", // TODO: EventUser does not have pronoun, fetch this info?
          validatorFns: [lengthValidator],
          disabled: !enableEditAccount,
          borderless: !mobileView,
          className: mergeClasses(
            gridClasses.col,
            gridClasses.xs12,
            gridClasses.md6
          ),
        },
      ]
    );
  }
  const [contactInfoFormFields, setContactInfoFormFields] = useState<
    PortalFormField[]
  >(initialContactInfoFormFieldsState);

  const initialAboutYouFormFieldsState = !!showEditAccountNonMvpFields
    ? [
        {
          type: PortalFormFieldType.Text,
          name: "organization",
          labelTranslationKey: "organization_label",
          placeholderTranslationKey: "organization_field_placeholder",
          value: user.userBio?.company || /* istanbul ignore next */ "",
          validatorFns: [lengthValidator],
          disabled: !enableEditAccount,
          borderless: !mobileView,
          className: mergeClasses(
            gridClasses.col,
            gridClasses.xs12,
            gridClasses.md6
          ),
        },
        {
          type: PortalFormFieldType.DropDown,
          name: "industry",
          labelTranslationKey: "industry_label",
          placeholderTranslationKey: "industry_field_placeholder",
          value: "", // TODO: EventUser does not have industry, fetch this info?
          options: industryOptions,
          disabled: !enableEditAccount,
          borderless: !mobileView,
          className: mergeClasses(
            gridClasses.col,
            gridClasses.xs12,
            gridClasses.md6
          ),
        },
        {
          type: PortalFormFieldType.DropDown,
          name: "location",
          labelTranslationKey: "location_label",
          placeholderTranslationKey: "location_field_placeholder",
          value: "", // TODO: EventUser does not have location/country, fetch this info?
          options: locationOptions,
          disabled: !enableEditAccount,
          borderless: !mobileView,
          className: mergeClasses(gridClasses.col, gridClasses.xs12),
        },
        {
          type: PortalFormFieldType.Text,
          name: "interests",
          labelTranslationKey: "interests_label",
          placeholderTranslationKey: "interests_field_placeholder",
          value: initialInterests.join(","), // TODO: EventUser does not have interests, fetch this info?
          disabled: !enableEditAccount,
          borderless: !mobileView,
          className: mergeClasses(gridClasses.col, gridClasses.xs12),
        },
      ]
    : [];
  const [aboutYouFormFields, setAboutYouFormFields] = useState<
    PortalFormField[]
  >(initialAboutYouFormFieldsState);

  const initialOnlinePresenceFormFieldsState = !!showEditAccountNonMvpFields
    ? [
        {
          type: PortalFormFieldType.Text,
          name: "linkedin",
          labelTranslationKey: "linkedin_label",
          value: user.userBio?.socialMediaLinks?.["linkedIn"],
          placeholderTranslationKey: "linkedin_field_placeholder",
          validatorFns: [linkedinProfileUrlValidator],
          disabled: !enableEditAccount,
          borderless: !mobileView,
          className: mergeClasses(gridClasses.col, gridClasses.xs12),
        },
        {
          type: PortalFormFieldType.Text,
          name: "twitter",
          labelTranslationKey: "twitter_label",
          placeholderTranslationKey: "twitter_field_placeholder",
          value: user.userBio?.socialMediaLinks?.["twitter"],
          validatorFns: [twitterUserUrlValidator],
          disabled: !enableEditAccount,
          borderless: !mobileView,
          className: mergeClasses(gridClasses.col, gridClasses.xs12),
        },
        {
          type: PortalFormFieldType.Text,
          name: "github",
          labelTranslationKey: "github_label",
          placeholderTranslationKey: "github_field_placeholder",
          value: user.userBio?.socialMediaLinks?.["github"],
          validatorFns: [githubUserUrlValidator],
          disabled: !enableEditAccount,
          borderless: !mobileView,
          className: mergeClasses(gridClasses.col, gridClasses.xs12),
        },
      ]
    : [];
  const [onlinePresenceFormFields, setOnlinePresenceFormFields] = useState<
    PortalFormField[]
  >(initialOnlinePresenceFormFieldsState);

  const { errorCount } = usePortalFormValidatorContext();

  const onClickSave = () => {
    setHasSaveBeenClicked(true);
    if (!errorCount) {
      const fields: PortalFormField[] = ([] as PortalFormField[]).concat(
        contactInfoFormFields,
        aboutYouFormFields,
        onlinePresenceFormFields
      );
      logger?.logTrace(
        LoggerLevels.debug,
        `ProfileAccount: submited form fields = ${JSON.stringify(
          fields
        )} referrer link ${referrer}`
      );
      // TODO: Dispatch action to submit data.
    }
  };

  const onFieldChange = (
    form: PortalFormField[],
    setForm: Dispatch<SetStateAction<PortalFormField[]>>,
    fieldName: string,
    newValue?: PortalFormFieldValue
  ): void => {
    setHasFormChanged(true);
    const newFormValues = form.map((item: PortalFormField) => {
      if (item.name === fieldName) {
        item.value = newValue;
      }
      return item;
    });
    setForm(newFormValues);
  };

  const profilePictureSection = (
    <Section
      title={i18n("account_profile_picture")}
      aria-label={i18n("account_profile_picture")}
    >
      <Avatar
        image={
          /* istanbul ignore next */
          profileImage ? { src: profileImage } : undefined
        }
        name={user?.displayName || /* istanbul ignore next */ undefined}
        aria-label={user?.displayName || /* istanbul ignore next */ undefined}
        size={96}
        icon={<Person16Filled />}
        className={profileAccountSettingsClasses.avatar}
      />
    </Section>
  );

  const contactInformationSection = (
    <Section
      title={i18n("account_contact_info")}
      aria-label={i18n("account_contact_info")}
    >
      <div
        className={mergeClasses(
          gridClasses.row,
          profileEditAccountFieldsClasses.root
        )}
        tabIndex={0}
      >
        <PortalFormFields
          form={contactInfoFormFields}
          onChange={(item, newValue) =>
            onFieldChange(
              contactInfoFormFields,
              setContactInfoFormFields,
              item.name,
              newValue
            )
          }
          forceShowErrorMessage={hasSaveBeenClicked}
          field={(item, field) => (
            <div className={item.className} key={item.name} tabIndex={0}>
              {field}
            </div>
          )}
        />
      </div>
    </Section>
  );

  const aboutYouSection = aboutYouFormFields.length ? (
    <Section
      title={i18n("account_about_you")}
      aria-label={i18n("account_about_you")}
    >
      <div
        className={mergeClasses(
          gridClasses.row,
          profileEditAccountFieldsClasses.root
        )}
        tabIndex={0}
      >
        <PortalFormFields
          form={aboutYouFormFields}
          onChange={(item, newValue) =>
            onFieldChange(
              aboutYouFormFields,
              setAboutYouFormFields,
              item.name,
              newValue
            )
          }
          forceShowErrorMessage={hasSaveBeenClicked}
          field={(item, field) => (
            <div className={item.className} key={item.name} tabIndex={0}>
              {field}
            </div>
          )}
        />
      </div>
    </Section>
  ) : null;

  const onlinePresenceSection = onlinePresenceFormFields.length ? (
    <Section
      title={i18n("account_online_presence")}
      aria-label={i18n("account_online_presence")}
    >
      <div
        className={mergeClasses(
          gridClasses.row,
          profileEditAccountFieldsClasses.root
        )}
        tabIndex={0}
      >
        <PortalFormFields
          form={onlinePresenceFormFields}
          onChange={(item, newValue) =>
            onFieldChange(
              onlinePresenceFormFields,
              setOnlinePresenceFormFields,
              item.name,
              newValue
            )
          }
          forceShowErrorMessage={hasSaveBeenClicked}
          field={(item, field) => (
            <div className={item.className} key={item.name} tabIndex={0}>
              {field}
            </div>
          )}
        />
      </div>
    </Section>
  ) : null;

  return (
    <div
      className={mergeClasses(
        "profile-account",
        flexClasses.root,
        flexClasses.fill,
        flexClasses.column
      )}
    >
      {mobileView && (
        <ProfilePageHeaderMobile
          header={i18n("account_header")}
          backPath="/profile"
        />
      )}
      <div
        className={mergeClasses(
          "profile-page-container",
          gridClasses.container,
          profilePageClasses.container
        )}
      >
        <div className={gridClasses.row}>
          <div
            className={mergeClasses(
              gridClasses.col,
              gridClasses.xs12,
              gridClasses.lg11,
              gridClasses.xl10
            )}
          >
            <div
              className={mergeClasses(
                flexClasses.root,
                flexClasses.column,
                flexClasses.columnGapLarge
              )}
            >
              <Text
                className={mergeClasses(
                  gridClasses.hiddenMdDown,
                  portalTextClasses.large
                )}
                as="h1"
                weight="bold"
              >
                {i18n("account_header")}
              </Text>
              {profilePictureSection}
              {contactInformationSection}
              {aboutYouSection}
              {onlinePresenceSection}
              <div>
                <Button
                  appearance="primary"
                  disabled={
                    !enableEditAccount ||
                    (hasFormChanged ? !!errorCount : false)
                  }
                  onClick={onClickSave}
                  aria-label={i18n("button_save")}
                >
                  {i18n("button_save")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section: React.FunctionComponent<{ title: string }> = (props) => {
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapSmall
      )}
    >
      <Text as="h2" className={portalTextClasses.large} weight="semibold">
        {props.title}
      </Text>
      {props.children}
    </div>
  );
};
