import React from "react";
import { useSelector } from "react-redux";
import { EventUser } from "../core/slices/userTypes.interface";
import { speakerProfileStyles } from "../styles/SpeakerProfile";
import { useTranslation } from "react-i18next";
import { IEventSession } from "../core/slices/session.interface";
import {
  mergeClasses,
  TextV9 as Text,
  DialogContentV9 as DialogContent,
  DialogTitleV9 as DialogTitle,
  DialogV9 as Dialog,
  DialogSurfaceV9 as DialogSurface,
  DialogBodyV9 as DialogBody,
  DialogTriggerV9 as DialogTrigger,
  ButtonV9 as Button,
  Dismiss32Regular,
} from "../shared";
import { sessionCardStyles } from "../styles/SessionCard";
import { useBreakpoint } from "../utilities/hooks/useBreakpoints";
import { Breakpoint } from "../styles/Grid";
import { eventSpeakerSessions } from "../core/slices/sessionSlice";
import { flexAlignStyles, flexStyles } from "../styles/FlexStyles";
import { portalTextStyles } from "../styles/PortalText";
import { speakerCardStyles } from "../styles/SpeakerCard";
import { SpeakerCardInfo, SpeakerImage } from "./SpeakerCard";
import { portalResponsiveDialogStyles } from "../styles/PortalResponsiveDialog";
import { dialogStyles } from "../styles/Dialog";
import { SessionInfoAndDescription, SessionTimings } from "./SessionCard";
import { isMultiSessionEventSelector } from "../core/slices/sessionSlice";
export enum SpeakerProfileDescriptionPosition {
  underImage,
  underTitle,
}

export interface ISpeakerProfileProps {
  speaker: EventUser;
}

export const SpeakerProfile: React.FunctionComponent<ISpeakerProfileProps> = ({
  speaker,
}) => {
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const speakerProfileClasses = speakerProfileStyles();
  const speakerCardClasses = speakerCardStyles();
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);
  const isMultiSessionEvent = useSelector(isMultiSessionEventSelector);
  const descriptionAndSessions = (
    <>
      {!!speaker.userBio?.description && (
        <Text className={speakerProfileClasses.speakerDialogDescriptionText}>
          {speaker.userBio?.description}
        </Text>
      )}
      {isMultiSessionEvent && <SpeakerProfileSessions speaker={speaker} />}
    </>
  );
  return (
    <div
      className={mergeClasses(
        "speaker-profile",
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapMedium
      )}
    >
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.row,
          flexClasses.rowGap24px
        )}
      >
        <SpeakerImage
          speaker={speaker}
          size={isMobileView ? 72 : 128}
          avatarClassName={speakerCardClasses.textAvatar}
        />
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            flexClasses.columnGap16px,
            flexClasses.fill
          )}
        >
          <SpeakerCardInfo
            speaker={speaker}
            speakerDisplayNameStyle={mergeClasses(
              portalTextClasses.lineHeight24px,
              speakerCardClasses.speakerNameDialog
            )}
            speakerBioStyleName={speakerCardClasses.userBioDialog}
          />
          {!isMobileView && descriptionAndSessions}
        </div>
      </div>
      {isMobileView && descriptionAndSessions}
    </div>
  );
};

export const SpeakerProfileSessions: React.FunctionComponent<{
  speaker: EventUser;
}> = ({ speaker }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const sessions: IEventSession[] = useSelector(
    eventSpeakerSessions(speaker.id)
  );

  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapSmaller,
        flexClasses.fill,
        flexAlignClasses.justifyContentEnd
      )}
    >
      {sessions.map((session) => (
        <SpeakerSession key={session.id} session={session} />
      ))}
    </div>
  );
};

const SpeakerSession: React.FunctionComponent<{ session: IEventSession }> = ({
  session,
}) => {
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const speakerProfileClasses = speakerProfileStyles();
  const sessionCardClasses = sessionCardStyles();
  return (
    <div
      data-testid="sessionCardSpeaker"
      className={mergeClasses(
        "session-card",
        flexClasses.root,
        flexClasses.column,
        sessionCardClasses.root,
        speakerProfileClasses.session
      )}
    >
      <div
        className={mergeClasses(
          "session-card-body",
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGap4px
        )}
      >
        <SessionTimings
          session={session}
          dateText={{
            className: mergeClasses(
              portalTextClasses.small,
              portalTextClasses.smallLineHeight
            ),
          }}
          dayText={{
            className: mergeClasses(
              portalTextClasses.smaller,
              portalTextClasses.lineHeight12px
            ),
          }}
          isSpeakerSessionCard={true}
        />
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            flexClasses.columnGap16px,
            flexClasses.fill
          )}
        >
          <SessionInfoAndDescription
            session={session}
            isSpeakerSessionCard={true}
            sessionTitleClassName={speakerProfileClasses.speakerSessionTitle}
          />
        </div>
      </div>
    </div>
  );
};

export const SpeakerProfileDialog: React.FunctionComponent<{
  onDismiss: () => void;
  speaker: EventUser | null;
}> = (props) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const speakerProfileClasses = speakerProfileStyles();
  const dialogClasses = dialogStyles();
  const portalResponsiveDialogClasses = portalResponsiveDialogStyles();
  const { onDismiss, speaker } = props;
  const { t: i18n } = useTranslation();
  const open = !!speaker;

  const SpeakerProfileText: React.FunctionComponent<{}> = () => {
    return (
      <Text
        as="h1"
        className={mergeClasses(
          speakerProfileClasses.speakerDialogTitle,
          portalTextClasses.lineHeight24px,
          portalTextClasses.size18px
        )}
      >
        {i18n("speaker_profile_header")}
      </Text>
    );
  };

  const CloseSpeakerDialog: React.FunctionComponent<{}> = () => {
    return (
      <DialogTrigger action="close">
        <Button
          id="closeSpeakerDialog"
          aria-label={i18n("close_popup_modal")}
          onClick={onDismiss}
          icon={<Dismiss32Regular />}
          appearance="transparent"
          title="Close"
          data-testid="closeButton"
        />
      </DialogTrigger>
    );
  };

  return (
    speaker && (
      <Dialog open={open} onOpenChange={onDismiss}>
        <DialogSurface
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            dialogClasses.dialogRadius,
            portalResponsiveDialogClasses.dialogSurface,
            speakerProfileClasses.dialogSurface
          )}
        >
          <DialogBody
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              flexClasses.columnGap23px,
              portalResponsiveDialogClasses.dialogBody
            )}
            aria-labelledby="speakerDialogTitle"
            aria-describedby="speakerDialogBody"
            data-testid="speakerDialog"
          >
            <DialogTitle
              className={mergeClasses(
                flexClasses.root,
                flexClasses.row,
                flexAlignClasses.alignItemCenter,
                flexAlignClasses.justifyContentSpaceBetween,
                speakerProfileClasses.dialogTitle
              )}
            >
              <SpeakerProfileText />
              <CloseSpeakerDialog />
            </DialogTitle>
            <DialogContent>
              <div
                id="speakerDialogContent"
                className={mergeClasses(
                  flexClasses.root,
                  flexClasses.row,
                  flexClasses.rowGap24px
                )}
              >
                <SpeakerProfile speaker={speaker} />
              </div>
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    )
  );
};
