import React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInputIsInvalid } from "./PortalFormValidatorContext";
import { IPortalFormValidatorFn } from "./PortalFormValidators";
import { CheckboxV9 as Checkbox, Field, LabelV9 as Label } from "..";
import { formFieldsStyles } from "../../styles/PortalFormFields";
import { validationMsg } from "./PortalFormFields";

export interface IPortalFormCheckboxInputField {
  labelTranslationKey: string;
  value?: IPortalFormCheckboxInputValue;
  validatorFns?: IPortalFormValidatorFn<boolean>[];
}

export interface IPortalFormCheckboxInputProps
  extends IPortalFormCheckboxInputField {
  onChange?: (
    event?: React.SyntheticEvent<HTMLElement>,
    newValue?: IPortalFormCheckboxInputValue
  ) => void;
  forceShowErrorMessage?: boolean;
}

export type IPortalFormCheckboxInputValue = boolean;

export const PortalFormCheckboxInput: React.FunctionComponent<
  IPortalFormCheckboxInputProps
> = (props) => {
  const {
    labelTranslationKey,
    value,
    onChange,
    validatorFns = [],
    forceShowErrorMessage,
  } = props;
  const formFieldsClasses = formFieldsStyles();
  const { t: i18n } = useTranslation();

  const validators = validatorFns.map((validatorFn) =>
    validatorFn(value || false)
  );
  const failedValidator = validators.findIndex(
    (validator) => validator.isValid === false
  );
  const isValid = failedValidator === -1;
  const errorMessage = !isValid
    ? i18n(validators[failedValidator].message)
    : "";

  const [, setIsAnswerInvalid] = useInputIsInvalid(false);
  useEffect(() => {
    setIsAnswerInvalid(!isValid);
  }, [setIsAnswerInvalid, isValid]);

  const [hasBeenChanged, setHasBeenChanged] = useState(false);

  const shouldShowErrorMessage = useMemo(() => {
    return (forceShowErrorMessage || hasBeenChanged) && !!errorMessage;
  }, [forceShowErrorMessage, hasBeenChanged, errorMessage]);

  const onCheckboxChange = useCallback(
    (event, data) => {
      setHasBeenChanged(true);
      if (onChange) {
        onChange(event, !!data?.checked);
      }
    },
    [setHasBeenChanged, onChange]
  );

  return (
    <Field
      validationState={shouldShowErrorMessage ? "error" : undefined}
      validationMessage={
        shouldShowErrorMessage ? validationMsg(errorMessage) : undefined
      }
      validationMessageIcon={null}
    >
      <Checkbox
        label={
          <Label className={formFieldsClasses.itemLabel}>
            {i18n(labelTranslationKey)}
          </Label>
        }
        aria-label={`${labelTranslationKey}`}
        onChange={onCheckboxChange}
        checked={value}
      />
    </Field>
  );
};
