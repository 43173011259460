import React from "react";
import ReactDOM from "react-dom";
// Disabling telemetry before logging start needs to be in the import and the import needs be before other imports,
// as other imports may call the logger instance (Eg. store).
import { disableTelemetryForAutomatedTestingTenants } from "./utilities/disableTelemetryForAutomatedTestingTenants";
import { Logger } from "./common/logger/Logger";
import { ILogger, LoggerLevels } from "./common/logger/interface";
import { ShortcutService } from "./common/shortcut-service/ShortcutService";
import "./styles/index.scss";
import App from "./views/App";
import { store } from "./core/store/store";
import "./core/localization/i18n";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createPortalEcsClient } from "./core/ecs/ecsClient";
import {
  captureWebVitals,
  reportWebVitalsTelemetry,
} from "./core/perf/webVitals";
import "./views/trusted-security-policies";

const isTelemetryDisableForAutomatedTestingTenants =
  disableTelemetryForAutomatedTestingTenants;

// load env
if (process.env.NODE_ENV !== "production") {
  /* eslint-disable @typescript-eslint/no-var-requires */
  require("dotenv").config();
  /* eslint-enable @typescript-eslint/no-var-requires */
}

function logBootInfo(logger: ILogger): void {
  logger.logTrace(
    LoggerLevels.info,
    `Current UTC Time: ${new Date(Date.now()).toUTCString()}`
  );
  logger.logTrace(
    LoggerLevels.info,
    `Local time + zone: ${new Date(Date.now()).toTimeString()}`
  );
  logger.logTrace(
    LoggerLevels.info,
    `Original URL: ${window.location.toString()}`
  );
  logger.logTrace(
    LoggerLevels.info,
    `navigator.userAgent: ${navigator.userAgent}`
  );
  logger.logTrace(
    LoggerLevels.info,
    `navigator.appVersion: ${navigator.appVersion}`
  );
  if (isTelemetryDisableForAutomatedTestingTenants) {
    logger.logTrace(
      LoggerLevels.info,
      "Telemetry disabled for automated testing tenant."
    );
  }
}

const logger = Logger.getInstance();
logBootInfo(logger);

const shortcutServiceConfig = {
  logger,
  addEventListener: window.addEventListener.bind(this),
  removeEventListener: window.removeEventListener,
};
const shortcutService = new ShortcutService(shortcutServiceConfig);
logger.initShortcut(shortcutService);
logger.setStore(store);

const ecsClient = createPortalEcsClient(logger);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App
          logger={logger}
          shortcutService={shortcutService}
          ecsClient={ecsClient}
        />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

captureWebVitals(reportWebVitalsTelemetry);
