export const routes = {
  login: "/login",
  session: "session",
  attendee: "attendee",
  registration: "registration",
  webinarSeries: "webinar-series",
  authPopup: "auth", // MSAL redirect URL for popup login, and acquire token popup/silent.
  authRedirect: "auth-redirect", // MSAL redirect URL for redirect login.
  sponsors: "sponsors",
  profile: "/profile",
  event: "/event",
  authError: "/auth-error",
  fetchError: "/fetch-error",
  notFound: "/not-found",
  eventCanceled: "/event-canceled",
  userDeleted: "/user-deleted",
  tooManyRequests: "/too-many-requests",
};

export const sessionsPage = (eventId?: string, sessionId?: string): string => {
  return `/event/${eventId}/sessions/${sessionId}`;
};

// TODO: Add locale, attendeeId, and presenterKey.
export enum SearchParamKey {
  initiateWorkLogin = "initiateWorkLogin", // Used with MCAS, and is used in the MCAS controller
}

export const microsoftLinks = {
  microsoftTermsOfUse: "https://go.microsoft.com/fwlink/?linkid=2210903",
  microsoftPrivacyPolicy:
    "https://privacy.microsoft.com/en-us/privacystatement",
  createWebinar:
    "https://teams.microsoft.com/l/virtualevent/new?eventType=Webinar&source=RegistrationPortal",
  createWebinarGCCH:
    "https://gov.teams.microsoft.us/l/virtualevent/new?eventType=Webinar&source=RegistrationPortal",
  createWebinarDOD:
    "https://dod.teams.microsoft.us/l/virtualevent/new?eventType=Webinar&source=RegistrationPortal",
  learnMoreWebinar:
    "https://support.microsoft.com/en-us/office/schedule-a-webinar-in-microsoft-teams-0719a9bd-07a0-47fd-8415-6c576860f36a",
};

export const socialUrls = {
  facebook: "https://www.facebook.com/",
  linkedIn: "https://www.linkedin.com/",
  twitter: "https://twitter.com/",
};
export const cacheKeys = {
  intAuthSkypeToken: "int-skype-token",
  intVisitorSkypeToken: "int-visitor-skype-token",
  authSkypeToken: "skype-token",
  visitorSkypeToken: "visitor-skype-token",
  gccAuthSkypeToken: "gcc-skype-token",
  gccVisitorSkypeToken: "gcc-visitor-skype-token",
  gcchAuthSkypeToken: "gcch-skype-token",
  gcchVisitorSkypeToken: "gcch-visitor-skype-token",
  dodAuthSkypeToken: "dod-skype-token",
  dodVisitorSkypeToken: "dod-visitor-skype-token",
  badgerToken: "badger-token",
};

export const navItemIds = {
  speakersTab: "speakers_tab",
  informationTab: "information_tab",
  sessionsTab: "sessions_tab",
  myAgendaTab: "my_agenda_tab",
  sponsorsTab: "sponsors_tab",
};

export const msalErrorCodes = {
  popupWindowError: "popup_window_error",
  userCancelled: "user_cancelled",
  silentSsoError: "silent_sso_error",
};

export const defaultImageUrl =
  "https://statics.teams.microsoft.com/evergreen-assets/virtual-events/share-to-social/pre-canned-default.png";
