import React from "react";
import {
  TextV9 as Text,
  DividerV9 as Divider,
  mergeClasses,
  ArrowLeft20Regular,
} from "../../shared";
import { HelpMenuButton } from "./../HelpMenuButton";
import { useTranslation } from "react-i18next";
import { gridStyles } from "../../styles/Grid";
import {
  flexAlignStyles,
  flexItemStyles,
  flexStyles,
} from "../../styles/FlexStyles";
import { profilePageHeaderMobileStyles } from "../../styles/ProfilePage";
import { Link } from "react-router-dom";
import { portalTextStyles } from "../../styles/PortalText";

export const ProfilePageHeaderMobile: React.FunctionComponent<{
  header: string;
  backPath?: string;
}> = ({ header, backPath }) => {
  const flexItemClasses = flexItemStyles();
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const profilePageHeaderMobileClasses = profilePageHeaderMobileStyles();
  const portalTextClasses = portalTextStyles();
  const gridClasses = gridStyles();
  const { t: i18n } = useTranslation();

  return (
    <div className={mergeClasses("profile-page-header", gridClasses.container)}>
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.fill,
          flexAlignClasses.alignItemCenter,
          profilePageHeaderMobileClasses.root
        )}
      >
        {backPath && (
          <Link
            to={backPath}
            aria-label={i18n("back")}
            className={profilePageHeaderMobileClasses.backPath}
          >
            <ArrowLeft20Regular />
          </Link>
        )}
        <Text
          as="h1"
          className={mergeClasses(
            flexItemClasses.grow,
            portalTextClasses.medium,
            profilePageHeaderMobileClasses.header
          )}
          align="center"
          weight="bold"
        >
          {header}
        </Text>
        <HelpMenuButton />
      </div>
      <Divider />
    </div>
  );
};
