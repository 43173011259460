import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

export enum PortalAxiosErrorType {
  SKYPE_TOKEN = "skypeToken",
  CMD_SERVICES = "cmdServices",
}

export class PortalAxiosError<T, D> extends AxiosError<T, D> {
  constructor(
    readonly type: PortalAxiosErrorType,
    message?: string,
    code?: string,
    config?: AxiosRequestConfig<D>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    request?: any,
    response?: AxiosResponse<T, D>
  ) {
    super(message, code, config, request, response);
  }

  public static fromAxiosError<T, D>(
    type: PortalAxiosErrorType,
    axiosError: AxiosError<T, D>
  ): PortalAxiosError<T, D> {
    return new PortalAxiosError(
      type,
      axiosError.message,
      axiosError.code,
      axiosError.config,
      axiosError.request,
      axiosError.response
    );
  }
}
