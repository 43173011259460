import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { imageSelector } from "../core/slices/imageSlice";
import { EventUser } from "../core/slices/userTypes.interface";
import {
  mergeClasses,
  AvatarV9 as Avatar,
  TextV9 as Text,
  Person16Regular,
  ButtonV9,
  PortalAnchorV9 as PortalAnchor,
  PortalTwitterIcon,
  PortalPersonalWebsiteIcon,
  PortalLinkedInIcon,
} from "../shared";
import { flexAlignStyles, flexStyles } from "../styles/FlexStyles";
import { portalTextStyles } from "../styles/PortalText";
import { AvatarSize } from "@fluentui/react-components";
import { useBreakpoint } from "../utilities/hooks/useBreakpoints";
import { Breakpoint } from "../styles/Grid";
import {
  speakerCardGridStyles,
  speakerCardStyles,
} from "../styles/SpeakerCard";
import { SpeakerProfileDialog, SpeakerProfileSessions } from "./SpeakerProfile";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../utilities/hooks/useWindowSize";
import { buttonStyles } from "../styles/ButtonStyles";
import { isMultiSessionEventSelector } from "../core/slices/sessionSlice";
import { getWindow } from "../shared";
import { getValidUserURL, isValidUserURL } from "../utilities/common/utils";

export enum SpeakerProfileDescriptionPosition {
  underImage,
  underTitle,
}

export interface ISpeakerCardWebinarProps {
  speaker: EventUser;
  isExpanded: boolean;
  onExpand: (speakerId: string) => void;
}

export const SpeakerCard: React.FunctionComponent<
  ISpeakerCardWebinarProps & {
    isExpanded: boolean;
    onExpand: (speakerId: string) => void;
  }
> = ({ speaker, isExpanded, onExpand }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const speakerCardClasses = speakerCardStyles();
  const isMultiSessionEvent = useSelector(isMultiSessionEventSelector);
  const profileImage = useSelector(imageSelector(speaker.profileImage));
  const speakerImage = profileImage ? profileImage : undefined;

  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);

  const handleExpand = () => {
    onExpand(speaker.id);
  };

  const speakerDescriptionAndSessions = (
    <>
      <ShowMoreText
        speaker={speaker}
        isExpanded={isExpanded}
        onExpand={handleExpand}
      ></ShowMoreText>
      {isMultiSessionEvent && <SpeakerProfileSessions speaker={speaker} />}
    </>
  );

  return (
    <div
      className={mergeClasses(
        "speaker-profile",
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGapMedium
      )}
    >
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.fill,
          flexClasses.rowGap24px,
          flexClasses.row,
          flexAlignClasses.alignItemCenter
        )}
      >
        <Avatar
          aria-hidden={true}
          image={
            speakerImage
              ? { src: speakerImage }
              : /* istanbul ignore next */ undefined
          }
          name={speaker.displayName || /* istanbul ignore next */ undefined}
          className={speakerCardClasses.avatar}
        />
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            flexClasses.columnGapSmaller,
            flexClasses.fill
          )}
        >
          <SpeakerCardInfo
            speaker={speaker}
            speakerDisplayNameStyle={speakerCardClasses.speakerName}
            speakerBioStyleName={speakerCardClasses.userBio}
          />
          {!isMobileView && speakerDescriptionAndSessions}
        </div>
      </div>
      {isMobileView && speakerDescriptionAndSessions}
    </div>
  );
};

export const ShowMoreText: React.FunctionComponent<
  ISpeakerCardWebinarProps & {
    isExpanded: boolean;
    onExpand: (speakerId: string) => void;
  }
> = ({ speaker, isExpanded, onExpand }) => {
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const buttonClasses = buttonStyles();
  const speakerCardClasses = speakerCardStyles();
  const speakerDescriptionText = speaker.userBio?.description;
  const [showButton, setShowButton] = React.useState(true);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { t: i18n } = useTranslation();
  const { width } = useWindowSize();
  const window = getWindow();

  const handleClick = () => {
    if (showButton) {
      onExpand(speaker.id);
    }
  };

  // Checks button availability based on screen resize
  useEffect(() => {
    const hasClamping = (element: HTMLDivElement) => {
      const { scrollHeight } = element;
      const computedStyle = window?.getComputedStyle(element);
      const lineHeight = parseInt(computedStyle!.lineHeight);
      const numberOfLines = Math.floor(scrollHeight / lineHeight);

      return numberOfLines > 3;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        setShowButton(hasClamping(containerRef.current));
      }
    };

    checkButtonAvailability();
  }, [containerRef, width, window]);

  if (!speakerDescriptionText) {
    return null;
  }

  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.columnGapSmaller,
        flexClasses.column
      )}
    >
      <div
        ref={containerRef}
        className={!isExpanded ? speakerCardClasses.descriptionBox : ""}
      >
        <Text
          className={mergeClasses(
            portalTextClasses.medium2,
            speakerCardClasses.speakerCardDescription
          )}
        >
          {speakerDescriptionText}
        </Text>
      </div>
      {showButton && (
        <ButtonV9
          appearance="transparent"
          data-testid={!isExpanded ? "ShowMoreButton" : "ShowLessButton"}
          onClick={handleClick}
          className={buttonClasses.showMoreButton}
        >
          {!isExpanded ? i18n("show_more") : i18n("show_less")}
        </ButtonV9>
      )}
    </div>
  );
};

export const SpeakerImage: React.FunctionComponent<{
  speaker: EventUser;
  size: AvatarSize;
  avatarClassName?: string;
}> = ({ speaker, size, avatarClassName }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const profileImage = useSelector(imageSelector(speaker.profileImage));
  return (
    <div
      className={mergeClasses(
        "speaker-card-avatar-wrapper",
        flexClasses.root,
        flexAlignClasses.justifyContentCenter
      )}
      data-testid="speakerImage"
    >
      <Avatar
        image={
          /* istanbul ignore next */
          profileImage ? { src: profileImage } : undefined
        }
        name={speaker.displayName || undefined}
        size={size}
        className={avatarClassName}
        icon={<Person16Regular data-testid="speakerIcon" />}
      />
    </div>
  );
};

export const SpeakerCardInfo: React.FunctionComponent<{
  speaker: EventUser;
  speakerDisplayNameStyle: string;
  speakerBioStyleName: string;
}> = ({ speaker, speakerDisplayNameStyle, speakerBioStyleName }) => {
  const flexClasses = flexStyles();
  const speakerCardClasses = speakerCardStyles();
  const [linkedInProfile, setLinkedInProfile] = React.useState<
    string | undefined
  >(undefined);
  const [twitterProfile, setTwitterProfile] = React.useState<
    string | undefined
  >(undefined);
  const [personalWebsites, setPersonalWebsites] = React.useState<
    string[] | undefined
  >(undefined);
  const { t: i18n } = useTranslation();

  useEffect(() => {
    setLinkedInProfile(
      getValidUserURL(speaker.userBio?.socialMediaLinks?.linkedIn)
    );
    setTwitterProfile(
      getValidUserURL(speaker.userBio?.socialMediaLinks?.twitter)
    );
    setPersonalWebsites(
      speaker.userBio?.websites?.filter((website) => isValidUserURL(website))
    );
  }, [speaker]);
  const speakerName = (
    <Text weight="semibold" className={speakerDisplayNameStyle}>
      {speaker.displayName}
    </Text>
  );

  const socialIconsContainer = (
    <Text className={speakerCardClasses.socialIconsContainer}>
      {linkedInProfile && (
        <PortalAnchor
          href={linkedInProfile}
          target="_blank"
          aria-label={`${i18n("linkedin_label")} ${i18n("link_new_tab")}`}
        >
          {<PortalLinkedInIcon className={speakerCardClasses.socialIcon} />}
        </PortalAnchor>
      )}
      {twitterProfile && (
        <PortalAnchor
          href={twitterProfile}
          target="_blank"
          aria-label={`${i18n("twitter_label")} ${i18n("link_new_tab")}`}
        >
          {<PortalTwitterIcon className={speakerCardClasses.socialIcon} />}
        </PortalAnchor>
      )}
      {personalWebsites && personalWebsites.length > 0 && (
        <PortalAnchor
          href={personalWebsites[0]}
          target="_blank"
          aria-label={`${i18n("speaker_website")} ${i18n("link_new_tab")}`}
        >
          {
            <PortalPersonalWebsiteIcon
              className={speakerCardClasses.socialIcon}
            />
          }
        </PortalAnchor>
      )}
    </Text>
  );

  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGap4px
      )}
    >
      <Text className={speakerCardClasses.infoHeader}>
        {speakerName}
        {socialIconsContainer}
      </Text>

      {!!(speaker.userBio?.jobTitle || speaker.userBio?.company) && (
        <div className={mergeClasses(flexClasses.root, flexClasses.column)}>
          <Text className={speakerBioStyleName}>
            {speaker.userBio.jobTitle}
          </Text>
          <Text className={speakerBioStyleName}>{speaker.userBio.company}</Text>
        </div>
      )}
    </div>
  );
};

// Speaker Card for Webinar in Grid View
export const SpeakerCardGrid: React.FunctionComponent<{
  speaker: EventUser;
}> = ({ speaker }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const portalTextClasses = portalTextStyles();
  const speakerCardGridClasses = speakerCardGridStyles();
  const speakerCardClasses = speakerCardStyles();
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);
  const [isDialogNeeded, setDialogNeeded] = React.useState(false);
  const handleClick = () => {
    if (!isDialogNeeded) {
      setDialogNeeded(true);
    }
  };

  const handleCloseDialog = () => {
    setDialogNeeded(false);
  };

  return (
    <div
      className={mergeClasses(
        speakerCardGridClasses.gridBox,
        flexClasses.column,
        flexClasses.columnGap16px
      )}
      onClick={handleClick}
      data-testid="speakerCardGrid"
    >
      <SpeakerImage
        speaker={speaker}
        size={isMobileView ? 72 : 128}
        avatarClassName={speakerCardClasses.textAvatar}
      />
      <div
        className={mergeClasses(
          flexClasses.row,
          flexClasses.rowGap2px,
          flexAlignClasses.alignItemCenter
        )}
      >
        <Text
          className={mergeClasses(
            flexClasses.block,
            portalTextClasses.size18px,
            speakerCardGridClasses.speakerCellTextStyles,
            portalTextClasses.lineHeight26px
          )}
          truncate={true}
          wrap={false}
          align="center"
        >
          {speaker.displayName}
        </Text>
        <Text
          className={mergeClasses(
            flexClasses.block,
            portalTextClasses.medium2,
            speakerCardClasses.infoText
          )}
          align="center"
        >
          {speaker.userBio?.jobTitle}
        </Text>
      </div>
      {isDialogNeeded && (
        <SpeakerProfileDialog speaker={speaker} onDismiss={handleCloseDialog} />
      )}
    </div>
  );
};
