import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, Switch, useParams } from "react-router";
import { routes } from "../common/constants";
import { imageSelector } from "../core/slices/imageSlice";
import {
  authenticatedSelector,
  isUserLoadingSelector,
  userSelector,
} from "../core/slices/userSlice";
import {
  AvatarV9 as Avatar,
  DividerV9 as Divider,
  Navigation20Regular,
  SkeletonV9 as Skeleton,
  useBoolean,
  mergeClasses,
  ButtonV9 as Button,
  DialogV9 as Dialog,
  DialogSurfaceV9 as DialogSurface,
  DialogBodyV9 as DialogBody,
  DialogTitleV9 as DialogTitle,
  DialogContentV9 as DialogContent,
  Dismiss20Regular,
  TextV9 as Text,
  ChevronRight16Regular,
  Person16Regular,
  Link,
  SkeletonItem,
} from "../shared";
import { topNavBarMobileStyles } from "../styles/TopNavBarMobile";
import { EventNav, EventNavDirection } from "./EventNav";
import { HelpMenuButton } from "./HelpMenuButton";
import {
  flexAlignStyles,
  flexItemStyles,
  flexStyles,
} from "../styles/FlexStyles";
import { iconStyles } from "../styles/IconStyles";
import { portalTextStyles } from "../styles/PortalText";
import { avatarStyles } from "../styles/Avatar";
import { skeletonItemStyles } from "../styles/SkeletonStyles";

export const TopNavBarMobile: React.FunctionComponent<{
  enableManageAccount: boolean;
  onClickSignIn: () => void;
  onClickSignOut: () => void;
  onClickManageAccount: () => void;
}> = ({
  enableManageAccount,
  onClickSignIn,
  onClickSignOut,
  onClickManageAccount,
}) => {
  return (
    <Switch>
      <Route path={["/profile", "/profile/:profilePage"]} />
      <TopNavBarMobileInternal
        enableManageAccount={enableManageAccount}
        onClickSignIn={onClickSignIn}
        onClickSignOut={onClickSignOut}
        onClickManageAccount={onClickManageAccount}
      />
    </Switch>
  );
};

const TopNavBarMobileInternal: React.FunctionComponent<{
  enableManageAccount: boolean;
  onClickSignIn: () => void;
  onClickSignOut: () => void;
  onClickManageAccount: () => void;
}> = ({
  enableManageAccount,
  onClickSignIn,
  onClickSignOut,
  onClickManageAccount,
}) => {
  const flexItemClasses = flexItemStyles();
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const topNavBarMobileClasses = topNavBarMobileStyles();
  const isAuthenticated = useSelector(authenticatedSelector);
  const { t: i18n } = useTranslation();

  const [isNavPanelOpen, { setTrue: openNavPanel, setFalse: dismissNavPanel }] =
    useBoolean(false);

  const signInButton = (
    <Button
      id="signIn"
      onClick={() => {
        dismissNavPanel();
        onClickSignIn();
      }}
    >
      {i18n("sign_in")}
    </Button>
  );

  const signOutButton = (
    <Button
      id="signOut"
      onClick={() => {
        dismissNavPanel();
        onClickSignOut();
      }}
    >
      {i18n("sign_out")}
    </Button>
  );

  const navMobileContent = (
    <Switch>
      <Route path={`${routes.event}/:id`}>
        <EventNavMobile onNav={dismissNavPanel} />
      </Route>
    </Switch>
  );

  const userSection = !isAuthenticated ? (
    signInButton
  ) : (
    <>
      <AccountMobile
        enableManageAccount={enableManageAccount}
        onClickManageAccount={onClickManageAccount}
      />
      <Divider className={topNavBarMobileClasses.divider} />
    </>
  );

  return (
    <>
      <div
        className={mergeClasses(
          "top-nav-bar-mobile",
          topNavBarMobileClasses.root,
          flexClasses.root,
          flexAlignClasses.alignItemCenter,
          flexAlignClasses.justifyContentSpaceBetween,
          flexClasses.rowGapSmall
        )}
      >
        <Button
          id="topNavBarMobileMenuButton"
          data-testid="topNavBarMobileMenuButton"
          aria-label={i18n("top_Nav_Mobile_Bar")}
          appearance="transparent"
          icon={<Navigation20Regular />}
          onClick={openNavPanel}
        />
        <div className={flexItemClasses.rowPush}>
          <HelpMenuButton />
        </div>
        <Dialog open={isNavPanelOpen} onOpenChange={dismissNavPanel}>
          <DialogSurface
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              flexAlignClasses.alignItemStart,
              flexAlignClasses.justifyContentStart,
              topNavBarMobileClasses.userMenuDialogSurface
            )}
          >
            <DialogBody
              className={mergeClasses(
                flexClasses.root,
                flexClasses.column,
                flexClasses.fill
              )}
            >
              <DialogTitle>
                <Button
                  data-testid="topNavBarMobileMenuCloseButton"
                  className={topNavBarMobileClasses.userMenuCloseButton}
                  appearance="transparent"
                  icon={<Dismiss20Regular />}
                  onClick={dismissNavPanel}
                />
              </DialogTitle>
              <DialogContent>
                <div
                  className={mergeClasses(
                    flexClasses.root,
                    flexClasses.column,
                    flexClasses.columnGapSmall
                  )}
                >
                  {userSection}
                  {navMobileContent}
                  {isAuthenticated && signOutButton}
                </div>
              </DialogContent>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      </div>
      <div
        className={mergeClasses(
          "top-nav-bar-mobile-spacer",
          topNavBarMobileClasses.spacer
        )}
      />
    </>
  );
};

const AccountMobile: React.FunctionComponent<{
  enableManageAccount: boolean;
  onClickManageAccount: () => void;
}> = ({ enableManageAccount, onClickManageAccount }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const avatarClasses = avatarStyles();
  const topNavBarMobileClasses = topNavBarMobileStyles();
  const portalTextClasses = portalTextStyles();
  const skeletonItemClasses = skeletonItemStyles();
  const iconClasses = iconStyles();
  const user = useSelector(userSelector);
  const isUserLoading = useSelector(isUserLoadingSelector);
  const profileImage = useSelector(imageSelector(user && user.profileImage));
  const { t: i18n } = useTranslation();

  const userProfile = user ? (
    <div
      id="userProfile"
      className={mergeClasses(
        flexClasses.root,
        flexClasses.row,
        flexClasses.fill,
        flexClasses.rowGapMedium
      )}
    >
      <Avatar
        image={
          profileImage
            ? { src: profileImage }
            : /* istanbul ignore next */ undefined
        }
        name={user?.displayName || undefined}
        icon={<Person16Regular data-testid="userProfileIcon" />}
        className={avatarClasses.root}
      />
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexAlignClasses.justifyContentCenter,
          topNavBarMobileClasses.userInfoWrapper
        )}
      >
        <div
          className={mergeClasses(
            flexClasses.root,
            flexClasses.row,
            flexClasses.rowGapSmaller,
            flexAlignClasses.alignItemCenter
          )}
        >
          <Text weight="bold" color="default">
            {user.displayName}
          </Text>
          {enableManageAccount && (
            <ChevronRight16Regular
              className={topNavBarMobileClasses.userChevronIcon}
            />
          )}
        </div>
        <Text
          className={mergeClasses(
            portalTextClasses.small,
            topNavBarMobileClasses.userEmailText
          )}
          truncate
          wrap={false}
        >
          {user.email}
        </Text>
      </div>
    </div>
  ) : null;

  const loadingUserProfile = (
    <div
      id="userProfile"
      className={mergeClasses(
        flexClasses.root,
        flexClasses.row,
        flexClasses.rowGapMedium
      )}
    >
      <Avatar icon={<Person16Regular data-testid="userLoadingAvatarIcon" />} />
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexAlignClasses.justifyContentCenter,
          topNavBarMobileClasses.userInfoLoadingWrapper
        )}
      >
        <Skeleton animation="pulse">
          <div
            className={mergeClasses(
              flexClasses.root,
              flexClasses.row,
              flexClasses.rowGapSmall,
              flexAlignClasses.alignItemCenter
            )}
          >
            <SkeletonItem
              data-testid="userLoadingText"
              className={mergeClasses(
                skeletonItemClasses.root,
                skeletonItemClasses.textSize18,
                topNavBarMobileClasses.loadingUserDisplayName
              )}
            />
            {enableManageAccount && (
              <ChevronRight16Regular
                className={mergeClasses(
                  iconClasses.small,
                  topNavBarMobileClasses.userChevronIcon
                )}
              />
            )}
          </div>
          <SkeletonItem
            className={mergeClasses(
              skeletonItemClasses.root,
              skeletonItemClasses.textSize18,
              topNavBarMobileClasses.loadingUserEmailText
            )}
          />
        </Skeleton>
      </div>
    </div>
  );

  const content = isUserLoading ? loadingUserProfile : userProfile;
  return enableManageAccount && !!content ? (
    <Link
      aria-label={`${i18n("manage_account")} ${i18n("link_new_tab")}`}
      onClick={onClickManageAccount}
      className={topNavBarMobileClasses.profileLink}
    >
      {content}
    </Link>
  ) : (
    content
  );
};

interface IEventNavMobileProps {
  onNav: () => void;
}

const EventNavMobile: React.FunctionComponent<IEventNavMobileProps> = ({
  onNav,
}) => {
  const { id } = useParams<{ id: string }>();
  return (
    <EventNav
      urlPath={`${routes.event}/`.concat(id)}
      direction={EventNavDirection.Vertical}
      onNav={onNav}
    />
  );
};
