import { AXHelper } from "../api/axHelper";
import { EventId, Guid } from "./CMDTypes";
import {
  IEvent,
  IEventRegister,
  IEventRegisterAnswers,
  IEventRegisterResponse,
  UserRegistrationStatus,
  IPageType,
  IEventPageViewCountResponse,
} from "./eventTypes.interface";
import {
  eventsRoute,
  eventRegistrationDetailsRoute,
  eventRegisterRoute,
  pageViewCountRoute,
} from "./CMDRoutes";
import { EventUser } from "./userTypes.interface";
import { IScenarioLogger } from "../../common/logger/interface";
import {
  getEventTenantId,
  isValidEventIdFormat,
} from "../../utilities/common/utils";
import { InvalidEventIdFormatError } from "./InvalidEventIdFormatError";

export const samplePromoImageID = "0-cus-d1-fa800407fc5f9c5f473ceb6f01bb9e41";
export const sampleLogoImageID = "0-cus-d17-4bb07d56721475e0ace625425695321f";

export async function fetchEvent(
  eventId: EventId,
  scenario?: IScenarioLogger
): Promise<IEvent> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  const route: string = `${eventsRoute}/${eventId}`;
  return AXHelper.getDataFromServer(route, eventTenantId, scenario);
}

export async function fetchEventSpeakers(eventId: Guid): Promise<EventUser[]> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  const route: string = `${eventsRoute}/${eventId}/speakers`;
  return AXHelper.getDataFromServer(route, eventTenantId);
}

export async function fetchAMSImage(id: string): Promise<string> {
  return AXHelper.getAMSImage(id);
}

export async function getEventRegistration(
  eventId: EventId,
  scenario?: IScenarioLogger
): Promise<IEventRegister> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  const route: string = `${eventsRoute}/${eventId}/${eventRegistrationDetailsRoute}`;
  return AXHelper.getDataFromServer(route, eventTenantId, scenario);
}

export async function getRegistrationStatusFromUserID(
  eventId: EventId,
  userObjectId: Guid,
  userUPN: string,
  userTenantID?: Guid,
  scenario?: IScenarioLogger
): Promise<IEventRegisterResponse> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  if (eventTenantId === userTenantID) {
    const routeWithObjectId: string = `${eventsRoute}/${eventId}/users/${userObjectId}/${eventRegistrationDetailsRoute}`;
    return AXHelper.getDataFromServer(
      routeWithObjectId,
      eventTenantId,
      scenario
    );
  }

  const routeWithUserUPN: string = `${eventsRoute}/${eventId}/users/${userUPN}/${eventRegistrationDetailsRoute}`;
  return AXHelper.getDataFromServer(routeWithUserUPN, eventTenantId, scenario);
}

export async function getRegistrationStatusFromRegistrationID(
  eventId: EventId,
  registrationId: Guid,
  scenario?: IScenarioLogger
): Promise<IEventRegisterResponse> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  const route: string = `${eventsRoute}/${eventId}/attendees/${registrationId}/${eventRegistrationDetailsRoute}`;
  return AXHelper.getDataFromServer(route, eventTenantId, scenario);
}

export async function eventRegister(
  eventId: EventId,
  regInfo: IEventRegisterAnswers,
  scenario?: IScenarioLogger
): Promise<IEventRegisterResponse> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  const route: string = `${eventsRoute}/${eventId}/${eventRegisterRoute}`;
  return AXHelper.postDataToServer(route, regInfo, eventTenantId, scenario);
}

export async function cancelEventRegister(
  eventId: EventId,
  registrationId: Guid,
  scenario?: IScenarioLogger
): Promise<IEventRegisterResponse> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  const route: string = `${eventsRoute}/${eventId}/attendees/${registrationId}/cancel`;
  //TODO: This should have status of the user presently (Registered, Waitlisted or Awaiting Approval) instead of UNKNOWN
  //In discusion with Quan if a new endpoint is needed for that or if an existing endpoint can
  //be updated with that info so I can use it here
  let result: IEventRegisterResponse = {
    registrationId: "",
    status: UserRegistrationStatus.UNKNOWN,
  };
  await AXHelper.postDataToServer(route, null, eventTenantId, scenario).then(
    () => {
      result = {
        registrationId: "",
        status: UserRegistrationStatus.CANCELED,
      };
    }
  );
  return result;
}

export async function pageViewCount(
  eventId: EventId,
  pageType: IPageType,
  scenario?: IScenarioLogger
): Promise<IEventPageViewCountResponse> {
  if (!isValidEventIdFormat(eventId)) {
    throw new InvalidEventIdFormatError(eventId);
  }
  const eventTenantId = getEventTenantId(eventId);
  const route: string = `${eventsRoute}/${eventId}/${pageViewCountRoute}`;
  return AXHelper.postDataToServer(route, pageType, eventTenantId, scenario);
}
