import { makeStyles, shorthands, tokens } from "../shared";

export const eventShareEventButtonStyle = makeStyles({
  webPopOverButton: {
    height: "32px",
    ":hover": {
      color: tokens.colorBrandForeground1,
    },
  },
  webPopupContent: {
    ...shorthands.padding("12px"),
    ...shorthands.borderRadius("2px"),
    backgroundColor: tokens.colorNeutralBackground4,
    backdropFilter: "blur(50px)",
  },
  webPopupWrapper: {
    minWidth: "320px",
  },
  mobileDialogTitle: {
    fontWeight: tokens.fontWeightBold,
  },
});
