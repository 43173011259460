import React from "react";
import { Dropdown, Field, LabelV9 as Label, Option, mergeClasses } from "..";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInputIsInvalid } from "./PortalFormValidatorContext";
import { IPortalFormValidatorFn } from "./PortalFormValidators";
import { validationMsg } from "./PortalFormFields";
import { formFieldsStyles } from "../../styles/PortalFormFields";

export interface IPortalFormDropdownInputField {
  labelTranslationKey: string;
  value?: IPortalFormDropdownInputValue;
  options: IPortalFormDropDownOption[];
  validatorFns: IPortalFormValidatorFn<string>[];
  placeholderTranslationKey?: string;
  disabled?: boolean;
  borderless?: boolean;
}

export interface IPortalFormDropDownOption {
  key: string;
  id: string;
  content: string;
}

export interface IPortalFormDropdownInputProps
  extends IPortalFormDropdownInputField {
  onChange?: (
    event: React.MouseEvent | React.KeyboardEvent | null,
    newValue?: IPortalFormDropdownInputValue
  ) => void;
  forceShowErrorMessage?: boolean;
}

export type IPortalFormDropdownInputValue = string;

export const PortalFormDropdownInput: React.FunctionComponent<
  IPortalFormDropdownInputProps
> = (props) => {
  const {
    labelTranslationKey,
    value,
    options,
    onChange,
    validatorFns = [],
    forceShowErrorMessage,
    placeholderTranslationKey,
    disabled,
    borderless,
  } = props;
  const formFieldsClasses = formFieldsStyles();
  const { t: i18n } = useTranslation();

  const validators = validatorFns.map((validatorFn) =>
    validatorFn(value || "")
  );
  const failedValidator = validators.findIndex(
    (validator) => validator.isValid === false
  );
  const isValid = failedValidator === -1;
  const errorMessage = !isValid
    ? i18n(validators[failedValidator].message)
    : "";

  const [, setIsAnswerInvalid] = useInputIsInvalid(false);
  useEffect(() => {
    setIsAnswerInvalid(!isValid);
  }, [setIsAnswerInvalid, isValid]);

  const [hasBeenChanged, setHasBeenChanged] = useState(false);

  const shouldShowErrorMessage = useMemo(() => {
    return (forceShowErrorMessage || hasBeenChanged) && !!errorMessage;
  }, [forceShowErrorMessage, hasBeenChanged, errorMessage]);

  const onDropdownChange = useCallback(
    (event, data) => {
      setHasBeenChanged(true);
      if (onChange) {
        onChange(event, data.optionValue ? data.optionValue : undefined);
      }
    },
    [setHasBeenChanged, onChange]
  );

  const dropdown = (
    <Field
      label={
        <Label className={formFieldsClasses.itemLabel}>
          {i18n(labelTranslationKey)}
        </Label>
      }
      validationState={shouldShowErrorMessage ? "error" : undefined}
      validationMessage={
        shouldShowErrorMessage ? validationMsg(errorMessage) : undefined
      }
      validationMessageIcon={null}
    >
      <Dropdown
        placeholder={
          placeholderTranslationKey
            ? i18n(placeholderTranslationKey)
            : undefined
        }
        className={mergeClasses(
          borderless
            ? formFieldsClasses.borderlessInputText
            : formFieldsClasses.itemInput,
          disabled && borderless
            ? formFieldsClasses.borderlessDisabled
            : undefined,
          formFieldsClasses.dropdownPlaceholder
        )}
        data-testid={labelTranslationKey}
        defaultValue={value}
        onOptionSelect={onDropdownChange}
        disabled={disabled}
      >
        {options.map((eachOption) => (
          <Option
            key={eachOption.key}
            value={eachOption.key}
            text={eachOption.content}
            data-testid={eachOption.id}
            className={formFieldsClasses.itemOption}
          >
            {eachOption.content}
          </Option>
        ))}
      </Dropdown>
    </Field>
  );

  return dropdown;
};
