import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  eventSelector,
  registrationDetailSelector,
  registrationErrorSelector,
  logoImageSelector,
  registeredSelector,
  eventRegisterAsyncAction,
  IEventRegisterPayload,
  IEventRegisterAnswers,
  IEventUserRegistrationStatus,
  Answer,
  QuestionTypes,
  userIsRegistered,
  getEventAsyncAction,
  getRegistrationDetailsAsyncAction,
  getPromoImageAsyncAction,
  getLogoImageAsyncAction,
  eventErrorSelector,
  isUserRejectedSelector,
  getRegistrationStatusFromUserIdAsyncAction,
  isOrganizerSelector,
} from "../core/slices/eventSlice";
import {
  enableRedirectAcquireTokenSelector,
  enableWebinarSeriesSelector,
} from "../core/slices/ecsSlice";
import {
  getMeAsyncAction,
  authenticatedSelector,
  userSelector,
  userErrorSelector,
} from "../core/slices/userSlice";
import {
  UserRegistrationStatus,
  Questions,
  QuestionCategories,
} from "../core/slices/eventTypes.interface";
import { useAuthenticationService } from "../core/auth/auth-context";
import { CMDTimeZoneDetails } from "../core/slices/CMDTypes";
import {
  FormOptions,
  IFormOptions,
  DividerV9 as Divider,
  TextV9 as Text,
  getDocument,
  mergeClasses,
  LabelV9 as Label,
  Title1,
  Title2,
  TimeDisplay,
  ImageV9 as Image,
  ButtonV9 as Button,
  Spinner,
  CalendarMultiple20Filled,
  Field,
  InputV9 as Input,
  ErrorCircle20Filled,
  PresenceAvailable16Regular,
  Textarea,
  FluentProvider,
  teamsLightTheme,
  TooltipV9 as Tooltip,
} from "../shared";
import {
  registrationFormStyles,
  registrationPageStyles,
} from "../styles/EventRegistration";
import { logoImageStyles } from "../styles/LogoImage";
import "../styles/EventPage.scss";
import { isValidEmail } from "../utilities/common/utils";
import {
  IRegistrationWarningPopUp,
  RegistrationWarningPopUp,
} from "./registration/RegistrationWarningPopUp";
import { HeroImage } from "./HeroImage";
import { useLogger } from "../common/logger/LoggerContext";
import { LoggerLevels } from "../common/logger/interface";
import { Scenario, UserBIScenario } from "../common/logger/Logger";
import { StepsToTrack } from "../common/logger/StepsToTrack";
import { routes, microsoftLinks } from "../common/constants";
import {
  CmdServicesResponseErrorCode,
  ErrorType,
  IError,
} from "../core/slices/error";
import {
  getRegistrationButtonState,
  RegistrationState,
  isPredefinedQuestion,
} from "./registration/RegistrationStateInternal";
import { EventUser, EventUserRole } from "../core/slices/userTypes.interface";
import { useBreakpoint } from "../utilities/hooks/useBreakpoints";
import { RegistrationUnavailableScenarios } from "./registration/RegistrationUnavailableScenarios";
import { EventRegistrationPageLoading } from "./loading/EventRegistrationPageLoading";
import {
  browserTZToWindowsTZ,
  browserTZToLocalizedWindowsTZ,
} from "../utilities/common/tzMap";
import { PageViewCount } from "./PageViewCount";
import { getLocale } from "../core/localization/i18n";
import { eventErrorHandler } from "../utilities/eventErrorHandler";
import ScrollToTop from "../utilities/common/utils";
import { Breakpoint, gridStyles } from "../styles/Grid";
import { flexAlignStyles, flexStyles } from "../styles/FlexStyles";
import { buttonStyles } from "../styles/ButtonStyles";
import { portalTagStyles } from "../styles/PortalTag";
import { generateSearchParams } from "../utilities/common/generateSearchParams";
import { validationMsg } from "../shared/form/PortalFormFields";
import { formFieldsStyles } from "../styles/PortalFormFields";
import { PortalLocationState } from "../core/history/history.interface";

type FormAnswers = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  answerMap: Map<string, AnswerMapValue>;
  multiChoiceAnswerMap: Map<string, Map<string, boolean>>;
};

type AnswerMapValue = string | boolean | AnswerMapValue[];

const TEXT_LIMIT = 255;
const NOTE_LIMIT = 512;

export const EventRegistrationPage: React.FunctionComponent = () => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const formFieldsClasses = formFieldsStyles();
  const gridClasses = gridStyles();
  const buttonClasses = buttonStyles();
  const portalTagClasses = portalTagStyles();
  const logoImageClasses = logoImageStyles();
  const registrationPageClasses = registrationPageStyles();
  const registrationFormClasses = registrationFormStyles();
  const { id } = useParams<{ id: string }>();
  const { authenticationService } = useAuthenticationService();
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  const dispatch = useDispatch();
  const { t: i18n } = useTranslation();
  const logger = useLogger()?.logger;
  const user = useSelector(userSelector);
  const logoImage = useSelector(logoImageSelector);
  const isAuthenticated = useSelector(authenticatedSelector);
  const registrationDetails = useSelector(registrationDetailSelector);
  const registrationStatus = useSelector(registeredSelector);
  const registrationError = useSelector(registrationErrorSelector);
  const eventError: IError | undefined = useSelector(eventErrorSelector);
  const meError: IError | undefined = useSelector(userErrorSelector);
  const { isBreakpointAndDown } = useBreakpoint();
  const isMobileView = isBreakpointAndDown(Breakpoint.Medium);
  const enableWebinarSeries = useSelector(enableWebinarSeriesSelector);
  const isUserRejected = useSelector(isUserRejectedSelector);
  const currentEvent = useSelector(eventSelector);
  const eventPromoImageID =
    currentEvent && currentEvent.theme.promoImage
      ? currentEvent.theme.promoImage
      : /* istanbul ignore next */ "";
  const eventLogoImageID =
    currentEvent && currentEvent.theme.logoImage
      ? currentEvent.theme.logoImage
      : /* istanbul ignore next */ "";

  const enableRedirectAcquireToken = useSelector(
    enableRedirectAcquireTokenSelector
  );
  const isOrganizer = useSelector(isOrganizerSelector);
  const questions = registrationDetails?.questions;
  const ORGANIZER_TERMS_OF_USE_ID = "organizer-terms-and-conditions";
  const termsOfUseUrl =
    registrationDetails?.registrationProperties?.termsOfUseUrl;

  //important to know this for the display order
  const organizerQuestionHasTAndC =
    !!registrationDetails?.registrationProperties.termsOfUseUrl;

  const msTermsOfUseUrl = microsoftLinks.microsoftTermsOfUse;
  const msTermsOfUserQuestion: Questions = {
    id: "ms-event-terms-and-conditions",
    internalName: "ms-terms-and-conditions",
    category: QuestionCategories.PREDEFINED,
    type: QuestionTypes.BooleanType,
    displayText: i18n("microsoft_terms_and_conditions"),
    order: questions ? questions.length : /* istanbul ignore next */ 1,
    isRequired: true,
  };

  const isRegistered = useSelector(userIsRegistered);

  //Need these states
  const [hasFormChanged, setHasFormChanged] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fNameValidationState, setFNameValidationState] = useState<
    "none" | "success" | "error" | "warning" | undefined
  >("none");
  const [fNameErr, setFNameErr] = useState("");
  const [fNameValidationIcon, setFNameValidationIcon] = useState(<></>);
  const [lNameValidationState, setLNameValidationState] = useState<
    "none" | "success" | "error" | "warning" | undefined
  >("none");
  const [lNameErr, setLNameErr] = useState("");
  const [lNameValidationIcon, setLNameValidationIcon] = useState(<></>);
  const [emailAddressValidationState, setEmailAddressValidationState] =
    useState<"none" | "success" | "error" | "warning" | undefined>("none");
  const [emailAddressErr, setEmailAddressErr] = useState("");
  const [emailAddressValidationIcon, setEmailAddressValidationIcon] = useState(
    <></>
  );
  const [, setMSconsentAgreed] = useState(false);
  const [consentAgreed, setConsentAgreed] = useState(false);
  const [togglePromo, setTogglePromo] = useState(false);
  const [inValidCount, setInvalidCount] = useState(0);
  const validationErrors = useRef(new Map<string, string>());
  const [validationStates, setValidationStates] = useState<
    Map<string, "none" | "success" | "error" | "warning" | undefined>
  >(new Map());
  const validationStatesRef = useRef(validationStates);
  const [validationIcons, setValidationIcons] = useState<
    Map<string, JSX.Element>
  >(new Map());
  const validationIconsRef = useRef(validationIcons);
  const ownerRegisteringError = useRef(<></>);

  const presenterRegisteringError = useRef(<></>);

  const questionsAndRefs = useRef(new Map<string, HTMLDivElement>());

  const userEnteredAnswers = useRef<FormAnswers>({
    firstName: "",
    lastName: "",
    emailAddress: "",
    answerMap: new Map<string, AnswerMapValue>(),
    multiChoiceAnswerMap: new Map<string, Map<string, boolean>>(),
  });

  const makeTimeZoneDetails = () => {
    const now = new Date();
    const tzd: CMDTimeZoneDetails = {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      utcOffset: -now.getTimezoneOffset(),
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      windowsTimeZone: browserTZToWindowsTZ(),
      localizedTimeZoneString: i18n(browserTZToLocalizedWindowsTZ()),
    };
    return tzd;
  };
  ScrollToTop();

  useEffect(() => {
    //If you sign out while on the form, the event becomes undefined
    //so have to make this call
    if (!currentEvent) {
      dispatch(getEventAsyncAction(id));
    }
  }, [dispatch, currentEvent, id]);

  useEffect(() => {
    if (!registrationDetails) {
      dispatch(getRegistrationDetailsAsyncAction(id));
    }
  }, [dispatch, registrationDetails, id]);

  useEffect(() => {
    if (isAuthenticated && !user) {
      dispatch(getMeAsyncAction(true));
    }
  }, [dispatch, isAuthenticated, user]);

  useEffect(() => {
    //if user object id is known or Upn is known then find the registration status
    if (user && id) {
      const userObjectId = user && user.id ? user.id : "";
      const userIdentity = user && user.identity ? user.identity : undefined;
      const userUPN = userIdentity && userIdentity.upn ? userIdentity.upn : "";
      if (userObjectId.length > 0 || userUPN.length > 0) {
        const userTenantID = userIdentity?.tenantId || "";
        const userRegistrationStatusAndId: IEventUserRegistrationStatus = {
          eventId: id,
          userObjectId: userObjectId,
          userUPN: userUPN,
          userTenantId: userTenantID,
        };
        dispatch(
          getRegistrationStatusFromUserIdAsyncAction(
            userRegistrationStatusAndId
          )
        );
      }
    }
  }, [dispatch, user, id]);

  useEffect(() => {
    dispatch(getPromoImageAsyncAction(eventPromoImageID));
    dispatch(getLogoImageAsyncAction(eventLogoImageID));
  }, [dispatch, eventPromoImageID, eventLogoImageID]);

  useEffect(() => {
    //This strategy of accessing child component from parent component so focus
    //can return to parent element when using keyboard for navigation
    if (
      validationErrors.current.size > 0 &&
      !isSubmitted &&
      questionsAndRefs.current &&
      questionsAndRefs.current.size > 0
    ) {
      const [firstKey] = validationErrors.current.keys();
      if (questionsAndRefs.current.has(firstKey)) {
        questionsAndRefs.current.get(firstKey)?.focus();
      }
    }
    const regisrationErrorHandler = () => {
      if (!registrationError) {
        return;
      }

      const scenarioRegistrationDetails = logger?.findScenario(
        Scenario.RegistrationDetails
      );
      scenarioRegistrationDetails?.fail();
      const scenarioEventRegistration = logger?.findScenario(
        Scenario.EventRegistration
      );
      scenarioEventRegistration?.fail();
      logger?.logTrace(
        LoggerLevels.error,
        registrationError && registrationError.responseErrorMessage
          ? registrationError.responseErrorCode +
              ": " +
              registrationError.responseErrorMessage
          : "Cannot Register for the event"
      );

      if (
        registrationError.type === ErrorType.CMD_SERVICES &&
        registrationError.status === 401
      ) {
        if (isAuthenticated) {
          history.push({
            pathname: `${routes.event}/${id}`,
            search: generateSearchParams(location),
            state: { error: CmdServicesResponseErrorCode.UNKNOWN_ERROR },
          });
        } else {
          history.replace({
            pathname: routes.login,
            search: generateSearchParams(location),
            state: {
              redirectPath: {
                pathname: location.pathname,
                search: generateSearchParams(location),
                hash: location.hash,
              },
              showWorkLoginOnly: true,
            },
          });
        }
        return;
      } else if (
        registrationError.type === ErrorType.CMD_SERVICES &&
        registrationError.status === 403
      ) {
        if (isAuthenticated) {
          history.replace({
            pathname: routes.authError,
            search: generateSearchParams(location),
            state: {
              from: {
                pathname: location.pathname,
                search: generateSearchParams(location),
                hash: location.hash,
              },
            },
          });
        } else {
          history.replace({
            pathname: routes.login,
            search: generateSearchParams(location),
            state: {
              redirectPath: {
                pathname: location.pathname,
                search: generateSearchParams(location),
                hash: location.hash,
              },
              showWorkLoginOnly: true,
            },
          });
        }
        return;
      } else if (
        (registrationError.type === ErrorType.CMD_SERVICES &&
          registrationError.status === 404) ||
        // Skype token error with 400 (bad request) means the request data (tenant ID) is invalid,
        // meaning the event GUID is invalid.
        (registrationError.type === ErrorType.SKYPE_TOKEN &&
          registrationError.status === 400)
      ) {
        history.replace({
          pathname: routes.notFound,
          search: generateSearchParams(location),
        });
        return;
      } else if (isSubmitted) {
        //hasSubmitted indicates check for error code obtained after
        //form submission
        setIsSubmitted(false);

        if (
          registrationError.type ===
            ErrorType.SILENT_AUTH_INTERACTION_REQUIRED ||
          (registrationError.type === ErrorType.CMD_SERVICES &&
            registrationError.responseErrorCode ===
              CmdServicesResponseErrorCode.FORBIDDEN)
        ) {
          history.replace({
            pathname: routes.authError,
            search: generateSearchParams(location),
            state: {
              from: {
                pathname: location.pathname,
                search: generateSearchParams(location),
                hash: location.hash,
              },
            },
          });
          return;
        } else if (
          registrationError.type === ErrorType.CMD_SERVICES &&
          registrationError.responseErrorCode ===
            CmdServicesResponseErrorCode.NOT_FOUND
        ) {
          history.replace({
            pathname: routes.notFound,
            search: generateSearchParams(location),
          });
          return;
        } else if (
          registrationError.type === ErrorType.CMD_SERVICES &&
          registrationError.responseErrorCode ===
            CmdServicesResponseErrorCode.PRESENTER_CANNOT_REGISTER
        ) {
          const errorInfo: IRegistrationWarningPopUp = {
            title: i18n(
              "errors.validation_errors.event_presenter_registration_title"
            ),
            errorMessage: i18n(
              "errors.validation_errors.event_presenter_registration_text"
            ),
            showError: true,
          };
          presenterRegisteringError.current = (
            <RegistrationWarningPopUp {...errorInfo} />
          );
          setInvalidCount(validationErrors.current.size + 1);
          return;
        } else if (
          registrationError.type === ErrorType.CMD_SERVICES &&
          (registrationError.responseErrorCode ===
            CmdServicesResponseErrorCode.REGISTRATION_FULL ||
            registrationError.responseErrorCode ===
              CmdServicesResponseErrorCode.WAITLIST_FULL)
        ) {
          history.push({
            pathname: `${routes.event}/${id}`,
            search: generateSearchParams(location),
            state: { error: CmdServicesResponseErrorCode.REGISTRATION_FULL },
          });
          return;
        } else {
          history.push({
            pathname: `${routes.event}/${id}`,
            search: generateSearchParams(location),
            state: { error: CmdServicesResponseErrorCode.UNKNOWN_ERROR },
          });
          return;
        }
      }

      return;
    };

    const handleError = () => {
      const isEventErrorHandled = eventErrorHandler(
        eventError || meError,
        isAuthenticated,
        enableRedirectAcquireToken,
        history,
        location
      );

      if (!isEventErrorHandled) {
        regisrationErrorHandler();
      }
    };

    handleError();
  }, [
    history,
    id,
    logger,
    registrationError,
    eventError,
    meError,
    isAuthenticated,
    enableRedirectAcquireToken,
    hasFormChanged,
    isSubmitted,
    authenticationService,
    i18n,
    inValidCount,
    location,
  ]);

  useEffect(() => {
    if (
      (isRegistered && !isAuthenticated && !hasFormChanged) ||
      (registrationStatus?.status === UserRegistrationStatus.UNKNOWN &&
        !hasFormChanged)
    ) {
      //for unauth public flow, if user registered and accessing page to register again then
      // reload the page so the registration status from before gets wiped out
      history.go(0);
    }

    if (
      isRegistered ||
      registrationStatus?.status === UserRegistrationStatus.REGISTRATION_FULL
    ) {
      const scenarioRegistrationDetails = logger?.findScenario(
        Scenario.RegistrationDetails
      );
      if (scenarioRegistrationDetails) {
        scenarioRegistrationDetails.stop();
      }
      const scenarioEventRegistration = logger?.findScenario(
        Scenario.EventRegistration
      );
      if (scenarioEventRegistration) {
        scenarioEventRegistration.stop();
      }
      history.push({
        pathname: `${routes.event}/${id}`,
        search: generateSearchParams(location),
        state: { emailAddress: userEnteredAnswers.current.emailAddress },
      });
    }
  }, [
    id,
    dispatch,
    isRegistered,
    history,
    registrationStatus,
    logger,
    isAuthenticated,
    hasFormChanged,
    location,
  ]);

  const submitRegistration: () => void = async () => {
    logger?.logUiTelemetry(
      "eventRegistration",
      UserBIScenario.SubmitRegistration,
      "click",
      "none",
      "EventRegistrationPage"
    );
    const isValid = validationCheck();

    if (isValid && inValidCount === 0) {
      const dynamicAnswers: Answer[] = [];

      /* istanbul ignore else */
      if (userEnteredAnswers.current.answerMap.size > 0) {
        userEnteredAnswers.current.answerMap.forEach((v, k) => {
          //TODO: Add this check until the ms-terms of conditions question is
          //added to the service otherwise sending this answer will cause error.
          //Not adding this answer to the payload for now. Just a UI check.
          if (
            k.toString() !== "ms-event-terms-and-conditions" &&
            k.toString() !== ORGANIZER_TERMS_OF_USE_ID
          ) {
            const answer: Answer = {
              Id: k.toString(),
              Answer: v as boolean,
            };
            dynamicAnswers.push(answer);
          }
        });
      }

      /* istanbul ignore else */
      if (userEnteredAnswers.current.multiChoiceAnswerMap.size > 0) {
        userEnteredAnswers.current.multiChoiceAnswerMap.forEach((v, k) => {
          const arr: string[] = [];
          userEnteredAnswers.current.multiChoiceAnswerMap
            .get(k)
            ?.forEach((checked, ans) => {
              if (checked) {
                arr.push(ans);
              }
            });

          const answer: Answer = {
            Id: k,
            Answer: arr as string[],
          };
          dynamicAnswers.push(answer);
        });
      }

      //TODO: Add to payload only when service supports - hasAcceptedMSTermsOfUser: msConsentAgreed,
      const finalAnswers: IEventRegisterAnswers = {
        firstName: userEnteredAnswers.current.firstName,
        lastName: userEnteredAnswers.current.lastName,
        email: userEnteredAnswers.current.emailAddress,
        language: getLocale(),
        timeZoneDetails: makeTimeZoneDetails(),
        answers: dynamicAnswers,
        hasAcceptedTermsOfUse:
          termsOfUseUrl && termsOfUseUrl.trim().length > 0
            ? consentAgreed
            : null,
      };

      const payload: IEventRegisterPayload = {
        eventId: id,
        regInfo: finalAnswers,
      };
      await dispatch(eventRegisterAsyncAction(payload));
    }
  };

  const validationErrorIcon: JSX.Element = (
    <ErrorCircle20Filled
      className={registrationFormClasses.validationErrorIcon}
    />
  );

  const validationSuccessIcon: JSX.Element = (
    <PresenceAvailable16Regular
      className={registrationFormClasses.validationSuccessIcon}
    />
  );

  const validationCheck: () => boolean = () => {
    let isValid = true;

    //Pre-defined questions check
    if (userEnteredAnswers.current.firstName.trim().length === 0) {
      isValid = false;
      setFNameValidationState("error");
      setFNameErr(i18n("errors.validation_errors.required_field_validation"));
      setFNameValidationIcon(validationErrorIcon);
    }

    if (userEnteredAnswers.current.lastName.trim().length === 0) {
      isValid = false;
      setLNameValidationState("error");
      setLNameErr(i18n("errors.validation_errors.required_field_validation"));
      setLNameValidationIcon(validationErrorIcon);
    }

    if (
      userEnteredAnswers.current.emailAddress.trim().length === 0 ||
      !isValidEmail(userEnteredAnswers.current.emailAddress.trim())
    ) {
      isValid = false;
      setEmailAddressValidationState("error");
      setEmailAddressErr(i18n("errors.validation_errors.email_validation"));
      setEmailAddressValidationIcon(validationErrorIcon);
    }

    //Dynamic questions validation check
    questions?.forEach((ques) => {
      if (ques.isRequired) {
        if (ques.type === QuestionTypes.Text) {
          if (userEnteredAnswers.current.answerMap.has(ques.id)) {
            const text = userEnteredAnswers.current.answerMap.get(ques.id);
            /* istanbul ignore else */
            if (text && text.toString().trim().length > TEXT_LIMIT) {
              validationErrors.current.set(
                ques.id,
                i18n("errors.validation_errors.length_limit_validation")
              );
              setValidationStates((map) => new Map(map.set(ques.id, "error")));
              setValidationIcons(
                (map) => new Map(map.set(ques.id, validationErrorIcon))
              );
            } else if ((text && text.toString().trim().length === 0) || !text) {
              validationErrors.current.set(
                ques.id,
                i18n("errors.validation_errors.generic_validation_error")
              );
              setValidationStates((map) => new Map(map.set(ques.id, "error")));
              setValidationIcons(
                (map) => new Map(map.set(ques.id, validationErrorIcon))
              );
            }
          }
          // if the required question id is not present in the answermap then
          // it means user did not attempt to answer it so it is a validation error
          else {
            validationErrors.current.set(
              ques.id,
              i18n("errors.validation_errors.generic_validation_error")
            );
            setValidationStates((map) => new Map(map.set(ques.id, "error")));
            setValidationIcons(
              (map) => new Map(map.set(ques.id, validationErrorIcon))
            );
          }
        }

        if (ques.type === QuestionTypes.Note) {
          if (userEnteredAnswers.current.answerMap.has(ques.id)) {
            const note = userEnteredAnswers.current.answerMap.get(ques.id);
            /* istanbul ignore else */
            if (note && note.toString().trim().length > NOTE_LIMIT) {
              validationErrors.current.set(
                ques.id,
                i18n("errors.validation_errors.length_limit_validation")
              );
              setValidationStates((map) => new Map(map.set(ques.id, "error")));
            } else if ((note && note.toString().trim().length === 0) || !note) {
              validationErrors.current.set(
                ques.id,
                i18n("errors.validation_errors.generic_validation_error")
              );
              setValidationStates((map) => new Map(map.set(ques.id, "error")));
            }
          } else {
            validationErrors.current.set(
              ques.id,
              i18n("errors.validation_errors.generic_validation_error")
            );
            setValidationStates((map) => new Map(map.set(ques.id, "error")));
          }
        }

        if (ques.type === QuestionTypes.SingleChoice) {
          /* istanbul ignore else */
          if (!userEnteredAnswers.current.answerMap.has(ques.id)) {
            validationErrors.current.set(
              ques.id,
              i18n("errors.validation_errors.generic_validation_error")
            );
            setValidationStates((map) => new Map(map.set(ques.id, "error")));
          }
        }

        if (ques.type === QuestionTypes.MultiChoice) {
          //Logic to see if at least one multi-choice answer resolves to true when marked as required
          let isAtleastOneChecked = false;
          /* istanbul ignore else */
          if (userEnteredAnswers.current.multiChoiceAnswerMap.has(ques.id)) {
            userEnteredAnswers.current.multiChoiceAnswerMap
              .get(ques.id)
              ?.forEach((checked, ans) => {
                if (checked) {
                  isAtleastOneChecked = checked;
                }
              });
          }

          if (
            !isAtleastOneChecked ||
            !userEnteredAnswers.current.multiChoiceAnswerMap.has(ques.id)
          ) {
            validationErrors.current.set(
              ques.id,
              i18n("errors.validation_errors.generic_validation_error")
            );
            setValidationStates((map) => new Map(map.set(ques.id, "error")));
          }
        }

        if (ques.type === QuestionTypes.BooleanType) {
          if (userEnteredAnswers.current.answerMap.has(ques.id)) {
            //Specifically check to see if the use unchecked consent
            /* istanbul ignore if */
            if (!userEnteredAnswers.current.answerMap.get(ques.id)) {
              validationErrors.current.set(
                ques.id,
                i18n("errors.validation_errors.generic_validation_error")
              );
              setValidationStates((map) => new Map(map.set(ques.id, "error")));
            }
          } else if (!userEnteredAnswers.current.answerMap.has(ques.id)) {
            userEnteredAnswers.current.answerMap.set(ques.id, false);
            validationErrors.current.set(
              ques.id,
              i18n("errors.validation_errors.generic_validation_error")
            );
            setValidationStates((map) => new Map(map.set(ques.id, "error")));
          }
        }

        if (ques.type === QuestionTypes.PromoEmailConsent) {
          /* istanbul ignore else if */
          if (userEnteredAnswers.current.answerMap.has(ques.id)) {
            //Specifically check to see if the user unchecked promo email consent
            if (!userEnteredAnswers.current.answerMap.get(ques.id)) {
              validationErrors.current.set(
                ques.id,
                i18n("errors.validation_errors.generic_validation_error")
              );
              setValidationStates((map) => new Map(map.set(ques.id, "error")));
            }
          } else if (!userEnteredAnswers.current.answerMap.has(ques.id)) {
            if (ques.isCheckedByDefault) {
              userEnteredAnswers.current.answerMap.set(
                ques.id,
                ques.isCheckedByDefault
              );
            } else {
              userEnteredAnswers.current.answerMap.set(ques.id, false);
            }
          }
        }
      }
    });

    //Has MS terms of use been accepted
    if (
      !userEnteredAnswers.current.answerMap.has(msTermsOfUserQuestion.id) ||
      !userEnteredAnswers.current.answerMap.get(msTermsOfUserQuestion.id)
    ) {
      validationErrors.current.set(
        msTermsOfUserQuestion.id,
        i18n("errors.validation_errors.generic_validation_error")
      );
    }

    //Has organizer terms of use been accepted
    if (
      organizerQuestionHasTAndC &&
      (!userEnteredAnswers.current.answerMap.has(ORGANIZER_TERMS_OF_USE_ID) ||
        !userEnteredAnswers.current.answerMap.get(ORGANIZER_TERMS_OF_USE_ID))
    ) {
      validationErrors.current.set(
        ORGANIZER_TERMS_OF_USE_ID,
        i18n("errors.validation_errors.generic_validation_error")
      );
    }

    if (validationErrors.current.size > 0) {
      isValid = false;
      setInvalidCount(validationErrors.current.size);
      setHasFormChanged(false);
    }

    let isOwnerRegistering = false;

    if (
      currentEvent?.roles.includes(EventUserRole.ORGANIZER) ||
      currentEvent?.roles.includes(EventUserRole.COORGANIZER)
    ) {
      isValid = false;
      isOwnerRegistering = true;
      setInvalidCount(validationErrors.current.size + 1);
      setHasFormChanged(false);
      const errorInfo: IRegistrationWarningPopUp = {
        title: i18n("errors.validation_errors.event_owner_registration_title"),
        errorMessage: i18n(
          "errors.validation_errors.event_owner_registration_text"
        ),
        showError: true,
      };
      ownerRegisteringError.current = (
        <RegistrationWarningPopUp {...errorInfo} />
      );
    }

    if (
      !isOwnerRegistering &&
      currentEvent?.roles.includes(EventUserRole.PRESENTER)
    ) {
      isValid = false;
      setInvalidCount(validationErrors.current.size + 1);
      setHasFormChanged(false);
      const errorInfo: IRegistrationWarningPopUp = {
        title: i18n(
          "errors.validation_errors.event_presenter_registration_title"
        ),
        errorMessage: i18n(
          "errors.validation_errors.event_presenter_registration_text"
        ),
        showError: true,
      };
      presenterRegisteringError.current = (
        <RegistrationWarningPopUp {...errorInfo} />
      );
    }

    //indicate if submission is in process since there are no validation errors
    setIsSubmitted(isValid);

    return isValid;
  };

  const updateQuestionsAndItsRefs: (
    questionId: string,
    refElement: HTMLDivElement
  ) => void = (questionId, refElement) => {
    questionsAndRefs.current.set(questionId, refElement);
  };

  const inputChange: (
    value: string | string[] | boolean | undefined,
    id: string,
    type: string,
    choices?: string[],
    choice?: string
  ) => void = (value, id, type, choices, choice) => {
    if (validationErrors.current.size > 0 && validationErrors.current.has(id)) {
      validationErrors.current.delete(id);
      setInvalidCount(validationErrors.current.size);
      setValidationStates((map) => new Map(map.set(id, "none")));
      setValidationIcons((map) => new Map(map.set(id, validationSuccessIcon)));
    }
    //For Multi Choice you have to pass in a choice map
    if (type === QuestionTypes.MultiChoice && choices) {
      if (!userEnteredAnswers.current.multiChoiceAnswerMap.has(id)) {
        const choiceMap = new Map<string, boolean>();
        choices.forEach((choice) => {
          choiceMap.set(choice, value === choice);
        });
        userEnteredAnswers.current.multiChoiceAnswerMap.set(id, choiceMap);
      }

      const choiceMap: Map<string, boolean> =
        userEnteredAnswers.current.multiChoiceAnswerMap.get(id) ||
        new Map<string, boolean>();
      if (choiceMap.size > 0) {
        choiceMap.forEach((ans, k) => {
          if (k === choice) {
            choiceMap.set(k, value as boolean);
          }
        });
      }
      userEnteredAnswers.current.multiChoiceAnswerMap.set(id, choiceMap);
    } else {
      if (type === QuestionTypes.BooleanType) {
        if (id === msTermsOfUserQuestion.id) {
          setMSconsentAgreed(value as boolean);
          userEnteredAnswers.current.answerMap.set(id, value as boolean);
          if (value === false)
            setValidationStates((map) => new Map(map.set(id, "error")));
        } else if (
          organizerQuestionHasTAndC &&
          id === ORGANIZER_TERMS_OF_USE_ID
        ) {
          setConsentAgreed(value as boolean);
          userEnteredAnswers.current.answerMap.set(id, value as boolean);
          if (value === false)
            setValidationStates((map) => new Map(map.set(id, "error")));
        } else {
          userEnteredAnswers.current.answerMap.set(id, value as boolean);
        }
      } else if (type === QuestionTypes.PromoEmailConsent) {
        userEnteredAnswers.current.answerMap.set(id, value as boolean);
        setTogglePromo(!togglePromo);
      } else {
        const userTextAnswer = value as string;
        if (type === QuestionTypes.Text) {
          if (userTextAnswer.length > 255) {
            validationErrors.current.set(
              id,
              i18n("errors.validation_errors.length_limit_validation")
            );
            setValidationStates((map) => new Map(map.set(id, "error")));
            setValidationIcons(
              (map) => new Map(map.set(id, validationErrorIcon))
            );
            setInvalidCount(validationErrors.current.size);
          } else if (
            userTextAnswer.length === 0 &&
            validationIcons.size > 0 &&
            validationIcons.has(id)
          ) {
            setValidationIcons((map) => new Map(map.set(id, <></>)));
          } else if (userTextAnswer.length > 0) {
            setValidationIcons(
              (map) => new Map(map.set(id, validationSuccessIcon))
            );
          }
        }
        userEnteredAnswers.current.answerMap.set(id, userTextAnswer);
      }
    }
    setHasFormChanged(true);
  };

  /*------Pre-set form information------*/
  let promoQuestion = null;
  let promoLocalizedDisplay = null;
  let isPromoConsentQuestionPresent = false;
  let promoConsentQuestionID = "";

  //user details being set
  if (
    isAuthenticated &&
    user &&
    (userEnteredAnswers.current.firstName.length === 0 ||
      userEnteredAnswers.current.lastName.length === 0 ||
      userEnteredAnswers.current.emailAddress.length === 0)
  ) {
    //user input takes precedence
    userEnteredAnswers.current.firstName =
      user && user.firstName
        ? user.firstName
        : userEnteredAnswers.current.firstName;
    userEnteredAnswers.current.lastName =
      user && user.lastName
        ? user.lastName
        : userEnteredAnswers.current.lastName;
    userEnteredAnswers.current.emailAddress =
      user && user.email ? user.email : userEnteredAnswers.current.emailAddress;
  }

  //promo consent being set
  if (registrationDetails && registrationDetails.questions.length > 0) {
    for (let i = 0; i < registrationDetails.questions.length; i++) {
      if (
        registrationDetails.questions[i].type ===
        QuestionTypes.PromoEmailConsent
      ) {
        promoQuestion = registrationDetails.questions[i];
        promoLocalizedDisplay = registrationDetails.questions[i].internalName
          ? registrationDetails.questions[i].internalName
          : registrationDetails.questions[i].displayText;
        isPromoConsentQuestionPresent = true;
        promoConsentQuestionID = registrationDetails.questions[i].id;
        if (
          !userEnteredAnswers.current.answerMap.has(
            registrationDetails.questions[i].id
          )
        ) {
          userEnteredAnswers.current.answerMap.set(
            registrationDetails.questions[i].id,
            registrationDetails.questions[i].isCheckedByDefault ? true : false
          );
          break;
        }
      }
    }
  }
  /*------Pre-set form information complete------*/

  const personalInfoChange: (newValue: string, name: string) => void = (
    newValue,
    name
  ) => {
    if (name === "firstName") {
      userEnteredAnswers.current.firstName = newValue ? newValue : "";
      if (userEnteredAnswers.current.firstName.length === 0) {
        setFNameValidationState("error");
        setFNameErr(i18n("errors.validation_errors.required_field_validation"));
        setFNameValidationIcon(validationErrorIcon);
      } else if (userEnteredAnswers.current.firstName.length > TEXT_LIMIT) {
        setFNameValidationState("error");
        setFNameErr(i18n("errors.validation_errors.length_limit_validation"));
        setFNameValidationIcon(validationErrorIcon);
      } else {
        setFNameValidationState("none");
        setFNameErr("");
        setFNameValidationIcon(validationSuccessIcon);
      }
    }

    if (name === "lastName") {
      userEnteredAnswers.current.lastName = newValue ? newValue : "";
      if (userEnteredAnswers.current.lastName.length === 0) {
        setLNameValidationState("error");
        setLNameErr(i18n("errors.validation_errors.required_field_validation"));
        setLNameValidationIcon(validationErrorIcon);
      } else if (userEnteredAnswers.current.lastName.length > TEXT_LIMIT) {
        setLNameValidationState("error");
        setLNameErr(i18n("errors.validation_errors.length_limit_validation"));
        setLNameValidationIcon(validationErrorIcon);
      } else {
        setLNameValidationState("none");
        setLNameErr("");
        setLNameValidationIcon(validationSuccessIcon);
      }
    }

    if (name === "email") {
      userEnteredAnswers.current.emailAddress = newValue ? newValue : "";
      if (!isValidEmail(userEnteredAnswers.current.emailAddress.trim())) {
        setEmailAddressValidationState("error");
        setEmailAddressErr(i18n("errors.validation_errors.email_validation"));
        setEmailAddressValidationIcon(validationErrorIcon);
      } else if (
        userEnteredAnswers.current.emailAddress.trim().length > TEXT_LIMIT
      ) {
        setEmailAddressValidationState("error");
        setEmailAddressErr(
          i18n("errors.validation_errors.length_limit_validation")
        );
        setEmailAddressValidationIcon(validationErrorIcon);
      } else if (
        isValidEmail(userEnteredAnswers.current.emailAddress.trim()) &&
        userEnteredAnswers.current.emailAddress.trim().length <= TEXT_LIMIT
      ) {
        setEmailAddressValidationState("none");
        setEmailAddressErr(i18n(""));
        setEmailAddressValidationIcon(validationSuccessIcon);
      }
    }
    setHasFormChanged(true);
  };
  const contentAfterIcon: (
    user: EventUser | null,
    name: string
  ) => JSX.Element | undefined = (user, name) => {
    if (name === "firstName") {
      if (user && user?.firstName && user.firstName.length > 0)
        return <PresenceAvailable16Regular />;
      else return fNameValidationIcon;
    } else if (name === "lastName") {
      if (user && user?.firstName && user.firstName.length > 0)
        return <PresenceAvailable16Regular />;
      else return lNameValidationIcon;
    } else if (name === "email") {
      if (user && user?.firstName && user.firstName.length > 0)
        return <PresenceAvailable16Regular />;
      else return emailAddressValidationIcon;
    }
  };
  const userCancelsRegistration: () => void = () => {
    const referrer = getDocument()?.referrer;
    logger?.logUiTelemetry(
      "eventRegistration",
      UserBIScenario.CancelRegistrationSubmission,
      "click",
      "none",
      "EventRegistrationPage",
      referrer
    );
    const scenarioRegistrationDetails = logger?.findScenario(
      Scenario.RegistrationDetails
    );
    if (scenarioRegistrationDetails) {
      scenarioRegistrationDetails.stop();
    }
    const scenario = logger?.findScenario(Scenario.EventRegistration);
    if (scenario) {
      scenario.mark(StepsToTrack.NotRegistering);
      scenario.stop();
    }
    history.push({
      pathname: `${routes.event}/${id}`,
      search: generateSearchParams(location),
    });
  };

  const otherFields: React.ReactNode[] = [];

  const registrationState = getRegistrationButtonState(
    currentEvent,
    registrationDetails
  );

  const isWithinRegistrationTimeframe: boolean = !!(
    registrationDetails &&
    (!registrationDetails?.registrationProperties.registrationOpenTime ||
      (registrationState !== RegistrationState.REGISTRATION_NOT_STARTED &&
        registrationState !== RegistrationState.REGISTRATION_ENDED)) &&
    registrationState !== RegistrationState.EVENT_ENDED
  );

  const canWeDisplayFields =
    registrationDetails && isWithinRegistrationTimeframe && !isUserRejected;

  const prePopulatedFields = canWeDisplayFields && (
    <>
      <div
        id="first-name-section"
        className={mergeClasses(
          gridClasses.col,
          gridClasses.xs12,
          gridClasses.lg6
        )}
      >
        <Field
          label={
            <Label
              htmlFor="first_name"
              required
              aria-label={`${i18n("first_name_label")} ${i18n("required")}`}
              className={registrationFormClasses.itemLabel}
            >
              {i18n("first_name_label")}
            </Label>
          }
          validationState={fNameValidationState}
          validationMessage={validationMsg(fNameErr)}
          validationMessageIcon={null}
        >
          <Input
            placeholder={i18n("first_name_label")}
            id="first_name"
            className={registrationFormClasses.itemInput}
            name="firstName"
            defaultValue={userEnteredAnswers.current.firstName}
            onChange={(_, data) => {
              personalInfoChange(data?.value || "", "firstName");
            }}
            contentAfter={contentAfterIcon(user, "firstName")}
            disabled={!!(user && user?.firstName && user.firstName.length > 0)}
            aria-label={`${i18n("first_name_label")} ${i18n("required")}`}
          />
        </Field>
      </div>
      <div
        id="last-name-section"
        className={mergeClasses(
          gridClasses.col,
          gridClasses.xs12,
          gridClasses.lg6
        )}
      >
        <Field
          label={
            <Label
              htmlFor="last_name"
              required
              aria-label={`${i18n("last_name_label")} ${i18n("required")}`}
              className={registrationFormClasses.itemLabel}
            >
              {i18n("last_name_label")}
            </Label>
          }
          validationState={lNameValidationState}
          validationMessage={validationMsg(lNameErr)}
          validationMessageIcon={null}
        >
          <Input
            placeholder={i18n("last_name_label")}
            name="lastName"
            id="last_name"
            className={registrationFormClasses.itemInput}
            required
            defaultValue={userEnteredAnswers.current.lastName}
            onChange={(_, data) => {
              personalInfoChange(data?.value || "", "lastName");
            }}
            contentAfter={contentAfterIcon(user, "lastName")}
            disabled={!!(user && user?.lastName && user.lastName.length > 0)}
            aria-label={`${i18n("last_name_label")} ${i18n("required")}`}
          />
        </Field>
      </div>
      <div
        id="email-address-section"
        className={mergeClasses(gridClasses.col, gridClasses.xs12)}
      >
        <Field
          label={
            <Label
              htmlFor="email"
              required
              aria-label={`${i18n("email_label")} ${i18n("required")}`}
              className={registrationFormClasses.itemLabel}
            >
              {i18n("email_label")}
            </Label>
          }
          validationState={emailAddressValidationState}
          validationMessage={validationMsg(emailAddressErr)}
          validationMessageIcon={null}
        >
          <Input
            placeholder={i18n("email_placeholder")}
            name="email"
            id="email"
            className={registrationFormClasses.itemInput}
            required
            defaultValue={userEnteredAnswers.current.emailAddress}
            onChange={(_, data) => {
              personalInfoChange(data?.value || "", "email");
            }}
            contentAfter={contentAfterIcon(user, "email")}
            disabled={!!(user && user?.email && user.email.length > 0)}
            aria-label={`${i18n("email_label")} ${i18n("required")}`}
          />
        </Field>
      </div>
    </>
  );

  if (questions && canWeDisplayFields) {
    questions.forEach((ques) => {
      const localizedDisplayLabel =
        ques.internalName && isPredefinedQuestion(ques.internalName)
          ? i18n(ques.internalName.toLocaleLowerCase() + "_label")
          : ques.displayText;
      const formOptions: IFormOptions = {
        question: ques,
        localizedDisplayLabel: localizedDisplayLabel,
        onChange: inputChange,
        validationError: validationErrors.current.get(ques.id),
        updateQuestionRefs: updateQuestionsAndItsRefs,
        validationStates: validationStates,
        validationMsg: validationMsg,
      };
      const requiredAriaLabel = ques.isRequired ? i18n("required") : "";
      validationStatesRef.current.set(ques.id, "none");
      switch (ques.type) {
        case QuestionTypes.SingleChoice:
          otherFields.push(
            <FormOptions key={ques.id} formOptions={formOptions} />
          );
          break;
        case QuestionTypes.MultiChoice:
          otherFields.push(
            <FormOptions key={ques.id} formOptions={formOptions} />
          );
          break;
        case QuestionTypes.BooleanType:
          otherFields.push(
            <FormOptions key={ques.id} formOptions={formOptions} />
          );
          break;
        case QuestionTypes.PromoEmailConsent:
          /*Although Promo Email Consent question is part of the dynamic questions obtained 
          we cannot handle it as any regular question. It has to be handled like a T&C so 
          this question is handled in the T&C section below*/
          break;
        case QuestionTypes.Note:
          otherFields.push(
            <div id={ques.id + "-section"}>
              <Field
                label={
                  <Label
                    htmlFor={ques.id}
                    required={ques.isRequired}
                    aria-label={`${localizedDisplayLabel} ${requiredAriaLabel}`}
                    className={registrationFormClasses.itemLabel}
                  >
                    {localizedDisplayLabel}
                  </Label>
                }
                validationState={validationStates.get(ques.id)}
                validationMessage={validationMsg(
                  validationErrors.current.get(ques.id)
                )}
                validationMessageIcon={null}
              >
                <Textarea
                  key={ques.id}
                  role={"textarea"}
                  id={ques.id}
                  className={registrationFormClasses.itemInput}
                  required={ques.isRequired}
                  onChange={(e, data) =>
                    inputChange(data?.value, ques.id, ques.type)
                  }
                  aria-label={`${localizedDisplayLabel} ${requiredAriaLabel}`}
                />
              </Field>
            </div>
          );
          break;
        default:
          validationIconsRef.current.set(ques.id, <></>);
          otherFields.push(
            <div id={ques.id + "-section"}>
              <Field
                label={
                  <Label
                    htmlFor={ques.id}
                    required={ques.isRequired}
                    aria-label={`${localizedDisplayLabel} ${requiredAriaLabel}`}
                    className={formFieldsClasses.itemLabel}
                  >
                    {localizedDisplayLabel}
                  </Label>
                }
                validationState={validationStates.get(ques.id)}
                validationMessage={validationMsg(
                  validationErrors.current.get(ques.id)
                )}
                validationMessageIcon={null}
              >
                <Input
                  key={ques.id}
                  id={ques.id}
                  className={formFieldsClasses.itemInput}
                  placeholder={localizedDisplayLabel}
                  name={ques.internalName}
                  required={ques.isRequired}
                  onChange={(_, data) =>
                    inputChange(data?.value, ques.id, ques.type)
                  }
                  contentAfter={
                    ques.isRequired ? validationIcons.get(ques.id) : null
                  }
                  aria-label={`${localizedDisplayLabel} ${requiredAriaLabel}`}
                />
              </Field>
            </div>
          );
          break;
      }
    });
  }

  const msTermsFormOptions: IFormOptions = {
    question: msTermsOfUserQuestion,
    //This is already localized
    localizedDisplayLabel: msTermsOfUserQuestion.displayText,
    termsOfUseUrl: msTermsOfUseUrl,
    onChange: inputChange,
    validationError: validationErrors.current.get(msTermsOfUserQuestion.id),
    updateQuestionRefs: updateQuestionsAndItsRefs,
    validationStates: validationStates,
    validationMsg: validationMsg,
  };

  let promoQuestionDisplay = null;

  if (isPromoConsentQuestionPresent) {
    const formOptionsPromo: IFormOptions = {
      question: promoQuestion as Questions,
      localizedDisplayLabel: promoLocalizedDisplay ? promoLocalizedDisplay : "",
      onChange: inputChange,
      validationError: validationErrors.current.get(promoConsentQuestionID),
      promoConsent: userEnteredAnswers.current.answerMap.has(
        promoConsentQuestionID
      )
        ? (userEnteredAnswers.current.answerMap.get(
            promoConsentQuestionID
          ) as boolean)
        : false,
      updateQuestionRefs: updateQuestionsAndItsRefs,
      validationStates: validationStates,
      validationMsg: validationMsg,
    };
    promoQuestionDisplay = (
      <FormOptions
        key={promoConsentQuestionID}
        formOptions={formOptionsPromo}
      />
    );
  }

  if (organizerQuestionHasTAndC && isWithinRegistrationTimeframe) {
    const organizerTandCQuestion: Questions = {
      id: ORGANIZER_TERMS_OF_USE_ID,
      internalName: "organizer-terms-and-conditions",
      category: QuestionCategories.PREDEFINED,
      type: QuestionTypes.BooleanType,
      displayText: i18n("organizer_terms_and_conditions"),
      order: questions ? questions.length + 1 : 0,
      isRequired: true,
    };
    const termsAndConditions: IFormOptions = {
      question: organizerTandCQuestion,
      //This is already localized
      localizedDisplayLabel: organizerTandCQuestion.displayText,
      termsOfUseUrl: registrationDetails
        ? registrationDetails.registrationProperties.termsOfUseUrl
        : "",
      onChange: inputChange,
      validationError: validationErrors.current.get(organizerTandCQuestion.id),
      updateQuestionRefs: updateQuestionsAndItsRefs,
      validationStates: validationStates,
      validationMsg: validationMsg,
    };
    otherFields.push(
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGapMedium
        )}
      >
        <Divider />
        <div className={mergeClasses(flexClasses.root, flexClasses.column)}>
          <FormOptions
            key={msTermsOfUserQuestion.id}
            formOptions={msTermsFormOptions}
          />
          <FormOptions
            key={organizerTandCQuestion.id}
            formOptions={termsAndConditions}
          />
          {isPromoConsentQuestionPresent && promoQuestionDisplay}
        </div>
      </div>
    );
  } else {
    otherFields.push(
      <div
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          flexClasses.columnGapMedium
        )}
      >
        <Divider />
        <FormOptions
          key={msTermsOfUserQuestion.id}
          formOptions={msTermsFormOptions}
        />
        {isPromoConsentQuestionPresent && promoQuestionDisplay}
      </div>
    );
  }

  let primaryButton = i18n("button_register");
  let primaryAriaLabel = i18n("button_register_submit_description");

  if (
    registrationDetails?.registrationProperties.isFull &&
    registrationDetails?.registrationProperties.isWaitlistEnabled &&
    !registrationDetails?.registrationProperties.isWaitlistFull
  ) {
    primaryButton = i18n("join_waitlist");
    primaryAriaLabel = i18n("join_waitlist_submit_description");
  } else if (
    registrationDetails?.registrationProperties.isManualApprovalEnabled
  ) {
    primaryButton = i18n("submit_for_approval");
    primaryAriaLabel = i18n("submit_for_approval_description");
  }

  let registrationForm = null;

  if (!registrationDetails) {
    registrationForm = <EventRegistrationPageLoading />;
  }

  //Show spinner when fetching /me when user signs in or when unauth user registers and
  //tries to access registration form again, we need to refresh to clear out
  //their registration status so until then show spinner
  if (
    !registrationError &&
    ((isAuthenticated && !user) ||
      (isRegistered && !isAuthenticated && !hasFormChanged))
  ) {
    registrationForm = <EventRegistrationPageLoading />;
  } else if (currentEvent && registrationDetails) {
    const registrationFormHeader = (
      <div
        className={mergeClasses(
          "registration-form-header",
          registrationFormClasses.header
        )}
      >
        <div className={mergeClasses(gridClasses.row)}>
          <div
            className={mergeClasses(
              gridClasses.col,
              gridClasses.xs12,
              gridClasses.md10,
              gridClasses.offsetMd1,
              gridClasses.xl8,
              gridClasses.offsetXl2
            )}
          >
            <div
              className={mergeClasses(
                flexClasses.root,
                flexClasses.column,
                flexClasses.columnGapSmall
              )}
            >
              {isMobileView && (
                <Image
                  src={logoImage.image}
                  className={mergeClasses(
                    registrationFormClasses.logo,
                    logoImageClasses.root,
                    registrationFormClasses.headerImage
                  )}
                />
              )}
              <div
                className={mergeClasses(
                  flexClasses.root,
                  flexClasses.rowGapSmall
                )}
              >
                <Title1
                  as="h1"
                  className={registrationPageClasses.titleDisplay}
                  aria-label={i18n("registration_form_announcement", {
                    eventTitle: currentEvent?.title,
                  })}
                  tabIndex={1}
                >
                  {currentEvent?.title}
                </Title1>
                {enableWebinarSeries && (
                  <div
                    className={mergeClasses(
                      flexClasses.root,
                      flexAlignClasses.alignItemCenter,
                      portalTagClasses.root,
                      portalTagClasses.grey
                    )}
                    id="webinar-series"
                  >
                    <CalendarMultiple20Filled />
                    &nbsp;
                    <Text weight="semibold">{i18n("webinar_series_tag")}</Text>
                  </div>
                )}
              </div>
              {currentEvent && (
                <TimeDisplay
                  startTime={currentEvent?.eventTime.startTime}
                  endTime={currentEvent?.eventTime.endTime}
                  weight="regular"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
    const submitRegistrationButton = (
      <Button
        id="submitRegistration"
        className={buttonClasses.fluid}
        appearance="primary"
        disabled={isSubmitted || isOrganizer}
        onClick={submitRegistration}
        aria-label={primaryAriaLabel}
      >
        {isSubmitted && <Spinner />}
        {primaryButton}
      </Button>
    );
    const registrationFormBody = (
      <div
        className={mergeClasses(
          "registration-form-Body",
          registrationFormClasses.body
        )}
      >
        <div className={gridClasses.row}>
          <div
            className={mergeClasses(
              gridClasses.col,
              gridClasses.xs12,
              gridClasses.md10,
              gridClasses.offsetMd1,
              gridClasses.xl8,
              gridClasses.offsetXl2
            )}
          >
            {isUserRejected && <RegistrationUnavailableScenarios />}
            {!isWithinRegistrationTimeframe && (
              <RegistrationUnavailableScenarios
                errorCode={
                  registrationState === RegistrationState.EVENT_ENDED
                    ? CmdServicesResponseErrorCode.EXPIRED_EVENT
                    : CmdServicesResponseErrorCode.REGISTRATION_CLOSED
                }
                regStartTime={
                  registrationDetails?.registrationProperties
                    .registrationOpenTime?.startTime
                }
              />
            )}
            {canWeDisplayFields && (
              <div
                className={mergeClasses(
                  flexClasses.root,
                  flexClasses.column,
                  flexClasses.columnGapMedium
                )}
              >
                <Title2
                  as="h2"
                  className={
                    registrationPageClasses.registrationInformationStyles
                  }
                  aria-label={`${i18n("registration_information")} ${i18n(
                    "heading_level_2"
                  )}`}
                >
                  {i18n("registration_information")}
                </Title2>
                <div
                  className={mergeClasses(
                    gridClasses.row,
                    registrationFormClasses.fieldsGrid
                  )}
                >
                  {canWeDisplayFields && prePopulatedFields}
                  {canWeDisplayFields &&
                    otherFields.map((item, i) => (
                      <div
                        key={i}
                        className={mergeClasses(
                          gridClasses.col,
                          gridClasses.xs12
                        )}
                      >
                        {item}
                      </div>
                    ))}
                </div>
                {canWeDisplayFields && (
                  <div
                    className={mergeClasses(
                      flexClasses.root,
                      flexClasses.rowGapSmall
                    )}
                  >
                    {isOrganizer ? (
                      <Tooltip
                        content={{
                          children: i18n("organizer_registration_tooltip"),
                        }}
                        relationship={"label"}
                        positioning={"below"}
                        key={"organizersTooltip"}
                      >
                        {submitRegistrationButton}
                      </Tooltip>
                    ) : (
                      submitRegistrationButton
                    )}
                    <Button
                      id="cancel"
                      className={buttonClasses.fluid}
                      disabled={isSubmitted}
                      onClick={() => userCancelsRegistration()}
                      aria-label={i18n(
                        "button_cancel_registration_form_description"
                      )}
                    >
                      {i18n("button_cancel")}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );

    registrationForm = currentEvent && registrationDetails && (
      <div className={gridClasses.row}>
        <form
          className={mergeClasses(
            flexClasses.root,
            flexClasses.column,
            flexAlignClasses.justifyContentSpaceEvenly,
            flexClasses.fill,
            registrationFormClasses.root
          )}
        >
          <div
            className={mergeClasses(
              gridClasses.col,
              gridClasses.xs12,
              gridClasses.lg8,
              gridClasses.offsetLg2,
              registrationFormClasses.cardArea
            )}
          >
            <div
              className={mergeClasses(
                "registration-form-card",
                registrationFormClasses.card
              )}
            >
              {registrationFormHeader}
              {registrationFormBody}
            </div>
          </div>
        </form>
      </div>
    );
  }

  const registrationPageDisplay = !(
    registrationError && registrationError.status === 404
  ) ? (
    <div role="main">
      <HeroImage />
      <FluentProvider theme={teamsLightTheme}>
        <div
          className={mergeClasses(
            gridClasses.container,
            registrationPageClasses.container
          )}
        >
          {registrationForm}
        </div>
        <PageViewCount />
      </FluentProvider>
      {ownerRegisteringError.current}
      {presenterRegisteringError.current}
    </div>
  ) : null;

  return registrationPageDisplay;
};
