import { shorthands, mergeClasses, makeStyles } from '@griffel/react';
import { tokens } from '@fluentui/react-theme';
export const imageClassNames = {
    root: 'fui-Image'
};
const useStyles = makeStyles({
    // Base styles
    base: {
        ...shorthands.borderColor(tokens.colorNeutralStroke1),
        ...shorthands.borderRadius(tokens.borderRadiusNone),
        boxSizing: 'border-box',
        display: 'inline-block'
    },
    // Bordered styles
    bordered: {
        ...shorthands.borderStyle('solid'),
        ...shorthands.borderWidth(tokens.strokeWidthThin)
    },
    // Shape variations
    circular: {
        ...shorthands.borderRadius(tokens.borderRadiusCircular)
    },
    rounded: {
        ...shorthands.borderRadius(tokens.borderRadiusMedium)
    },
    square: {
    },
    // Shadow styles
    shadow: {
        boxShadow: tokens.shadow4
    },
    // Fit variations
    center: {
        objectFit: 'none',
        objectPosition: 'center',
        height: '100%',
        width: '100%'
    },
    contain: {
        objectFit: 'contain',
        objectPosition: 'center',
        height: '100%',
        width: '100%'
    },
    default: {
    },
    cover: {
        objectFit: 'cover',
        objectPosition: 'center',
        height: '100%',
        width: '100%'
    },
    none: {
        objectFit: 'none',
        objectPosition: 'left top',
        height: '100%',
        width: '100%'
    },
    // Block styles
    block: {
        width: '100%'
    }
});
export const useImageStyles_unstable = (state)=>{
    const styles = useStyles();
    state.root.className = mergeClasses(imageClassNames.root, styles.base, state.block && styles.block, state.bordered && styles.bordered, state.shadow && styles.shadow, styles[state.fit], styles[state.shape], state.root.className);
};
