import React, { useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  DialogContentV9 as DialogContent,
  DialogTitleV9 as DialogTitle,
  DialogV9 as Dialog,
  DialogSurfaceV9 as DialogSurface,
  DialogBodyV9 as DialogBody,
  DialogTriggerV9 as DialogTrigger,
  mergeClasses,
  ButtonV9 as Button,
  Dismiss32Regular,
  TextV9 as Text,
} from "../../shared";
import { useTranslation } from "react-i18next";
import { flexAlignStyles, flexStyles } from "../../styles/FlexStyles";
import { portalResponsiveDialogStyles } from "../../styles/PortalResponsiveDialog";
import { registrationPageStyles } from "../../styles/EventRegistration";
import { regAndCancelSuccessStyles } from "../../styles/RegistrationAndCancellationSuccess";
import { generateSearchParams } from "../../utilities/common/generateSearchParams";
import { routes } from "../../common/constants";
import { PortalLocationState } from "../../core/history/history.interface";

export interface IRegistrationWarningPopUp {
  title: string;
  errorMessage: string;
  showError: boolean;
}

export const RegistrationWarningPopUp: React.FunctionComponent<
  IRegistrationWarningPopUp
> = ({ title, errorMessage, showError }) => {
  const flexAlignClasses = flexAlignStyles();
  const flexClasses = flexStyles();
  const portalResponsiveDialogClasses = portalResponsiveDialogStyles();
  const registrationPageClasses = registrationPageStyles();
  const regAndCancelSuccessClasses = regAndCancelSuccessStyles();

  const { t: i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory<PortalLocationState>();
  const location = useLocation<PortalLocationState>();
  const [isOpen, setIsOpen] = useState(showError);

  const onDismiss: () => void = () => {
    setIsOpen(false);
    history.replace({
      pathname: `${routes.event}/${id}`,
      search: generateSearchParams(location),
      state: undefined,
    });
  };

  const errorModal = (
    <Dialog
      open={isOpen}
      onOpenChange={/* istanbul ignore next */ () => onDismiss()}
    >
      <DialogSurface
        className={mergeClasses(
          flexClasses.root,
          flexClasses.column,
          portalResponsiveDialogClasses.dialogSurface
        )}
      >
        <DialogBody
          className={mergeClasses(
            "registration-warning-dialog",
            flexClasses.root,
            flexClasses.column,
            flexAlignClasses.justifyContentCenter,
            portalResponsiveDialogClasses.dialogBody
          )}
          id={"registration-warning-dialog"}
        >
          <DialogTitle
            className={mergeClasses(
              flexClasses.root,
              flexAlignClasses.justifyContentEnd
            )}
          >
            <DialogTrigger action="close">
              <Button
                id="close-warning"
                aria-label={i18n("close_popup_modal")}
                onClick={onDismiss}
                icon={<Dismiss32Regular />}
                appearance="transparent"
                title={i18n("button_close")}
              />
            </DialogTrigger>
          </DialogTitle>
          <DialogContent
            className={mergeClasses(
              flexClasses.root,
              flexClasses.column,
              flexClasses.columnGapMedium,
              flexAlignClasses.alignItemCenter
            )}
          >
            <div>
              <Text
                role="heading"
                id="cancelSuccessModalTitle"
                tabIndex={1}
                as="h1"
                align="center"
                className={mergeClasses(
                  registrationPageClasses.titleDisplay,
                  regAndCancelSuccessClasses.successBodyText
                )}
                aria-label={title + " " + errorMessage}
              >
                {title}
              </Text>
            </div>
            <div
              className={mergeClasses(
                flexClasses.root,
                flexClasses.column,
                flexClasses.columnGapLarge,
                flexAlignClasses.justifyContentCenter,
                regAndCancelSuccessClasses.successBody
              )}
            >
              <Text
                align="center"
                className={regAndCancelSuccessClasses.successBodyText}
              >
                {errorMessage}
              </Text>
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );

  return errorModal;
};
