import { tokens } from '@fluentui/react-theme';
import { makeStyles, mergeClasses, shorthands } from '@griffel/react';
export const listboxClassNames = {
    root: 'fui-Listbox'
};
/**
 * Styles for the root slot
 */ const useStyles = makeStyles({
    root: {
        backgroundColor: tokens.colorNeutralBackground1,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '160px',
        overflowY: 'auto',
        ...shorthands.outline('1px', 'solid', tokens.colorTransparentStroke),
        ...shorthands.padding(tokens.spacingHorizontalXS),
        rowGap: tokens.spacingHorizontalXXS
    }
});
/**
 * Apply styling to the Listbox slots based on the state
 */ export const useListboxStyles_unstable = (state)=>{
    const styles = useStyles();
    state.root.className = mergeClasses(listboxClassNames.root, styles.root, state.root.className);
    return state;
};
