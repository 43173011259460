import React from "react";
import { useSetTimeout } from "@fluentui/react-hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AXHelper } from "../core/api/axHelper";
import {
  PortalAnchorV9 as PortalAnchor,
  PortalFacebookIcon,
  PortalTwitterIcon,
  mergeClasses,
  InputV9 as Input,
  LabelV9 as Label,
  bundleIcon,
  Copy24Filled,
  Copy24Regular,
  TooltipV9 as Tooltip,
  ButtonV9 as Button,
  Checkmark24Filled,
  PortalLinkedInIcon,
} from "../shared";
import {
  createSocialLink,
  createSocialImageLink,
  generateFacebookShareLink,
  generateLinkedinShareLink,
  generateTwitterShareLink,
} from "../utilities/socialShareLinkGenerator";
import { eventSharingStyle } from "../styles/EventSharing";
import { useLogger } from "../common/logger/LoggerContext";
import { UserBIScenario } from "../common/logger/Logger";
import { useSelector } from "react-redux";
import { enableEventSharingSocialButtonsSelector } from "../core/slices/ecsSlice";
import { flexStyles } from "../styles/FlexStyles";
import { buttonStyles } from "../styles/ButtonStyles";

export const EventSharing: React.FunctionComponent<{
  eventTitle: string;
  eventUrl: string;
  eventDescription: string | undefined;
  promoImageID: string;
  eventID: string;
}> = ({ eventTitle, eventUrl, eventDescription, promoImageID, eventID }) => {
  const flexClasses = flexStyles();
  const buttonClasses = buttonStyles();
  const eventSharingClasses = eventSharingStyle();
  const { t: i18n } = useTranslation();
  const logger = useLogger()?.logger;

  const enableEventSharingSocialButtons = useSelector(
    enableEventSharingSocialButtonsSelector
  );

  const {
    setTimeout: setConfirmationTimeout,
    clearTimeout: clearConfirmationTimeout,
  } = useSetTimeout();
  const [confirmationTimeoutId, setConfirmationTimeoutId] = useState<
    number | null
  >(null);

  const copyLinkHandler = (e: React.SyntheticEvent<HTMLElement, Event>) => {
    logger?.logUiTelemetry(
      "shareEvent",
      UserBIScenario.CopyEventLink,
      "click",
      "none",
      "EventSharing"
    );
    navigator.clipboard.writeText(eventUrl);
    if (confirmationTimeoutId !== null) {
      clearConfirmationTimeout(confirmationTimeoutId);
      setConfirmationTimeoutId(null);
    }
    setConfirmationTimeoutId(
      setConfirmationTimeout(() => {
        setConfirmationTimeoutId(null);
      }, 500)
    );
  };

  // The .NET Controllers are slow the first time you call them. In order to
  // keep the scrapers from timing out, we pre-fetch the social link and the
  // social image link to get them faulted in and have the data resident in REDIS
  const socialLink = createSocialLink(
    eventUrl,
    eventTitle,
    eventDescription,
    promoImageID,
    eventID,
    "fb"
  );
  // prefetch the link to fault the controller in
  AXHelper.prefetchSocialShare(socialLink);

  const socialImageLink = createSocialImageLink(promoImageID, eventID);
  // prefetch the link to fault the controller in
  AXHelper.prefetchSocialShare(socialImageLink);

  const facebookShareLink = generateFacebookShareLink(
    eventUrl,
    eventTitle,
    eventDescription,
    promoImageID,
    eventID
  );
  const linkedinShareLink = generateLinkedinShareLink(
    eventUrl,
    eventTitle,
    eventDescription,
    promoImageID,
    eventID
  );
  const twitterShareLink = generateTwitterShareLink(
    eventUrl,
    eventTitle,
    eventDescription
  );

  const CopyIcon = bundleIcon(Copy24Filled, Copy24Regular);

  return (
    <div
      className={mergeClasses(
        flexClasses.root,
        flexClasses.column,
        flexClasses.columnGap18px,
        flexClasses.fill
      )}
    >
      <div className={mergeClasses(flexClasses.root, flexClasses.column)}>
        <Label
          className={eventSharingClasses.shareEventLinkText}
          htmlFor="eventLink"
        >
          {i18n("share_event_link")}
        </Label>
        <Input
          id="eventLink"
          className={eventSharingClasses.shareEventInput}
          value={eventUrl}
          readOnly
          contentAfter={
            <Tooltip
              content={{
                children: i18n("copy_tooltip"),
              }}
              relationship="label"
            >
              <Button
                aria-label={i18n("copy_tooltip")}
                id="accept-copy-icon"
                className={mergeClasses(
                  buttonClasses.text,
                  eventSharingClasses.clipboardIconWrapper
                )}
                icon={
                  confirmationTimeoutId !== null ? (
                    <Checkmark24Filled
                      className={eventSharingClasses.clipboardIcon}
                    />
                  ) : (
                    <CopyIcon className={eventSharingClasses.clipboardIcon} />
                  )
                }
                onClick={copyLinkHandler}
                size="small"
              />
            </Tooltip>
          }
        />
      </div>
      {enableEventSharingSocialButtons && (
        <div
          className={mergeClasses(flexClasses.root, flexClasses.rowGapMedium)}
        >
          <PortalAnchor
            id="facebookLink"
            href={facebookShareLink}
            target="_blank"
            aria-label={`${i18n("share_to_facebook")} ${i18n("link_new_tab")}`}
          >
            {
              <PortalFacebookIcon
                onClick={() =>
                  logger?.logUiTelemetry(
                    "shareEvent",
                    UserBIScenario.ShareToFacebook,
                    "click",
                    "none",
                    "EventSharing"
                  )
                }
              />
            }
          </PortalAnchor>
          <PortalAnchor
            id="linkedInLink"
            href={linkedinShareLink}
            target="_blank"
            aria-label={`${i18n("share_to_linkedIn")} ${i18n("link_new_tab")}`}
          >
            {
              <PortalLinkedInIcon
                onClick={() =>
                  logger?.logUiTelemetry(
                    "shareEvent",
                    UserBIScenario.ShareToLinkedIn,
                    "click",
                    "none",
                    "EventSharing"
                  )
                }
              />
            }
          </PortalAnchor>
          <PortalAnchor
            id="twitterLink"
            href={twitterShareLink}
            target="_blank"
            aria-label={`${i18n("share_to_twitter")} ${i18n("link_new_tab")}`}
          >
            {
              <PortalTwitterIcon
                onClick={() =>
                  logger?.logUiTelemetry(
                    "shareEvent",
                    UserBIScenario.ShareToTwitter,
                    "click",
                    "none",
                    "EventSharing"
                  )
                }
              />
            }
          </PortalAnchor>
        </div>
      )}
    </div>
  );
};
