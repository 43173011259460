import { makeStyles, shorthands, tokens } from "../shared";

export const eventSharingStyle = makeStyles({
  shareEventLinkText: {
    color: tokens.colorNeutralForeground3,
    marginBottom: "5px",
  },
  clipboardIcon: {
    color: tokens.colorBrandForeground1,
  },
  clipboardIconWrapper: {
    backgroundColor: "transparent",
    ...shorthands.borderColor("transparent"),
    ":hover": {
      backgroundColor: "transparent",
      ...shorthands.borderColor("transparent"),
    },
  },
  shareEventInput: {
    ...shorthands.borderColor("transparent"),
    ":hover": {
      ...shorthands.borderColor("transparent"),
    },
    backgroundColor: tokens.colorNeutralBackground3,
  },
});
