import DOMPurify from "dompurify";

const ampScript =
  "https://amp.azure.net/libs/amp/latest/azuremediaplayer.min.js";

if (window.trustedTypes && window.trustedTypes.createPolicy) {
  // Feature testing
  window.trustedTypes.createPolicy("default", {
    createHTML: (string) => {
      if (isAmp()) {
        // AMP parses video manifest as XML, it should not be sanitized
        return string;
      }
      return DOMPurify.sanitize(string);

      function isAmp() {
        const err = new Error();
        Error.prepareStackTrace = (_, stack) => stack;
        const stack = err.stack;
        Error.prepareStackTrace = undefined;
        for (var stackItem of stack) {
          if (stackItem.getFileName() === ampScript) {
            return true;
          }
        }
        return false;
      }
    },
    createScript: (string) => string,
    createScriptURL: (string) => {
      const selfOrigin = window.location.origin;
      const allowedOrigins = [selfOrigin];
      const url = new URL(string, selfOrigin);
      if (allowedOrigins.includes(url.origin)) {
        return url.href;
      }
      // return as-is and rely on script-src CSP
      return string;
    },
  });
}
